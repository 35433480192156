import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";
import { formatDateWithTime } from "helpers/formatDate";
import cn from "classnames";
import { INewsItemPage, IUserStore } from "models";
import { TagList, Tags } from "components";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_NewsPage.module.css";
import DOMRF_styles from "./DOMRF_NewsPage.module.css";
import MTS_styles from "./MTS_NewsPage.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsPage.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsPage.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsPage.module.css";
import negative from "../../assets/negative.png";
import neutral from "../../assets/neutral.png";
import positive from "../../assets/positive.png";

interface INewsPageProps {
  pageStore?: INewsItemPage;
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

@inject("userStore")
@inject("pageStore")
@observer
class NewsPage extends Component<INewsPageProps> {
  protected pageStore: INewsItemPage;

  constructor(props: INewsPageProps) {
    super(props);
    // @ts-ignore
    this.pageStore = this.props.pageStore;
  }

  renderTonality() {
    switch(this.pageStore.page.tonality) {
      case 'neutral': return <img src={neutral} alt="" width="20px"/>;
      case 'positive': return <img src={positive} alt="" width="20px"/>;
      case 'negative': return <img src={negative} alt="" width="20px"/>;
      default: return null;
    }
  }

  render() {
    let {
      id,
      source_name,
      title,
      annotation,
      author,
      tags,
      url,
      description,
      colorizedDescription,
      image,
      publication_at,
      favorite,
      viewed,
      source_breadth,
      breadthCount,
      tonality
    } = this.pageStore.page;
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const { styles } = assets[company];
    return (
        this.pageStore.state === "done" && (
            <Fragment>
              <div className={styles.root}>
                <div className={styles.src}>
                  {source_name && (
                      <span className={styles.name}>{source_name}</span>
                  )}
                  {breadthCount && (
                      <span className={styles.views}>АУДИТОРНЫЙ ОХВАТ: {breadthCount}</span>
                  )}
                  {publication_at && (
                      <span className={styles.date}>
                  {formatDateWithTime(publication_at)}
                </span>
                  )}
                  {author && <span className={styles.author}>Автор: {author}</span>}
                </div>
                {title && <h1 className={styles.newsTitle}>{title}</h1>}
                {tonality && <div className={styles.newsTonality}>
                  {this.renderTonality()}
                </div>}
                {annotation && (
                    <div className={styles.annotation} dangerouslySetInnerHTML={{
                      __html: annotation
                    }} />
                )}
                {tags && tags.length > 0 && <TagList tags={tags} />}
                <div className={styles.newsBody}>
                  <div className={styles.text}>
                    {image && (
                        <div className={styles.image}>
                          <img src={image} alt="" />
                          {/*<div className={styles.caption}>*/}
                          {/*    Московская бираж / © Reuters / Никита Павлов*/}
                          {/*</div>*/}
                        </div>
                    )}
                    {description && (
                        <div
                            dangerouslySetInnerHTML={{
                              __html: colorizedDescription || description || ""
                            }}
                        />
                    )}
                  </div>
                  {tags && tags.length > 0 && <Tags tags={tags} />}
                </div>
                <div className={styles.controls}>
                  {favorite ? (
                      <span
                          onClick={() => {
                            this.pageStore.removeNewsFromFavorites(id);
                          }}
                          className={cn(styles.favoriteLink, styles.favorite)}
                      >
                  Удалить из избранного
                </span>
                  ) : (
                      <span
                          onClick={() => {
                            this.pageStore.addNewsToFavorites(id);
                          }}
                          className={styles.favoriteLink}
                      >
                  Добавить в избранное
                </span>
                  )}
                  {url && (
                      <a href={url} target="_blank" className={styles.original}>
                        Оригинал публикации
                      </a>
                  )}
                  {/*<span className={styles.download}>Выгрузить</span>*/}
                </div>
                {/*<div className={styles.social}>*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href="/">vk</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">facebook</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">twitter</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">ok</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">whatsup</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">viber</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">telegram</a>*/}
                {/*    </li>*/}
                {/*    <li>*/}
                {/*      <a href="/">e-mail</a>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
              </div>
              {/*<NewsGrid />*/}
            </Fragment>
        )
    );
  }
}

export default NewsPage;