exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".KALASHNIKOVGROUP_NewsGrid_controls__1qqsa {\n    text-align: center;\n}\n\n.KALASHNIKOVGROUP_NewsGrid_show_more__32cki {\n    display: inline-block;\n    margin: 0 auto 30px;\n}\n\n.KALASHNIKOVGROUP_NewsGrid_show_more__32cki a {\n    color: #fff;\n}", ""]);

// Exports
exports.locals = {
	"tablet": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"large-desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["large-desktop"] + "",
	"controls": "KALASHNIKOVGROUP_NewsGrid_controls__1qqsa",
	"show_more": "KALASHNIKOVGROUP_NewsGrid_show_more__32cki"
};