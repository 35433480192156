import React, { FunctionComponent } from "react";
import {inject} from "mobx-react";
import {IUserStore} from "models";
import ASV_NewsItem from "./ASV_NewsItem";
import DOMRF_NewsItem from "./DOMRF_NewsItem";
import MTS_NewsItem from "./MTS_NewsItem";
import KALASHNIKOVGROUP_NewsItem from "./KALASHNIKOVGROUP_NewsItem";
import POCHTABANK_NewsItem from "./POCHTABANK_NewsItem";
import CUSTOMS_NewsItem from "./CUSTOMS_NewsItem";

interface INewsItemProps {
  userStore?: IUserStore;
}

const newsItemPage: any = {
  ASV: <ASV_NewsItem />,
  DOMRF: <DOMRF_NewsItem />,
  MTS: <MTS_NewsItem />,
  KALASHNIKOVGROUP: <KALASHNIKOVGROUP_NewsItem />,
  POCHTABANK: <POCHTABANK_NewsItem />,
  CUSTOMS: <CUSTOMS_NewsItem />,
};

const NewsItem: FunctionComponent<INewsItemProps> = ({userStore}) => {
  const company =  userStore && userStore.company || 'ASV';
  return (newsItemPage[company]);
};

export default inject('userStore')(NewsItem);
