import apiClient from "libs/apiClient";

const PATH_AUTHENTICATION = "/api/session";

export const tryLogin = (data: FormData) =>
  apiClient.post(PATH_AUTHENTICATION, data);

export const tryLoginGoSmi = (token: string) =>
  apiClient.get(
    "/api/profile/",
    {"format":"json"},
    {
      headers: {
        Authorization: `Basic ${token}`,

      },
    }
  );

export const logout = () => apiClient.delete(PATH_AUTHENTICATION);
