import { SwitchProps, Switch, withStyles } from "@material-ui/core";
import React from "react";

export const CustomSwitch = withStyles({
  switchBase: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
    "&$checked + $track": {
      backgroundColor: "#67B580",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})((props: SwitchProps) => <Switch {...props} />);
