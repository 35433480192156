import { cast, types, flow } from "mobx-state-tree";
import {addToFavorites, getFavorites, removeFromFavorites} from "api/news";
import NewsItem from "./NewsItem";

const FavoriteNews = types
  .model("FavoriteNews", {
    news: types.array(NewsItem),
    shown: true,
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const switchOn = (onOrOff: boolean) => {
      self.shown = onOrOff;
    };
    const getNewsItems = flow(function* getNewsItems() {
      self.news = cast([]);
      self.state = "pending";
      try {
        // @ts-ignore
        self.news = yield cast(getFavorites());
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    const addNewsToFavorites = flow(function* addNewsToFavorites(id: number) {
      try {
        // @ts-ignore
        const newFav = yield cast(addToFavorites(id));
        if (newFav) {
          self.news = cast([...self.news, newFav]);
        }
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    const removeNewsFromFavorites = flow(function* removeNewsFromFavorites(id: number) {
      try {
        // @ts-ignore
        const removed = yield cast(removeFromFavorites(id));
        if (removed) {
          self.news = cast(self.news.filter(item => item.id !== removed.id));
        }
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    return { switchOn, getNewsItems, addNewsToFavorites, removeNewsFromFavorites };
  });
export default FavoriteNews;
