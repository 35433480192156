import { inject } from "mobx-react";
import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatInputDate } from "helpers/formatDate";
import { IUserStore } from "models";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_DatePicker.module.css";
import DOMRF_styles from "./DOMRF_DatePicker.module.css";
import MTS_styles from "./MTS_DatePicker.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_DatePicker.module.css";
import POCHTABANK_styles from "./POCHTABANK_DatePicker.module.css";
import CUSTOMS_styles from "./CUSTOMS_DatePicker.module.css";

const datePickerStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: POCHTABANK_styles,
};

interface IDateRangeProps {
  onRangeSelect: any;
  userStore?: IUserStore;
}

const DateRangePicker: React.FC<IDateRangeProps> = ({
  userStore,
  onRangeSelect
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const endDateInput = useRef(null);
  const startDateInput = useRef(null);

  const handleStartDateChange = (date: Date) => {
    const t = (endDateInput && endDateInput.current) || {
      handleFocus: () => {}
    };
    t.handleFocus();
    setEndDate(date);
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    onRangeSelect(startDate, endDate);
  };

  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const styles = datePickerStyles[company];

  return (
    <>
      <div className={styles.dateRangeValue}>
        {`${startDate ? formatInputDate(startDate) : "dd/MM/yyyy"} - ${
          endDate ? formatInputDate(endDate) : "dd/MM/yyyy"
        }`}
      </div>
      <div className={styles.dateInput}>
        <DatePicker
          ref={endDateInput}
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy"
        />
      </div>
      <div className={styles.dateInput}>
        <DatePicker
          ref={startDateInput}
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </>
  );
};

export default inject("userStore")(DateRangePicker);
