import React, { FC } from "react";
import styled from "styled-components";
import { IFilteredSmiStore } from "../../models";
import { types } from "mobx-state-tree";
import eyeImage from "../../assets/eyeImage.png";
import google from "../../assets/google.png";
import topMedia from "../../assets/topMedia.png";
import yandex from "../../assets/yandex.png";
import checkedImg from "../../assets/checked.png";
import unCheckedImg from "../../assets/unChecked.png";

interface IProps {
  checked: boolean;
  setChecked: (val: number) => void;
  smi_id: number;
  name: string;
  web_link: string;
  smi_type_id: number;
  count_made: number;
  traffic: number;
  mpe_index: number | null;
  price: number;
  region_id: number | null;
  yandex_news: number;
  google_title: number;
  in_medialogy: number;
  top_media: number;
  cost_sum: number;
}

export const SMiListItemMobile: FC<IProps> = ({
  checked,
  setChecked,
  smi_id,
  name,
  traffic,
  web_link,
  mpe_index,
  price,
  google_title,
  top_media,
  yandex_news,
  cost_sum,
}) => {
  return (
    <Container>
      <TitleBlock>
        <ViewsBlock>
          <Title>{name}</Title>
          <ViewStatistic>
            <EyeImage src={eyeImage} />
            <ViewLabel>{traffic} ЧЕЛ/МЕС.</ViewLabel>
          </ViewStatistic>
          <LinkToSmi href={web_link}>{web_link}</LinkToSmi>
        </ViewsBlock>
        <CheckBoxBlock>
          <CheckImage
            src={checked ? checkedImg : unCheckedImg}
            onClick={() => setChecked(smi_id)}
          />
          <GreenNumber bold>{`${cost_sum} ₽`}</GreenNumber>
        </CheckBoxBlock>
      </TitleBlock>
      <StatiscticGrid>
        <InfoBlock>
          <InfoLabel>Охват</InfoLabel>
          <GreenNumber bold>{traffic / 1000} ТЫС.</GreenNumber>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>Индексируемость</InfoLabel>
          <IndexBlock>
            {!!google_title && (
              <MediaImage
                src={google}
                placeholder={"Индексируется Google"}
                alt={"Индексируется Google"}
                title={"Индексируется Google"}
              />
            )}
            {!!yandex_news && (
              <MediaImage
                src={yandex}
                placeholder={"Индексируется Yandex"}
                alt={"Индексируется Yandex"}
                title={"Индексируется Yandex"}
              />
            )}
            {!!top_media && (
              <MediaImage
                src={topMedia}
                placeholder={"Индексируется TopMedia"}
                alt={"Индексируется TopMedia"}
                title={"Индексируется TopMedia"}
              />
            )}
          </IndexBlock>
        </InfoBlock>
        <InfoBlock>
          <InfoLabel>MPEIndex</InfoLabel>
          <GreenNumber>{!!mpe_index ? mpe_index : `N/A`}</GreenNumber>
        </InfoBlock>
      </StatiscticGrid>
    </Container>
  );
};

const Container = styled.tr`
  font-family: Stem, serif;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(33, 33, 33, 0.18);
  padding: 30px 12px;
`;

const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  max-width: 76%;
  font-size: 13px;
  line-height: 17px;
  color: #4f4f4f;
`;

const CheckBoxBlock = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  & > :last-child {
    margin-top: 10px;
  }
`;
const CheckImage = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

const GreenNumber = styled.span<{ bold?: boolean }>`
  font-family: Stem, serif;
  font-style: normal;
  white-space: nowrap;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 14px;
  line-height: 21px;
  color: #67b580;
`;

const ViewsBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const ViewStatistic = styled.div`
  display: flex;
  margin-top: 18px;
`;

const EyeImage = styled.img`
  width: 32px;
  height: 18px;
  object-fit: contain;
`;

const ViewLabel = styled.p`
  padding-left: 7px;
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  /* or 182% */

  text-transform: uppercase;

  /* Light grey */

  color: #828282;
`;

const LinkToSmi = styled.a`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #67b580;
  padding-top: 8px;
`;

const StatiscticGrid = styled.div`
  display: grid;
  padding-top: 30px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px 40px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-flow: column;
  & > :first-child {
    margin-bottom: 5px;
  }
`;

const InfoLabel = styled.span`
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #4f4f4f;
`;

const IndexBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 15px;
`;

const MediaImage = styled.img`
  display: block;
  width: 27px;
  height: 27px;
`;
