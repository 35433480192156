exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./images/checkbox_marker.svg"));

// Module
exports.push([module.id, ".ASV_TagList_root__62bQB {\n  border: 1px solid rgba(141,150,201, 0.18);\n  border-width: 1px 0;\n  margin-bottom: 40px;\n  padding: 30px 0 0;\n}\n\n.ASV_TagList_tagList__1YkxD {\n  display: inline-block;\n  list-style: none;\n  margin: 0 0 15px;\n  padding: 0;\n}\n\n.ASV_TagList_tag__3h6R9 {\n  color: var(--asv-green);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-bottom: 15px;\n  margin-right: 30px;\n  text-decoration: underline;\n  vertical-align: middle;\n}\n\n.ASV_TagList_tagCheckbox__9HPqT {\n  border: none;\n  background: none;\n  height: 12px;\n  visibility: hidden;\n  width: 12px;\n}\n\n.ASV_TagList_checkboxMarker__2X8HH {\n  border: 1px solid rgba(130,130,130,0.3);\n  border-radius: 50%;\n  display: inline-block;\n  height: 12px;\n  position: relative;\n  vertical-align: middle;\n  width: 12px;\n}\n\n.ASV_TagList_checkboxMarker__2X8HH:after {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  display: block;\n  height: 12px;\n  position: absolute;\n  bottom: 2px;\n  left: 2px;\n  width: 12px;\n}\n\n.ASV_TagList_tagCheckbox__9HPqT:checked + .ASV_TagList_checkboxMarker__2X8HH:after {\n  content: '';\n}\n\n.ASV_TagList_label__e2v2r {\n  color: var(--asv-light-gray);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-right: 20px;\n  margin-bottom: 15px;\n  vertical-align: middle;\n}\n\n.ASV_TagList_gotoSearch__ufaPb {\n  margin-bottom: 30px;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "ASV_TagList_root__62bQB",
	"tagList": "ASV_TagList_tagList__1YkxD",
	"tag": "ASV_TagList_tag__3h6R9",
	"tagCheckbox": "ASV_TagList_tagCheckbox__9HPqT",
	"checkboxMarker": "ASV_TagList_checkboxMarker__2X8HH",
	"label": "ASV_TagList_label__e2v2r",
	"gotoSearch": "ASV_TagList_gotoSearch__ufaPb"
};