import { types, flow, cast } from "mobx-state-tree";
import { logout, tryLogin } from "../api/auth";
import DEFAULT_COMPANY from "constants/company";
import {
  loadFromStorage,
  removeFromStorage,
  saveToStorage,
} from "../helpers/storageHelpers";

export const UserInfo = types.model("UserInfo", {
  id: types.maybeNull(types.number),
  email: "",
  firstName: types.string,
  lastName: "",
  avatar: types.maybeNull(types.string),
  company_logo: types.maybeNull(types.string),
  gosmi: types.maybeNull(types.string),
  integrum: types.maybeNull(types.boolean),
});

export const User = types
  .model("User", {
    auth_token: types.maybeNull(types.string),
    info: types.maybeNull(UserInfo),
    errors: types.maybe(types.string),
    company: DEFAULT_COMPANY,
    state: types.enumeration("State", ["pending", "done", "error"]),
  })
  .actions((self) => ({
    login: flow(function* (data) {
      self.state = "pending";
      try {
        const res = yield tryLogin(data);
        self.state = "done";
        if (res.auth_token) {
          self.auth_token = res.auth_token;
          self.info = cast({
            firstName: res.first_name,
            lastName: res.last_name,
            avatar: res.photo,
            company_logo: res.company_logo,
            gosmi: res.gosmi,
            integrum: res.integrum
          });
          saveToStorage("auth_token", self.auth_token || "");
          saveToStorage("user", JSON.stringify(self.info));
          saveToStorage("gosmi", JSON.stringify(self.info && self.info.gosmi));
          saveToStorage("Integrum", JSON.stringify(self.info && self.info.integrum));
          // tryLoginGoSmi(res.gosmi);
        }
        if (res.code === "auth_error") {
          self.errors = res.message;
        }
      } catch (e) {
        console.log("error ", e);
      }
    }),
    logout: flow(function* () {
      try {
        const res = yield logout();
        self.auth_token = "";
        self.info = cast({
          firstName: "",
        });
        localStorage.clear();

        window.location.href = "/";
      } catch (e) {
        console.log("error ", e);
      }
    }),
    setCompany: function (company: string) {
      self.company = company;
    },
  }))
  .views((self) => ({
    get loggedIn() {
      if (self.auth_token) {
        return true;
      }
      const auth_token = loadFromStorage("auth_token");
      return !!auth_token;
    },
    get userInfo() {
      if (self.info) {
        return self.info;
      }
      const user = loadFromStorage("user");
      return JSON.parse(user || "");
    },
    get token() {
      if (self.auth_token) {
        return self.auth_token;
      }
      const auth_token = loadFromStorage("auth_token");
      if (auth_token) {
        return auth_token;
      }
      return "";
    },
  }));
