import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  Theme,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CssTextField } from "../ui/Input";
import { CustomInputLabel } from "../ui/CustomInputLabel";
import { observer } from "mobx-react";
import { IFilterSmi } from "../../models";
import { IFilter } from "../../pages/CreateOrder/CreateOrder";
import _ from "lodash";

interface IProps {
  filter: IFilter;
  setFilter: (filter: IFilter) => void;
  loadMore: () => void;
  filterStore?: IFilterSmi;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      maxWidth: 200,
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(130, 130, 130, 0.1)",
      border: "1px solid rgba(79,79,79,0.1)",
      boxSizing: "border-box",
      borderRadius: "30px",

      "& > .MuiInputLabel-formControl": {
        top: "-6px",
        left: "18px",
      },
      "&> label + .MuiInput-formControl": {
        width: "90%",
        marginTop: "8px",
        marginBottom: "8px",
        marginLeft: "10px",
      },
      "&>.MuiInputLabel-shrink": {
        top: "-18px",
      },

      "& > .MuiInput-root": {
        width: "100%",
      },

      "& > #type-smi": {
        fontFamily: "Stem, serif",
      },
      "& > #region-label": {
        fontFamily: "Stem, serif",
      },
      "& > #index-label": {
        fontFamily: "Stem, serif",
      },
      "& > #subject-label": {
        fontFamily: "Stem, serif",
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 300,
      width: 300,
    },
  },
};

export const FiltersBlock: FC<IProps> = observer(
  ({ filter, filterStore, setFilter, loadMore }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [oldState, setOldState] = useState(filter);

    function getStyles(name: string, personName: string[], theme: Theme) {
      return {
        // "border-bottom": "1px gray solid",
        fontWeight:
          personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }

    const search = (value: any) =>
        setFilter({ ...filter, search: value})

    const checkAnyThingChanged = useCallback(() => {
      !_.isEqual(filter, oldState) && loadMore();
    }, [filter, oldState, loadMore, _]);


    return (
      <Container>
        <TitleContainer>
          <Title>Фильтры</Title>
            <SearchSmi
                >
                <SearchSmiInput
                    onKeyUp={checkAnyThingChanged}
                    onInput={(event: any) => search(event.target.value)}
                    placeholder={"Поиск по названию"}
                />
                  {/*<Bucket*/}
                  {/*  onClick={checkAnyThingChanged}*/}
                  {/*  viewBox={"-4 -4 30 30"}*/}
                  {/*  style={{*/}
                  {/*      stroke:"#797979",*/}
                  {/*      fill:"#797979",*/}
                  {/*      height: "40%",*/}
                  {/*      width: "40%",*/}
                  {/*      margin: "5% 0% auto auto",*/}
                  {/*      display: "block",*/}
                  {/*  }}*/}
                  {/*/>*/}
                  {/*<Reset*/}
                  {/*  onClick={() => { checkAnyThingChanged(); search('');}}*/}
                  {/*  style={{fill:"#797979", margin: "5% 0% 0% 0%", height: "40%", width: "40%",}}*/}
                  {/*/>*/}
            </SearchSmi>
        </TitleContainer>

        <Filters>
        <FormControl className={classes.formControl}>
          <CustomInputLabel id="type-smi">Уровень СМИ</CustomInputLabel>
          <Select
            labelId="type-smi"
            multiple
            onClose={checkAnyThingChanged}
            disableUnderline
            value={filter.smiType}
            onChange={(val: any) =>
              setFilter({ ...filter, smiType: val.target.value })
            }
            input={<CssTextField />}
            MenuProps={MenuProps}
          >
            {filterStore &&
              filterStore.smiType.item.map(({ name, id }) => (
                <MenuItem
                  key={id}
                  value={id}
                  style={getStyles(
                    name,
                    filter.smiType.map((smi) => smi.toString()),
                    theme
                  )}
                >
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <CustomInputLabel id="region-label">Регион</CustomInputLabel>
          <Select
            labelId="region-label"
            multiple
            onClose={checkAnyThingChanged}
            value={filter.region}
            onChange={(val: any) =>
              setFilter({ ...filter, region: val.target.value })
            }
            input={<CssTextField />}
            MenuProps={MenuProps}
          >
            {filterStore &&
              filterStore.regions.Region.map(({ name, district_id }) => (
                <MenuItem
                  key={district_id}
                  value={district_id}
                  style={getStyles(
                    name,
                    filterStore.regions.Region.map((smi) => smi.name),
                    theme
                  )}
                >
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <CustomInputLabel id="index-label">Индексируемость</CustomInputLabel>
          <Select
            labelId="index-label"
            multiple
            onClose={checkAnyThingChanged}
            value={filter.indexing}
            onChange={(val: any) =>
              setFilter({ ...filter, indexing: val.target.value })
            }
            input={<CssTextField />}
            MenuProps={MenuProps}
          >
            {filterStore &&
              filterStore.indexable.item
                .filter((item) => item.field_name !== "medialogy")
                .map(({ name }) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(
                      name,
                      filterStore.indexable.item.map((smi) => smi.name),
                      theme
                    )}
                  >
                    {name}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <CustomInputLabel id="subject-label">Темы</CustomInputLabel>
          <Select
            labelId="subject-label"
            multiple
            onClose={checkAnyThingChanged}
            value={filter.subject}
            onChange={(val: any) =>
              setFilter({ ...filter, subject: val.target.value })
            }
            input={<CssTextField />}
            MenuProps={MenuProps}
          >
            {filterStore &&
              filterStore.smiThemes.item.map(({ name, id }, index) => (
                <MenuItem
                  key={index + id + name}
                  value={name}
                  style={getStyles(
                    name,
                    filterStore.smiThemes.item.map((smi) => smi.name),
                    theme
                  )}
                >
                  {name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        </Filters>
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
  padding: 45px;
  font-family: Stem, serif;
  border: 1px solid rgba(130, 130, 130, 0.18);
  box-sizing: border-box;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
  border-radius: 24px;
  background: #ffffff;
  margin-top: 18px;

  @media screen and (max-width: 767px) {
    padding: 15px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
  }

  @media screen and (max-width: 1023px) {
    border: initial;
    border-top: 1px solid rgba(130, 130, 130, 0.18);
    border-bottom: 1px solid rgba(130, 130, 130, 0.18);

    border-radius: initial;
  }
`;

const SearchSmi = styled.div`
  height: 50px;
  width: 300px;
  border: 1px solid #F2F2F2;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
`;

const SearchSmiInput = styled.input`
  width: auto;
  border: none;
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  &:focus {
    outline: none;
`;

const Filters = styled.div`
  margin-top: 18px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-column-gap: 30px;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    justify-content: center;
    & > :nth-child(2) {
      display: none;
    }
  }
`;

const Title = styled.h4`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  margin-right: auto;
  /* or 167% */

  /* Grey */

  color: #4f4f4f;
`;

