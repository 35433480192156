import { types } from "mobx-state-tree";
import { saveToStorage } from "helpers/storageHelpers";

export type TViewType = "list" | "grid" | "magazine";

export const ViewType = types
  .model("ViewType", {
    view: types.union(
      types.literal("list"),
      types.literal("grid"),
      types.literal("magazine")
    )
  })
  .actions(self => ({
    changeView: (view: TViewType) => {
      self.view = view;
      saveToStorage("viewType", view);
    }
  }));

export const ViewTypeStore = ViewType.create({ view: "magazine" });
