import React, { Fragment } from "react";
import { ActualNews, Footer, Header, NewsGrid, NewsIntegrum } from "components";

import styles from "./Main.module.css";

const ASV_Main: React.FC = () => {
  return (
    <Fragment>
      <Header />
      <div className="container">
        <div className={styles.news}>
          <div className={localStorage.getItem('Integrum') == 'true' ? styles.newsFeed : styles.newsFeedMin}>
            <NewsIntegrum />
          </div>
          <div className={localStorage.getItem('Integrum') == 'true' ? styles.actualNews : styles.actualNewsMin}>
            <ActualNews />
          </div>
        </div>
        <NewsGrid />
      </div>
      <Footer />
    </Fragment>
  );
  localStorage.removeItem('Integrum');
};

export default ASV_Main;
