import { types } from "mobx-state-tree";
import { loadFromStorage } from "../helpers/storageHelpers";
import { User } from "./User";
import { Menu } from "./MenuStore";
import { ViewType } from "./ViewType";
import Filter from "./FilterSmi";
import FilteredSmiStore from "./SMI";

const RootStore = types.model({
  userStore: User,
  menuStore: Menu,
  viewType: ViewType,
  filterStore: Filter,
  filteredSmiStore: FilteredSmiStore,
});

const userStore = User.create({ state: "done" });
const filteredSmiStore = FilteredSmiStore.create({
  state: "pending",
  smiList: [],
  hasMore: true,
  filter: { page: 0, limit: 50 },
});

const filterStore = Filter.create({
  state: "pending",
  smiType: {},
  smiThemes: {},
  regions: {},
  indexable: {},
  smiSearch: {name: ''},
});
const menuStore = Menu.create({ menu: [], state: "pending" });
const viewType = ViewType.create({
  view:
      (loadFromStorage("viewType") as "magazine" | "grid" | "list") || "magazine",
});

export default RootStore.create({
  userStore,
  menuStore,
  viewType,
  filterStore,
  filteredSmiStore,
});
