export const saveToStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const loadFromStorage = (key: string) => localStorage.getItem(key);

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};

