import star from 'assets/star.svg';
import lang from 'assets/account_lang.svg';
import comments from 'assets/account_comments.svg';
import settings from 'assets/account_settings.svg';

const ACCOUNT_MENU = [
  // {
  //   id: 1,
  //   name: 'Настройка главной страницы',
  //   icon: settings,
  // },
  {
    id: 2,
    name: 'Избранное',
    link: '/account',
    isActive: true,
    icon: star,
  },
  // {
  //   id: 3,
  //   name: 'Комментарии',
  //   icon: comments,
  // },
  // {
  //   id: 4,
  //   name: 'Переводы',
  //   icon: lang,
  // },
];

export default ACCOUNT_MENU;
