exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".MTS_Menu_menu__19miU {\n    display: none;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-pack: center;\n        justify-content: center;\n    list-style: none;\n    margin: 0;\n    padding: 0;\n}\n\n.MTS_Menu_item__2tt6z {\n    display: block;\n    font-size: 14px;\n    margin: 0 20px;\n}\n\n.MTS_Menu_link__2th7U {\n    color: #000000;\n    text-decoration: none;\n    -webkit-transition: opacity 0.3s ease-out;\n    transition: opacity 0.3s ease-out;\n    white-space: nowrap;\n}\n\n.MTS_Menu_link__2th7U:hover {\n    opacity: 0.7;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .MTS_Menu_menu__19miU {\n        -ms-flex-align: center;\n            align-items: center;\n        display: -ms-flexbox;\n        display: flex;\n        -ms-flex: 1 1 100%;\n            flex: 1 1 100%;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    }\n}", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"menu": "MTS_Menu_menu__19miU",
	"item": "MTS_Menu_item__2tt6z",
	"link": "MTS_Menu_link__2th7U"
};