exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".ASV_ActualNews_root__Y_WEN {\n    height: 100%;\n    position: relative;\n}\n\n.ASV_ActualNews_title__1wwm0 {\n    color: #fff;\n    font-size: 18px;\n    left: 15px;\n    line-height: 30px;\n    margin: 0;\n    position: absolute;\n    top: 18px;\n    z-index: 2;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .ASV_ActualNews_title__1wwm0 {\n        left: 90px;\n    }\n}\n", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"large-desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["large-desktop"] + "",
	"root": "ASV_ActualNews_root__Y_WEN",
	"title": "ASV_ActualNews_title__1wwm0"
};