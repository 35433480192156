import React, { FC } from "react";
import styled from "styled-components";
import { InfoStatistic } from "../../pages/CreateOrder/CreateOrder";
import {useHistory} from "react-router-dom";
import {ReactComponent as GoBack} from "../../assets/back_icon.svg";

interface Props extends InfoStatistic {
  activePath?: string;
}

export const InterActionPanel: FC<Props> = ({
  all_orders,
  in_work,
  complete_orders,
  current_balance,
  activePath,
}) => {
  const history = useHistory();

  function navigateToHome() {
    history.goBack();
  }
  return (
      <Container>
        <BalanceBlock>
          <GoBack onClick={navigateToHome} style={{margin: "2px 10px 0 0"}}/>
          <div>
            <Title>Взаимодействие со СМИ</Title>
            <Balance>
              Баланс: <ValueLabel>{current_balance} ₽</ValueLabel>
            </Balance>
          </div>
        </BalanceBlock>
        <Statistic>
          <LinkInfo href={"/interaction/statistic/inwork"} active={activePath === "/interaction/statistic/inwork"}>
            В работе: <ValueLabel>{in_work}</ValueLabel>
          </LinkInfo>
          <LinkInfo href={"/interaction/statistic/done"} active={activePath === "/interaction/statistic/done"}>
            Выполнено: <ValueLabel>{complete_orders}</ValueLabel>
          </LinkInfo>
          <LinkInfo href={"/interaction/statistic/all"} active={activePath === "/interaction/statistic/all"}>
            Все заказы: <ValueLabel>{all_orders}</ValueLabel>
          </LinkInfo>
        </Statistic>
      </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding: 30px 0;
  align-items: center;

  @media screen and (min-width: 1420px) {
    flex-flow: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 1023px) {
    background-color: #ffffff;
  }
`;

const BalanceBlock = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: 1420px) {
    text-align: initial;
  }
  
  "&> div": {
    flex-direction: column;
  },
`;

const Title = styled.p`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #4f4f4f;
`;

const Balance = styled.p`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #4f4f4f;
  margin-top: 9px;
`;

const ValueLabel = styled.span`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #67b580;
  text-decoration: none;
`;

const Statistic = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  grid-row-gap: 15px;

  @media screen and (min-width: 1420px) {
    grid-template-columns: repeat(4, auto);
    margin-top: 0;
  }
`;

const LinkInfo = styled.a<{active?:boolean}>`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.active ? "#67B580" : "#828282"} ;

  &:after {
    position: absolute;
    margin-left: 3px;
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ff5f5b;
    margin-bottom: auto;
  }
`;

const SpecialLink = styled.a`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #828282;
`;
