import React, { Fragment, useEffect, useState } from "react";
import { Footer, Header } from "../../components";
import { InterActionGrid } from "../../components/InterActionGrid/InterActionGrid";
import { InterActionPanel } from "../../components/InterActionPanel/InterActionPanel";
import { getGoSmiStatus } from "../../api/goSmi";
import { InfoStatistic } from "../CreateOrder/CreateOrder";

export const InterActionPage = () => {
  const [info, setInfo] = useState<InfoStatistic>({
    all_orders: 0,
    complete_orders: 0,
    in_work: 0,
    current_balance: 0,
  });

  useEffect(() => {
    getGoSmiStatus().then((res) => setInfo(res));
  }, []);

  return (
    <Fragment>
      <Header />
      <div className={"container"}>
        <InterActionPanel
          all_orders={info.all_orders}
          complete_orders={info.complete_orders}
          current_balance={info.current_balance}
          in_work={info.in_work}
        />
        <InterActionGrid />
      </div>
      <Footer />
    </Fragment>
  );
};
