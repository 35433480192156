import React, { PureComponent } from "react";
import { values } from "mobx";
import { inject, observer } from "mobx-react";
import {
  IntegrumStore,
  INewsIntegrumItem,
  IRootStore,
  IUserStore
} from "models";
import { Button, Settings } from "components";
import DEFAULT_COMPANY from "../../constants/company";
import NewsIntegrumItem from "./NewsIntegrumItem";

import ASV_styles from "./ASV_NewsIntegrum.module.css";
import DOMRF_styles from "./DOMRF_NewsIntegrum.module.css";
import MTS_styles from "./MTS_NewsIntegrum.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsIntegrum.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsIntegrum.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsIntegrum.module.css";

interface INewsIntegrumProps {
  store?: IRootStore;
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

@inject("userStore")
@observer
class NewsIntegrum extends PureComponent<INewsIntegrumProps> {
  constructor(props: INewsIntegrumProps) {
    super(props);

    IntegrumStore.getNewsItems();
  }

  render() {
    const { page, total_pages } = IntegrumStore.meta;
    const isThereMore = page < total_pages;
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const { styles } = assets[company];
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          <Settings title="Лента новостей" />
        </div>
        {(IntegrumStore.state === "done" ||
          IntegrumStore.state === "loading") && (
          <div className={styles.feedWrapper}>
            <div className={styles.feed}>
              {localStorage.setItem('Integrum', IntegrumStore.hasNews.toString())}
              {values(IntegrumStore.news).map((item, index) => (
                <NewsIntegrumItem
                  key={`${item.publication_at}-${index}`}
                  {...(item as INewsIntegrumItem)}
                />
              ))}
              {isThereMore && (
                <Button
                  className={styles.show_more}
                  size="small"
                  onClick={IntegrumStore.getNextPage}
                >
                  Показать больше
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}



export default NewsIntegrum;
