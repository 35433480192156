exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".MTS_Header_header__21ayr {\n    background: var(--mts-header-bg);\n    height: 85px;\n}\n\n.MTS_Header_header__21ayr .container {\n    -ms-flex-align: center;\n        align-items: center;\n    display: -ms-flexbox;\n    display: flex;\n    height: 100%;\n}\n\n/*.brand {*/\n/*    flex: 0 0 60px;*/\n/*}*/\n\n.MTS_Header_logo__3XYQe {\n    display: block;\n    height: 36px;\n}\n\n.MTS_Header_logoText__qDYaO {\n    display: none;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .MTS_Header_header__21ayr {}\n\n    .MTS_Header_logo__3XYQe {\n        display: none;\n    }\n    .MTS_Header_logoText__qDYaO {\n        height: 41px;\n        display: block;\n    }\n}\n", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"header": "MTS_Header_header__21ayr",
	"logo": "MTS_Header_logo__3XYQe",
	"logoText": "MTS_Header_logoText__qDYaO"
};