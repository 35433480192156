import { inject, observer } from "mobx-react";
import React from "react";
import {Link, useHistory} from "react-router-dom";
import cn from "classnames";
import { IRootStore, IMenuItem } from "models";
import DEFAULT_COMPANY from "../../constants/company";

import ASV_styles from "./ASV_SecondaryMenu.module.css";
import DOMRF_styles from "./DOMRF_SecondaryMenu.module.css";
import MTS_styles from "./MTS_SecondaryMenu.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_SecondaryMenu.module.css";
import POCHTABANK_styles from "./POCHTABANK_SecondaryMenu.module.css";
import CUSTOMS_styles from "./CUSTOMS_SecondaryMenu.module.css";

interface ISecondaryMenuProps {
  rootStore?: IRootStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

const SecondaryMenu: React.FC<ISecondaryMenuProps> = ({ rootStore }) => {

  const { listen } = useHistory();
  listen((location, action) => {
    rootStore && rootStore.menuStore.closeMenu();
  });

  const company = (rootStore && rootStore.userStore.company) || DEFAULT_COMPANY;
  const menuStore = (rootStore && rootStore.menuStore) || {
    menu: [],
    open: false,
    toggleMenu: () => {},
    openMenu: () => {},
    closeMenu: () => {}
  };
  const menu: IMenuItem[] = menuStore.menu;
  console.log("menu ",rootStore)
  const open: boolean = menuStore.open;
  const { styles } = assets[company];

  const renderMenuItem = ({ id, name }: IMenuItem) => {
    return (
      <li key={name} className={styles.item}>
        <Link className={styles.link} to={`/feeds/${id}`}>
          {name}
        </Link>
      </li>
    );
  };

  return (
    <div className={cn(styles.root, { [styles.open]: open })}>
      <div className={styles.menuToggler} onClick={menuStore.toggleMenu} />
      <div className="container">
        <ul className={styles.menu} onClick={menuStore.closeMenu}>
          {menu.map(renderMenuItem)}
        </ul>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(SecondaryMenu));
