import React, { FC } from "react";
import styles from "./InterActionCard.module.css";

export interface IInterActionCard {
  title: string;
  description: string;
  logo: string;
  link: string;
  isExternalLink?: boolean;
  buttonText: string;
}

export const InterActionCard: FC<IInterActionCard> = ({
  description,
  isExternalLink,
  link,
  logo,
  title,
  buttonText,
}) => {
  return (
    <div className={styles.container}>
      <img className={styles.logo} alt={title} src={logo} />
      <div className={styles.infoBlock}>
        <h4 className={styles.title}>{title}</h4>
        <p className={styles.description}>{description}</p>
        <a
          href={link}
          target={!!isExternalLink ? "_blank" : "_self"}
          className={styles.button}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};
