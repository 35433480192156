import {createMuiTheme, createStyles} from "@material-ui/core";

export const myTheme = createMuiTheme({
  overrides: {
    MuiMenuItem: createStyles({
      root: {
      fontFamily:"Stem,serif"
      },
    })
  }
});
