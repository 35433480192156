exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".CUSTOMS_Header_header__1skSD {\n    background: var(--customs-header-bg);\n    height: 85px;\n}\n\n.CUSTOMS_Header_header__1skSD .container {\n    -ms-flex-align: center;\n        align-items: center;\n    display: -ms-flexbox;\n    display: flex;\n    height: 100%;\n}\n\n/*.brand {*/\n/*    flex: 0 0 60px;*/\n/*}*/\n\n.CUSTOMS_Header_logo__1GRm4 {\n    display: block;\n    height: 36px;\n}\n\n.CUSTOMS_Header_logoText__GImjE {\n    display: none;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .CUSTOMS_Header_header__1skSD {}\n\n    .CUSTOMS_Header_logo__1GRm4 {\n        display: none;\n    }\n    .CUSTOMS_Header_logoText__GImjE {\n        height: 41px;\n        display: block;\n    }\n}\n", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"header": "CUSTOMS_Header_header__1skSD",
	"logo": "CUSTOMS_Header_logo__1GRm4",
	"logoText": "CUSTOMS_Header_logoText__GImjE"
};