import React from "react";
import cn from "classnames";
import styles from "./SettingsMenu.module.css";

interface MenuItem {
  title: string;
  callback: any;
  isRemove?: true;
}

interface SettingsMenuProps {
  menu: MenuItem[];
  isOpen: boolean
}

const renderSettingsMenuItem = ({ title, callback, isRemove }: MenuItem) => (
  <li
    className={cn(styles.item, { [styles.remove]: isRemove })}
    onClick={callback}
  >
    {title}
  </li>
);

const renderSettingsMenu = (menu: MenuItem[]) => (
  <ul className={styles.menu}>
    {menu.map(renderSettingsMenuItem)}
  </ul>
);

const SettingsMenu: React.FC<SettingsMenuProps> = ({menu, isOpen}) => (
  <div className={cn(styles.modal, {[styles.open]: isOpen})}>
    {renderSettingsMenu(menu)}
  </div>
);

export default SettingsMenu;
