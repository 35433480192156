exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DOMRF_ViewType_viewTypes__1dEdP {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  /*position: absolute;*/\n  /*right: 0;*/\n  /*top: 0;*/\n  /*z-index: 2;*/\n}\n\n.DOMRF_ViewType_label__FPi8f {\n  display: inline-block;\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 30px;\n  color: var(--domrf-dark-gray);\n}\n\n.DOMRF_ViewType_viewType__1zKnf {\n  color: var(--domrf-light-gray);\n  display: inline-block;\n  height: 18px;\n  margin-left: 20px;\n  opacity: 0.45;\n  width: 18px;\n}\n\n.DOMRF_ViewType_active__356lN {\n  color: var(--domrf-light-green);\n  opacity: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"viewTypes": "DOMRF_ViewType_viewTypes__1dEdP",
	"label": "DOMRF_ViewType_label__FPi8f",
	"viewType": "DOMRF_ViewType_viewType__1zKnf",
	"active": "DOMRF_ViewType_active__356lN"
};