import React, { PureComponent, Fragment } from "react";
import { observer, Provider } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { NewsItemPage, INewsItemPage } from "models";
import { Footer, Header, NewsPage } from "components";

interface INewsItemPageProps {
  id?: string;
}

interface Props extends RouteComponentProps<INewsItemPageProps> {}

@observer
class DOMRF_NewsItem extends PureComponent<Props> {
  private readonly pageStore: INewsItemPage;

  constructor(props: Props) {
    super(props);

    const { id } = props.match.params;

    this.pageStore = NewsItemPage.create({ page: { id: 0 }, state: "pending" });
    //@ts-ignore
    this.pageStore.getNewsItem("MedialogyNews", Number(id));
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="container">
          <Provider pageStore={this.pageStore}>
            <NewsPage />
          </Provider>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(DOMRF_NewsItem);
