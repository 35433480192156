import { cast, flow, Instance, types } from "mobx-state-tree";
import { getSMIList } from "../api/goSmi";

export const SmiTypeModel = types.array(
  types.model({
    cost_sum: types.number,
    count_made: types.number,
    google_title: types.number,
    in_medialogy: types.number,
    name: types.string,
    price: types.number,
    region_id: types.maybeNull(types.number),
    smi_id: types.number,
    smi_type_id: types.number,
    themes: types.model({
      theme: types.array(
        types.model({
          smi_theme_id: types.number,
          name: types.string,
        })
      ),
    }),
    web_link: types.string,
    traffic: types.number,
    mpe_index: types.maybeNull(types.number),
    yandex_news: types.number,
    top_media: types.number,
  })
);

export const smiFilter = types.model({
  page: types.number,
  limit: types.number,
  smi_type_id: types.maybe(types.array(types.number)),
  region_id: types.maybe(types.array(types.number)),
  yandex_news: types.maybe(types.number),
  google_title: types.maybe(types.number),
  in_medialogy: types.maybe(types.number),
  index: types.maybe(types.number),
  no_index: types.maybe(types.number),
  theme: types.maybe(types.array(types.string)),
  clear: types.maybe(types.boolean),
  search: types.maybe(types.string),
  order_by: types.maybe(types.string),
  order_type: types.maybe(types.string),
});

const FilteredSmiStore = types
  .model("FilteredSmiStore", {
    smiList: SmiTypeModel,
    filter: smiFilter,
    hasMore: types.boolean,
    state: types.enumeration("State", ["pending", "loading", "done", "error"]),
  })
  .actions((self) => {
    const getNewsItems = flow(function* getNewsItems(
      filter: Instance<typeof smiFilter>
    ) {
      self.filter = filter;
      // self.smiList = cast([]);
      self.state = "pending";
      try {
        const { gosmi_sources } = yield cast(getSMIList(filter));
        self.smiList = filter.clear
          ? gosmi_sources
          : [...self.smiList, ...gosmi_sources];
        self.hasMore = gosmi_sources.length >= filter.limit;
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch filters", error);
        self.state = "error";
      }
    });
    return { getNewsItems };
  });

export default FilteredSmiStore;
