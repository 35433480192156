import React, { PureComponent, Fragment } from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { NewsFeedPageStore } from "models";
import { Footer, Header, NewsFeed } from "components";

interface IFeedPageProps {
  id?: string;
}

interface Props extends RouteComponentProps<IFeedPageProps> {}

@observer
class FeedPage extends PureComponent<Props> {
  componentDidMount() {
    NewsFeedPageStore.getFeed(Number(this.props.match.params.id));
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      NewsFeedPageStore.getFeed(Number(this.props.match.params.id));
    }
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="container">
          {NewsFeedPageStore.state === "done" && (
            <NewsFeed feed={NewsFeedPageStore.page} />
          )}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(FeedPage);
