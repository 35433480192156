import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {observer, Provider} from "mobx-react";
import {RootStore} from "models";
import cn from "classnames";
import {Account, FeedPage, Main, NewsIntegrumItem, NewsItem, SearchPage,} from "pages";
import "./styles/reset.css";

import ASV_styles from "./ASV_App.module.css";
import DEFAULT_COMPANY from "./constants/company";
import DOMRF_styles from "./DOMRF_App.module.css";
import MTS_styles from "./MTS_App.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_App.module.css";
import POCHTABANK_styles from "./POCHTABANK_App.module.css";
import CUSTOMS_styles from "./CUSTOMS_App.module.css";
import {InterActionPage} from "./pages/InterAction/InterActionPage";
import CreateOrder from "./pages/CreateOrder/CreateOrder";
import Login from "./components/Login/Login";
import {myTheme} from "./theme/MuiTheme";
import {MuiThemeProvider} from "@material-ui/core";
import Statistic from "./components/Statistic/Statistic";
import StatisticInWorkPage from "./components/Statistic/StatisticInWork";
import StatisticDone from "./components/Statistic/StatisticDone";

const appStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

const App: React.FC = () => {
  console.log("hehehe");
  let subdomens = window.location.hostname.match(/^([^.]*)\./);
  let domen = subdomens && subdomens[1] || DEFAULT_COMPANY;
  domen = domen.toLowerCase();
  let company = (domen && domen.toUpperCase() && Object.keys(appStyles).find(item => item.toUpperCase() === domen.toUpperCase()) || DEFAULT_COMPANY);
  console.log("domen = " + domen);
  console.log("company = " + company);
  const logo =
    domen && Object.keys(appStyles).includes(domen.toUpperCase())
      ? company
      : "DEFAULT";
  RootStore.userStore.setCompany(company);
  if (RootStore.userStore.loggedIn) {
    RootStore.menuStore.getMenuItems();
  }
  const styles = appStyles[company];

  return (
    <MuiThemeProvider theme={myTheme}>
      <Provider rootStore={RootStore} userStore={RootStore.userStore}>
        {RootStore.userStore.loggedIn ? (
          <div
            className={cn(styles.app, {
              [styles.menuOpened]: RootStore.menuStore.open,
            })}
          >
            <Router>
              <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/interaction" component={InterActionPage} />
                <Route path="/interaction/create" component={CreateOrder} />
                <Route
                  path="/interaction/statistic/inwork/"
                  component={StatisticInWorkPage}
                />{" "}
                <Route
                  path="/interaction/statistic/inwork/:id/"
                  component={StatisticInWorkPage}
                />{" "}
                <Route
                  path="/interaction/statistic/done/"
                  component={StatisticDone}
                />{" "}
                <Route
                    path="/interaction/statistic/all/"
                    component={Statistic}
                />{" "}
                <Route path="/news/:id/" component={NewsItem} />
                <Route path="/i_news/:id/" component={NewsIntegrumItem} />
                <Route path="/feeds/:id/" component={FeedPage} />
                <Route path="/search" component={SearchPage} />
                <Route path="/account" component={Account} />
              </Switch>
            </Router>
          </div>
        ) : (
          <Login logo={logo} />
        )}
      </Provider>
    </MuiThemeProvider>
  );
};

export default observer(App);
