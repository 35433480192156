exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/POCHTABANK/star.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/POCHTABANK/star_filled.svg"));

// Module
exports.push([module.id, ".POCHTABANK_Favorites_title__2VxGi {\n  margin-bottom: 45px;\n}\n\n.POCHTABANK_Favorites_favList__prsFo {\n  border-bottom: 1px solid rgba(196, 196, 196, 0.45);\n  position: relative;\n}\n\n.POCHTABANK_Favorites_favList__prsFo:last-child {\n  border-bottom: none;\n}\n\n.POCHTABANK_Favorites_list__pG78g {\n  background: #ffffff;\n  border: 1px solid rgba(130, 130, 130, 0.18);\n  border-radius: 24px;\n  -webkit-box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.06);\n          box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.06);\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n\n.POCHTABANK_Favorites_listTitle__2ZLud {\n  border-bottom: 1px solid rgba(196, 196, 196, 0.45);\n  color: var(--pochtabank-dark-blue);\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 20px;\n  padding: 25px 30px;\n}\n\n.POCHTABANK_Favorites_favoriteLink__1uRl4 {\n  cursor: pointer;\n  margin-right: 30px;\n  position: absolute;\n  top: 15px;\n  right: -20px;\n}\n\n.POCHTABANK_Favorites_favoriteLink__1uRl4::before {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  content: '';\n  display: inline-block;\n  height: 24px;\n  margin-right: 10px;\n  vertical-align: bottom;\n  width: 24px;\n}\n\n.POCHTABANK_Favorites_favoriteLink__1uRl4.POCHTABANK_Favorites_favorite__3v3Oz::before {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}", ""]);

// Exports
exports.locals = {
	"title": "POCHTABANK_Favorites_title__2VxGi",
	"favList": "POCHTABANK_Favorites_favList__prsFo",
	"list": "POCHTABANK_Favorites_list__pG78g",
	"listTitle": "POCHTABANK_Favorites_listTitle__2ZLud",
	"favoriteLink": "POCHTABANK_Favorites_favoriteLink__1uRl4",
	"favorite": "POCHTABANK_Favorites_favorite__3v3Oz"
};