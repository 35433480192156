import { Instance } from "mobx-state-tree";
import FavoriteNews from "./FavoriteNews";
import FavoriteNewsStore from "./FavoriteNewsStore";
import IntegrumStore from "./IntegrumStore";
import MainPageStore from "./MainPageStore";
import { Menu, MenuItem, MenuStore } from "./MenuStore";
import NewsFeed from "./NewsFeed";
import NewsFeedList from "./NewsFeedList";
import NewsFeedPage from "./NewsFeedPage";
import NewsFeedPageStore from "./NewsFeedPageStore";
import NewsGridStore from "./NewsGridStore";
import NewsIntegrum from "./NewsIntegrum";
import NewsIntegrumItem from "./NewsIntegrumItem";
import NewsItem from "./NewsItem";
import NewsItemPage from "./NewsItemPage";
import NewsTop from "./NewsTop";
import NewsTopStore from "./NewsTopStore";
import RootStore from "./RootStore";
import Tag from "./Tag";
import { TagCheckbox, TagList } from "./TagList";
import { User, UserInfo } from "./User";
import UserStore from "./UserStore";
import SearchStore from "./SearchStore";
import { ViewType } from "./ViewType";
import { ViewTypeStore } from "./ViewType";
import FilterSMI from "./FilterSmi";
import FilteredSmiStore from "./SMI";

export {
  FavoriteNews,
  FavoriteNewsStore,
  IntegrumStore,
  MainPageStore,
  Menu,
  MenuItem,
  MenuStore,
  NewsFeed,
  NewsFeedList,
  NewsFeedPage,
  NewsFeedPageStore,
  NewsGridStore,
  NewsIntegrum,
  NewsIntegrumItem,
  NewsItem,
  NewsItemPage,
  NewsTop,
  NewsTopStore,
  RootStore,
  SearchStore,
  Tag,
  TagCheckbox,
  TagList,
  User,
  UserInfo,
  UserStore,
  ViewTypeStore,
};

export interface IFavoriteNews extends Instance<typeof FavoriteNews> {}
export interface IFavoriteNewsStore
    extends Instance<typeof FavoriteNewsStore> {}
export interface IMainPageStore extends Instance<typeof MainPageStore> {}
export interface IMenu extends Instance<typeof Menu> {}
export interface IMenuItem extends Instance<typeof MenuItem> {}
export interface INewsFeed extends Instance<typeof NewsFeed> {}
export interface INewsFeedList extends Instance<typeof NewsFeedList> {}
export interface INewsFeedPage extends Instance<typeof NewsFeedPage> {}
export interface INewsIntegrum extends Instance<typeof NewsIntegrum> {}
export interface INewsIntegrumItem extends Instance<typeof NewsIntegrumItem> {}
export interface INewsItem extends Instance<typeof NewsItem> {}
export interface INewsItemPage extends Instance<typeof NewsItemPage> {}
export interface INewsTop extends Instance<typeof NewsTop> {}
export interface IRootStore extends Instance<typeof RootStore> {}
export interface ISearchStore extends Instance<typeof SearchStore> {}
export interface ITag extends Instance<typeof Tag> {}
export interface ITagCheckbox extends Instance<typeof TagCheckbox> {}
export interface ITagList extends Instance<typeof TagList> {}
export interface IUser extends Instance<typeof User> {}
export interface IUserInfo extends Instance<typeof UserInfo> {}
export interface IUserStore extends Instance<typeof UserStore> {}
export interface IViewType extends Instance<typeof ViewType> {}
export interface IFilterSmi extends Instance<typeof FilterSMI> {}
export interface IFilteredSmiStore extends Instance<typeof FilteredSmiStore> {}
