import { getIntegrumNews } from "api/news";
import { cast, flow, types } from "mobx-state-tree";
import Meta from "./Meta";
import NewsIntegrumItem from "./NewsIntegrumItem";
import {IntegrumStore} from "./index";

const NewsIntegrum = types
  .model("NewsIntegrum", {
    news: types.array(NewsIntegrumItem),
    meta: Meta,
    shown: true,
    state: types.enumeration("State", ["pending", "loading", "done", "error"]),
    hasNews: true,
  })
  .actions(self => {
    const switchOn = (onOrOff: boolean) => {
      self.shown = onOrOff;
    };
    const getNewsItems = flow(function* getNewsItems() {
      self.news = cast([]);
      self.state = "pending";
      try {
        // @ts-ignore
        const { integrum_news, meta } = yield cast(getIntegrumNews({page: 1, per_page: "10"}));
        self.news = integrum_news;
        self.meta = meta;
        self.state = "done";
        integrum_news.length > 0 ? self.hasNews = true : self.hasNews = false;
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
      {localStorage.setItem('Integrum', IntegrumStore.hasNews.toString())}
    });
    const getNextPage = flow(function* getPaginated() {
      const currentPage = Number(self.meta.page);
      const {total_pages} = self.meta;
      if (currentPage < total_pages) {
        self.state = "loading";
        try {
          // @ts-ignore
          const {integrum_news, meta} = yield cast(getIntegrumNews({page: currentPage + 1, per_page: "10"}));
          self.news = cast([...self.news, ...integrum_news]);
          self.meta = meta;
          self.state = "done";
          self.hasNews = true;
        } catch (error) {
          console.error("Failed to fetch news", error);
          self.state = "error";
        }
      }
    });
    return { switchOn, getNewsItems, getNextPage };
  });
export default NewsIntegrum;
