import React, { FC } from "react";
import styled from "styled-components";
import google from "../../assets/google.png";
import { format } from "date-fns";

interface SmiStatusItem {
  smiId: number;
  smiName: string;
  statusName: string;
}

interface GoSMIOrdetType {
  statusName: string;
  balance: number;
  return_balance: string;
  complete_balance: string;
  current_balance: string;
  smi: SmiStatusItem[];
}

export interface OrderItem {
  isOpen: boolean;
  setActive: (id: number) => void;
  isLast?: boolean;
  comment: string;
  company_id: number;
  created_at: string;
  current_balance: string;
  current_status: string;
  deadline: string;
  extra_docs: null;
  extra_links: null;
  extra_photos: null;
  extra_titles: null;
  full_reprint: boolean;
  number: number;
  id: number;
  id_gosmi: string;
  keywords: string;
  need_advert: boolean;
  need_source_link: boolean;
  from_gosmi_order: GoSMIOrdetType;
  original_link: string;
  rewrite_type: boolean;
  source_id: string;
  updated_at: string;
}

export const StatisticItem: FC<OrderItem> = ({
  isOpen,
  setActive,
  isLast = true,
  id,
  id_gosmi,
  deadline,
  original_link,
  keywords,
  need_source_link,
  from_gosmi_order,
  rewrite_type,
  full_reprint,
}) => {
  return (
    <Container>
      <LineBlock
        isOpen={isOpen}
        isBorderBottom={!isOpen && isLast}
        onClick={() => setActive(id)}
      >
        <NameBlock>
          <SimpleText>#{id_gosmi}</SimpleText>
          <BoldText isOpen={isOpen}>PR-волна</BoldText>
        </NameBlock>
        <DateBlock>
          <BoldTextStat>
            {from_gosmi_order && from_gosmi_order.smi
              ? from_gosmi_order.smi.reduce(
                  (previousValue, currentValue) =>
                    currentValue.statusName === "Выполнено"
                      ? previousValue + 1
                      : previousValue,
                  0
                )
              : 1}{" "}
            из{" "}
            {from_gosmi_order && from_gosmi_order.smi
              ? from_gosmi_order.smi.length
              : 1}
          </BoldTextStat>
          <SimpleText>
            {deadline}
            {/*{format(new Date(), "dd.MM.yyyy, HH:mm")}*/}
          </SimpleText>
        </DateBlock>
      </LineBlock>

      <FullInfoContainer isHidden={!isOpen}>
        <InfoBlock>
          <InfoCell>
            <GrayText>
              Ссылка на <br /> первоисточник:
            </GrayText>
            <GreenLink>{original_link}</GreenLink>
          </InfoCell>

          <InfoCell>
            <GrayText>
              Ключевые слова
              <br />
              для заголовка:
            </GrayText>
            <GreenText>{keywords}</GreenText>
          </InfoCell>

          <InfoCell>
            <GrayText>
              Тип
              <br />
              рерайта
            </GrayText>
            <GreenText>
              {full_reprint
                ? "Полная перепечатка"
                : rewrite_type
                ? "Средний"
                : "Легкий"}
            </GreenText>
          </InfoCell>

          <InfoCell>
            <GrayText>
              Ссылка на <br /> источник
            </GrayText>
            <GreenText>{need_source_link ? "Да" : "Нет"}</GreenText>
          </InfoCell>

          <InfoCell>
            <GrayText>
              Тип
              <br />
              размещения
            </GrayText>
            <GreenText>PR-волна</GreenText>
          </InfoCell>
        </InfoBlock>

        <BlackLabel>Размещено в:</BlackLabel>

        {from_gosmi_order.smi.map(({ smiId, smiName, statusName }) => (
          <PartnerBlock key={smiId + smiName}>
            <PartnerNameBlock>
              <PartnerName>{smiName}</PartnerName>
              <PartnerImage src={google} />
            </PartnerNameBlock>
            <StatusLabel
              isDeclined={statusName.toLowerCase().includes("отклонено")}
            >
              {statusName}
            </StatusLabel>
          </PartnerBlock>
        ))}

        <PriceContainer>
          <PriceBlock>
            <PriceName>Внесенная сумма:</PriceName>
            <PriceValue>{from_gosmi_order.current_balance} ₽</PriceValue>
          </PriceBlock>
          <PriceBlock>
            <PriceName>Возвращенная сумма:</PriceName>
            <PriceValue>{from_gosmi_order.return_balance} ₽</PriceValue>
          </PriceBlock>
          <PriceBlock>
            <PriceName>Списано исполнителем:</PriceName>
            <PriceValue>{from_gosmi_order.complete_balance} ₽</PriceValue>
          </PriceBlock>
        </PriceContainer>
      </FullInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: Stem, serif;
`;

const LineBlock = styled.div<{ isOpen: boolean; isBorderBottom: boolean }>`
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background: ${(props) => (props.isOpen ? "#67b580" : "#ffffff")};
  color: ${(props) => (props.isOpen ? "#ffffff" : "4F4F4F")};
  padding: 36px 45px;
  border-radius: ${(props) =>
    props.isBorderBottom ? "0px 0px 24px 24px" : "unset"};
`;

const SimpleText = styled.p`
  font-family: Stem, serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 15px;
`;

const BoldText = styled.p<{ isOpen?: boolean }>`
  font-family: Stem, serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 15px;
  color: ${(props) => (props.isOpen ? "#ffffff" : "#67B580")};
`;

const BoldTextStat = styled.p`
  font-family: Stem, serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 15px;
`;

const NameBlock = styled.div`
  display: flex;
  & > :first-child {
    padding-right: 18px;
  }
`;

const DateBlock = styled.div`
  display: flex;

  & > :first-child {
    padding-right: 40px;
  }
`;

const FullInfoContainer = styled.div<{ isHidden: boolean }>`
  display: ${(props) => (props.isHidden ? "none" : "initial")};
  background: #ffffff;
  margin-bottom: 21px;

  border: 1px solid rgba(130, 130, 130, 0.18);
  box-sizing: border-box;
  border-radius: 0px 0px 24px 24px;
`;

const InfoBlock = styled.div`
  width: 100%;
  padding: 24px 66px;
  display: grid;
  grid-template-columns: minmax(140px, 3fr) 2fr repeat(3, 1fr);
  grid-column-gap: 10%;
`;

const InfoCell = styled.div`
  display: flex;
  flex-flow: column;
  & > :first-child {
    padding-bottom: 18px;
  }
`;

const GrayText = styled.span`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: rgba(79, 79, 79, 0.45);
`;

const GreenLink = styled.a`
  cursor: pointer;
  font-family: Stem, serif;
  font-size: 18px;
  line-height: 21px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-line: underline;
  color: rgba(103, 181, 128, 1);
`;

const GreenText = styled.span`
  font-family: Stem, serif;
  font-size: 18px;
  line-height: 20px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(103, 181, 128, 1);
`;

const BlackLabel = styled.span`
  display: block;
  font-family: Stem, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 15px;
  padding: 25px 45px;
  border-bottom: 1px solid rgba(130, 130, 130, 0.18);

  color: #4f4f4f;
`;

const PartnerBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 45px;
  border-bottom: 1px solid rgba(130, 130, 130, 0.18);
`;

const PartnerNameBlock = styled.div`
  display: flex;
  align-items: center;
`;

const PartnerName = styled(GreenLink)`
  max-width: initial;
  white-space: nowrap;
  font-weight: 500;
  padding-right: 20px;
`;

const PartnerImage = styled.img`
  width: 45px;
  height: 45px;
  object-fit: contain;
`;

const StatusLabel = styled.span<{ isDeclined?: boolean }>`
  font-family: Stem, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;

  color: ${(props) => (props.isDeclined ? "#FF5F5B" : "#67B580")};
`;

const PriceContainer = styled.div`
  padding: 45px 0px;
  display: flex;
  justify-content: space-around;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const PriceName = styled.p`
  //styleName: D3;
  font-family: Stem, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0px;
  text-align: left;

  color: #67b580;

  padding-bottom: 16px;
`;

const PriceValue = styled.p`
  //styleName: T3;
  font-family: Stem, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;

  color: #67b580;
`;
