export const deadlineTable = [
  { name: "72 часа", value: 8 },
  { name: "60 часов", value: 7 },
  { name: "48 часов", value: 5 },
  { name: "36 часов", value: 12 },
  { name: "24 часа", value: 4 },
  { name: "18 часов", value: 11 },
  { name: "12 часов", value: 3 },
  { name: "10 часов", value: 10 },
  { name: "8 часов", value: 2 },
  { name: "6 часов", value: 9 },
  { name: "4 часа", value: 1 },
  { name: "2 часа", value: 13 },
];
