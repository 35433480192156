import { cast, flow, types } from "mobx-state-tree";
import { getFilters } from "../api/goSmi";

const SmiTypeModel = types.model({
    item: types.array(
        types.model({
            id: types.number,
            name: types.string,
        })
    ),
});

const RegionsModel = types.model({
    Region: types.array(
        types.model({
            district_id: types.number,
            name: types.string,
            items: types.model({
                item: types.array(
                    types.model({
                        _id: types.number,
                        name: types.string,
                        ordering: types.number,
                        district_id: types.number,
                    })
                ),
            }),
        })
    ),
});

const IndexableStatusBy = types.model({
    item: types.array(
        types.model({
            name: types.string,
            field_name: types.string,
            default: types.number,
        })
    ),
});

const SmiThemes = types.model({
    item: types.array(
        types.model({
            id: types.number,
            name: types.string,
        })
    ),
});

const SmiSearch = types.model({
        name: types.string
    })

const Filter = types
    .model("FilterModel", {
        smiType: SmiTypeModel,
        regions: RegionsModel,
        indexable: IndexableStatusBy,
        smiThemes: SmiThemes,
        smiSearch: SmiSearch,
        state: types.enumeration("State", ["pending", "loading", "done", "error"]),
    })
    .actions((self) => {
        const getSmiFilters = flow(function* getSmiFilters() {
            self.smiThemes = cast({});
            self.indexable = cast({});
            self.regions = cast({});
            self.smiType = cast({});
            self.smiSearch.name = cast("");
            self.state = "pending";
            try {
                // @ts-ignore
                const { SmiType, Regions, IndexableStatusBy, SmiThemes, SmiSearch } = yield cast(
                    getFilters()
                );
                self.smiType.item = SmiType.item;
                self.regions = Regions;
                self.indexable = IndexableStatusBy;
                self.smiThemes = SmiThemes;
                self.smiSearch.name = SmiSearch.name;
                self.state = "done";
            } catch (error) {
                console.error("Failed to fetch filters", error);
                self.state = "error";
            }
        });
        return { getSmiFilters };
    });

export default Filter;
