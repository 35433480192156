import React, { PureComponent, Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react";

import { Footer, Header, SearchPanel, SearchResults } from "components";
import SearchStore from "models/SearchStore";

interface ISearchPageProps {
  id?: string;
}

interface Props extends RouteComponentProps<ISearchPageProps> {}

@observer
class SearchPage extends PureComponent<Props> {
  componentDidMount() {
    SearchStore.getSearchResult(SearchStore.searchQuery);
  }

  render() {
    const { results } = SearchStore;
    return (
      <Fragment>
        <Header />
        <div className="container">
          <SearchPanel />
          {SearchStore.state === "done" && <SearchResults results={results} />}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(SearchPage);
