exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ASV_ViewType_viewTypes__cApru {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  /*position: absolute;*/\n  /*right: 0;*/\n  /*top: 0;*/\n  /*z-index: 2;*/\n}\n\n.ASV_ViewType_label__dwxZz {\n  display: inline-block;\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 18px;\n  color: var(--asv-dark-gray);\n}\n\n.ASV_ViewType_viewType__1KmQP {\n  color: var(--asv-light-gray);\n  display: inline-block;\n  height: 18px;\n  margin-left: 20px;\n  opacity: 0.45;\n  width: 18px;\n}\n\n.ASV_ViewType_active__VIxUx {\n  color: var(--asv-green);\n  opacity: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"viewTypes": "ASV_ViewType_viewTypes__cApru",
	"label": "ASV_ViewType_label__dwxZz",
	"viewType": "ASV_ViewType_viewType__1KmQP",
	"active": "ASV_ViewType_active__VIxUx"
};