exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CUSTOMS_SearchResults_searchResultsHeader__MCqDz {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.CUSTOMS_SearchResults_resultsTitle__1cSBy {\n  color: var(--customs-dark-gray);\n  font-size: 18px;\n  line-height: 23px;\n  margin-bottom: 25px;\n  margin-left: 20px;\n}\n\n.CUSTOMS_SearchResults_viewTypesToggler__1lA-h {\n  height: 30px;\n  position: relative;\n  margin-left: auto;\n  margin-bottom: 25px;\n}\n\n.CUSTOMS_SearchResults_total__1XqV2 {\n  color: var(--customs-green);\n  font-weight: 600;\n}", ""]);

// Exports
exports.locals = {
	"searchResultsHeader": "CUSTOMS_SearchResults_searchResultsHeader__MCqDz",
	"resultsTitle": "CUSTOMS_SearchResults_resultsTitle__1cSBy",
	"viewTypesToggler": "CUSTOMS_SearchResults_viewTypesToggler__1lA-h",
	"total": "CUSTOMS_SearchResults_total__1XqV2"
};