import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { inject } from "mobx-react";
import { IUserStore } from "models";
import { Navigation } from "components";
import DEFAULT_COMPANY from "constants/company";

import ASV_styles from "./ASV_Header.module.css";
import ASV_logo from "assets/ASV/logo.svg";
import ASV_logo_text from "assets/ASV/logo_with_text.svg";

import DOMRF_styles from "./DOMRF_Header.module.css";
import DOMRF_logo from "assets/DOMRF/logo.svg";
import DOMRF_logo_text from "assets/DOMRF/logo.svg";
import SecondaryMenu from "../SecondaryMenu/SecondaryMenu";

import MTS_styles from "./MTS_Header.module.css";
import MTS_logo from "assets/MTS/logo.png";
import MTS_logo_text from "assets/MTS/logo.png";

import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Header.module.css";
import KALASHNIKOVGROUP_logo from "assets/KALASHNIKOVGROUP/logo.png";
import KALASHNIKOVGROUP_logo_text from "assets/KALASHNIKOVGROUP/logo.png";

import POCHTABANK_styles from "./POCHTABANK_Header.module.css";
import POCHTABANK_logo from "assets/POCHTABANK/logo.png";
import POCHTABANK_logo_text from "assets/POCHTABANK/logo.png";

import CUSTOMS_styles from "./CUSTOMS_Header.module.css";
import CUSTOMS_logo from "assets/CUSTOMS/logo.png";
import CUSTOMS_logo_text from "assets/CUSTOMS/logo.png";

interface IHeaderProps {
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles,
    logo: ASV_logo,
    logo_text: ASV_logo_text
  },
  DOMRF: {
    styles: DOMRF_styles,
    logo: DOMRF_logo,
    logo_text: DOMRF_logo_text,
  },
  MTS: {
    styles: MTS_styles,
    logo: MTS_logo,
    logo_text: MTS_logo_text,
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles,
    logo: KALASHNIKOVGROUP_logo,
    logo_text: KALASHNIKOVGROUP_logo_text,
  },
  POCHTABANK: {
    styles: POCHTABANK_styles,
    logo: POCHTABANK_logo,
    logo_text: POCHTABANK_logo_text,
  },
  CUSTOMS: {
    styles: CUSTOMS_styles,
    logo: CUSTOMS_logo,
    logo_text: CUSTOMS_logo_text,
  },
};

const Header: React.FC<IHeaderProps> = ({ userStore }) => {
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const logo = userStore && userStore.userInfo && userStore.userInfo.company_logo ? userStore.userInfo.company_logo : assets[company].logo;
  const logo_text = userStore && userStore.userInfo && userStore.userInfo.company_logo ? userStore.userInfo.company_logo : assets[company].logo_text;
  //const logo_text = assets[company].logo_text;
  const { styles } = assets[company];
  return (
    <Fragment>
      <header className={styles.header}>
        <div className="container">
          <Link to="/" className={styles.brand}>
            <img src={logo} className={styles.logo} alt="logo" />
            <img
              src={logo_text}
              className={styles.logoText}
              alt="logo width text"
            />
          </Link>
          <Navigation />
        </div>
      </header>
      <SecondaryMenu />
    </Fragment>
  );
};

export default inject("userStore")(Header);
