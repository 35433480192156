import apiClient from "libs/apiClient";

const NEWS_TOP_PATH = "/api/medialogy_news/top";
const NEWS_ITEM_PATH = "/api/medialogy_news/";
const NEWS_INTEGRUM_PATH = "/api/integrum_news/";
const FEEDS = "/api/feeds/";
const FAVORITES = "/api/news/favorite";
const FAVORITE = `/api/news/`;

export const getTopNews = () => apiClient.get(`${NEWS_TOP_PATH}`);

export const getIntegrumNews = (params = {}) =>
  apiClient.get(`${NEWS_INTEGRUM_PATH}`, params);

export const getNewsFeeds = () => apiClient.get(`${FEEDS}`);

export const getFavorites = () => apiClient.get(`${FAVORITES}`);

export const getNewsItemPage = (type: string, id: number) =>
  apiClient.get(
    `${type === "IntegrumNews" ? NEWS_INTEGRUM_PATH : NEWS_ITEM_PATH}${id}`
  );

export const getNewsFeed = (id: number) => apiClient.get(`${FEEDS}${id}`);

export const getSearchResults = (query: any) =>
  apiClient.get(`${NEWS_ITEM_PATH}`, query);

export const addToFavorites = (id: number) =>
  apiClient.put(`${FAVORITE}${id}`, {
    news: { action: "add_to_favorites" }
  });

export const removeFromFavorites = (id: number) =>
  apiClient.put(`${FAVORITE}${id}`, {
    news: { action: "remove_from_favorites" }
  });
