import apiClient from "libs/apiClient";
import { gosmi_Order } from "../pages/CreateOrder/CreateOrder";
import { Instance } from "mobx-state-tree";
import { smiFilter, SmiTypeModel } from "../models/SMI";

export const getFilters = () => apiClient.get("/api/gosmi_sources/get_filters/");

export const getSMIList = (
  filter: Instance<typeof smiFilter>
): Promise<Instance<typeof SmiTypeModel>> =>
  apiClient.post(
    "/api/gosmi_sources/get_smi_list_pr_wave",
    { ...filter },
    { page: filter.page, limit: filter.limit }
  );

export const getGoSmiOrders = async () =>
  await apiClient.get("/api/gosmi_orders");

export const getGoSmiInWorkOrders = async () =>
  await apiClient.get("/api/gosmi_orders/in_work_orders");

export const getGoSmiDoneOrders = async () =>
  await apiClient.get("/api/gosmi_orders/complete_orders");

export const getGoSmiStatus = async () =>
  await apiClient.get("/api/gosmi_orders/status_orders");

export const createOrder = (data: gosmi_Order) =>
  apiClient.post("/api/gosmi_orders", data);
