import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { OrderItem, StatisticItem } from "./StatisticItem";
import { useWindowSize } from "../../helpers/useWindowsSize";
import { StatisticItemMobile } from "./StatisticItemMobile";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useParams } from "react-router-dom";

interface IProps {
  orders: OrderItem[];
  loading: boolean;
}

export const StatisticList: FC<IProps> = ({ loading, orders }) => {
  const { id } = useParams<{ id?: string }>();
  const [activeId, setActiveId] = useState(id ? Number(id) : -1);
  const isMobile = useWindowSize().width < 993;

  const setActiveIdHandler = useCallback(
    (id: number) => (id === activeId ? setActiveId(-1) : setActiveId(id)),
    [activeId, setActiveId]
  );

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader type={"TailSpin"} color="#67B580" height={80} width={80} />
        </LoaderContainer>
      )}

      {orders.length === 0 && !loading && (
        <EmptyContainer>
          <p>Нет заказаов</p>
        </EmptyContainer>
      )}

      {orders.map((order, index) =>
        !isMobile ? (
          <StatisticItem
            {...order}
            key={order.id}
            isOpen={order.id === activeId}
            id={order.id}
            setActive={setActiveIdHandler}
            isLast={index + 1 === orders.length}
          />
        ) : (
          <StatisticItemMobile
            {...order}
            key={order.id}
            isOpen={order.id === activeId}
            id={order.id}
            setActive={setActiveIdHandler}
            isLast={index + 1 === orders.length}
          />
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  align-items: center;
  flex-grow: 1;

  @media screen and (min-width: 992px) {
    & > :first-child {
      & > :first-child {
        border-radius: 24px 24px 0px 0px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    border-top: 1px solid rgba(130, 130, 130, 0.18);
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(42, 41, 41, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;
