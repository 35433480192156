exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ASV_SearchResults_searchResultsHeader__CAW2i {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.ASV_SearchResults_resultsTitle__2G6Vt {\n  color: var(--asv-dark-gray);\n  font-size: 18px;\n  line-height: 23px;\n  margin-bottom: 25px;\n  margin-left: 20px;\n}\n\n.ASV_SearchResults_viewTypesToggler__3h04o {\n  height: 30px;\n  position: relative;\n  margin-left: auto;\n  margin-bottom: 25px;\n}\n\n.ASV_SearchResults_total__OzMFY {\n  color: var(--asv-green);\n  font-weight: 600;\n}", ""]);

// Exports
exports.locals = {
	"searchResultsHeader": "ASV_SearchResults_searchResultsHeader__CAW2i",
	"resultsTitle": "ASV_SearchResults_resultsTitle__2G6Vt",
	"viewTypesToggler": "ASV_SearchResults_viewTypesToggler__3h04o",
	"total": "ASV_SearchResults_total__OzMFY"
};