exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./images/checkbox_marker.svg"));

// Module
exports.push([module.id, ".KALASHNIKOVGROUP_TagList_root__1Vyj3 {\n  border: 1px solid rgba(141,150,201, 0.18);\n  border-width: 1px 0;\n  margin-bottom: 40px;\n  padding: 30px 0 0;\n}\n\n.KALASHNIKOVGROUP_TagList_tagList__3bgp9 {\n  display: inline-block;\n  list-style: none;\n  margin: 0 0 15px;\n  padding: 0;\n}\n\n.KALASHNIKOVGROUP_TagList_tag__1Z2nv {\n  color: var(--kalashnikovgroup-dark-gray);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-bottom: 15px;\n  margin-right: 30px;\n  text-decoration: underline;\n  vertical-align: middle;\n}\n\n.KALASHNIKOVGROUP_TagList_tagCheckbox__2H7Lt {\n  border: none;\n  background: none;\n  height: 12px;\n  visibility: hidden;\n  width: 12px;\n}\n\n.KALASHNIKOVGROUP_TagList_checkboxMarker__2aj_n {\n  border: 1px solid rgba(130,130,130,0.3);\n  border-radius: 50%;\n  display: inline-block;\n  height: 12px;\n  position: relative;\n  vertical-align: middle;\n  width: 12px;\n}\n\n.KALASHNIKOVGROUP_TagList_checkboxMarker__2aj_n:after {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  display: block;\n  height: 12px;\n  position: absolute;\n  bottom: 2px;\n  left: 2px;\n  width: 12px;\n}\n\n.KALASHNIKOVGROUP_TagList_tagCheckbox__2H7Lt:checked + .KALASHNIKOVGROUP_TagList_checkboxMarker__2aj_n:after {\n  content: '';\n}\n\n.KALASHNIKOVGROUP_TagList_label__1L-Ks {\n  color: var(--kalashnikovgroup-light-gray);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-right: 20px;\n  margin-bottom: 15px;\n  vertical-align: middle;\n}\n\n.KALASHNIKOVGROUP_TagList_gotoSearch__2FP-G {\n  margin-bottom: 30px;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "KALASHNIKOVGROUP_TagList_root__1Vyj3",
	"tagList": "KALASHNIKOVGROUP_TagList_tagList__3bgp9",
	"tag": "KALASHNIKOVGROUP_TagList_tag__1Z2nv",
	"tagCheckbox": "KALASHNIKOVGROUP_TagList_tagCheckbox__2H7Lt",
	"checkboxMarker": "KALASHNIKOVGROUP_TagList_checkboxMarker__2aj_n",
	"label": "KALASHNIKOVGROUP_TagList_label__1L-Ks",
	"gotoSearch": "KALASHNIKOVGROUP_TagList_gotoSearch__2FP-G"
};