import React, { Fragment, PureComponent, SyntheticEvent } from "react";
import { inject, observer } from "mobx-react";
import {
  ITag,
  ITagList,
  ITagCheckbox,
  TagList as TagListStore,
  IUserStore
} from "models";
import { Button } from "components";
import { Link } from "react-router-dom";
import { wordEnding } from "helpers/textFormatters";
import DEFAULT_COMPANY from "../../constants/company";

import ASV_styles from "./ASV_TagList.module.css";
import DOMRF_styles from "./DOMRF_TagList.module.css";
import MTS_styles from "./MTS_TagList.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_TagList.module.css";
import POCHTABANK_styles from "./POCHTABANK_TagList.module.css";
import CUSTOMS_styles from "./CUSTOMS_TagList.module.css";

interface ITagListProps {
  tags: ITag[];
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

@inject("userStore")
@observer
class TagList extends PureComponent<ITagListProps> {
  //@ts-ignore
  constructor(props) {
    super(props);
    this.tagsStore = TagListStore.create({ tags: [] });
    this.props.tags.forEach((tag: ITag) =>
      this.tagsStore.addItem({
        id: tag.id,
        name: tag.name,
        checked: false
      } as ITagCheckbox)
    );
  }

  private tagsStore: ITagList;

  renderTag = (tag: ITagCheckbox, index: number) => {
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const { styles } = assets[company];
    return (
      <li key={tag.name} className={styles.tag}>
        <label className={styles.tagName}>
          {tag.name}
          <input
            id={`${index}`}
            type="checkbox"
            className={styles.tagCheckbox}
            onChange={() => {
              tag.toggleTeg();
            }}
            value={tag.id}
          />
          <span className={styles.checkboxMarker} />
        </label>
      </li>
    );
  };

  labelText = wordEnding("тегу", "тегам", "тегам");

  render() {
    const { tags, checkedTags } = this.tagsStore;
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const { styles } = assets[company];
    return (
      <Fragment>
        <div className={styles.root}>
          <div className={styles.tags}>
            <ul className={styles.tagList}>
              <li className={styles.label}>Теги:</li>
              {tags.map(this.renderTag)}
            </ul>
          </div>
          {checkedTags.length > 0 && (
            <Button size="small" className={styles.gotoSearch}>
              <a href={`/search?${this.tagsStore.query}`}>
                К новостям по {checkedTags.length}&nbsp;
                {this.labelText(tags.length)}
              </a>
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
}

export default TagList;
