import qs from "qs";
import React, { PureComponent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import {ITagCheckbox, ITagList, IUserStore} from "models";
import { Button } from "components";
import search from "assets/ASV/search.svg";

import {
  searchQueryForThisMonth,
  searchQueryForThisWeek,
  searchQueryForThisYear,
  searchQueryForAllPeriod,
  daysDifferense, formatToSearch
} from "helpers/formatDate";
import SearchStore, { IQuery, ISearchQuery } from "models/SearchStore";
import DEFAULT_COMPANY from "../../constants/company";
import DatePickerComponent from "../DatePicker/DatePicker";

import { colourStyles } from "./selectStyles";

import ASV_styles from "./ASV_SearchPanel.module.css";
import DOMRF_styles from "./DOMRF_SearchPanel.module.css";
import MTS_styles from "./MTS_SearchPanel.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_SearchPanel.module.css";
import POCHTABANK_styles from "./POCHTABANK_SearchPanel.module.css";
import CUSTOMS_styles from "./CUSTOMS_SearchPanel.module.css";

interface OptionType {
  label: string;
  value: string;
}

interface ISearchPanelProps {
  tagList?: ITagList;
  userStore?: IUserStore;
}

interface ISearchPanelState {
  query: string;
  tags: number[];
  selectedOption: ValueType<OptionType>;
  showDateRange: boolean;
}

const options: OptionType[] = [
  { value: searchQueryForThisWeek(), label: "за неделю" },
  { value: searchQueryForThisMonth(), label: "за месяц" },
  { value: searchQueryForThisYear(), label: "за год" },
  { value: searchQueryForAllPeriod(), label: "за все время" },
  { value: "custom", label: "выбрать период" }
];

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

@inject('userStore')
@observer
class SearchPanel extends PureComponent<ISearchPanelProps, ISearchPanelState> {
  constructor(props: ISearchPanelProps) {
    super(props);

    this.state = {
      query: "",
      tags: [],
      selectedOption: options[0],
      showDateRange: false
    };
  }

  componentDidMount(): void {
    this.getSearchValue();
  }

  componentWillUnmount(): void {
    this.hanldeSearch({});
  }

  getSearchValue = () => {
    const query: ISearchQuery = SearchStore.searchQuery;

    let index = 0;
    if (query.q) {
      const { q } = query;

      if (q.title_or_description_cont_all) {
        this.setState({ query: q.title_or_description_cont_all });
      }
      if (q.root_tag_ids) {
        this.setState({ tags: q.root_tag_ids });
      }
      if (q.publication_at_gteq && q.publication_at_lteq) {
        const diff = daysDifferense(
          q.publication_at_lteq,
          q.publication_at_gteq
        );
        switch (diff) {
          case 7:
            index = 1;
            break;
          case 28:
          case 29:
          case 30:
          case 31:
            index = 2;
            break;
          case 365:
            index = 3;
            break;
        }
        this.setState({
          selectedOption: options[index]
        });
      } else {
        this.setState({
          selectedOption: options[3]
        });
      }
    }
  };

  handleSearchChange = (event: FormEvent<HTMLInputElement>) => {
    this.setState({ query: event.currentTarget.value });
  };

  handleDateRangeSelect = (start: any, end: any) => {
    this.hanldeSearch({
      publication_at_gteq: formatToSearch(start),
      publication_at_lteq: formatToSearch(end),
    });
  };

  handleDateSelect = (selectedOption: ValueType<OptionType>) => {
    this.setState({ selectedOption }, () => {
      const selected = this.state.selectedOption as OptionType;
      const q = qs.parse(selected.value).q || {};
      if (selected.value === "custom") {
        this.setState({ showDateRange: true });
      } else {
        this.hanldeSearch({
          // @ts-ignore
          publication_at_gteq: q.publication_at_gteq,
          // @ts-ignore
          publication_at_lteq: q.publication_at_lteq
        });
      }
    });
  };

  handleSearchSubmit = (event: FormEvent) => {
    event.preventDefault();
    const query = {
      title_or_description_cont_all: this.state.query
    };
    this.hanldeSearch(query);
  };

  hanldeSearch = (query: IQuery) => {
    SearchStore.updateSearchQuery(query);
    SearchStore.getSearchResult(SearchStore.searchQuery);
  };

  render() {
    const { tagList } = SearchStore;
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const { styles } = assets[company];
    return (
      <div className={styles.searchPanel}>
        <form className={styles.inputRow} onSubmit={this.handleSearchSubmit}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Текст для поиска"
            value={this.state.query}
            onChange={this.handleSearchChange}
          />
          <Button
            className={styles.searchBtn}
            size={"big"}
            iconAfter={search}
            type="submit"
            mobile
          >
            Поиск
          </Button>
        </form>
        <div className={styles.filtersRow}>
          <div className={styles.period}>
            <Select
              styles={colourStyles}
              classNamePrefix="date-select"
              value={this.state.selectedOption}
              onChange={this.handleDateSelect}
              options={options}
            />
          </div>
          {this.state.showDateRange && (
            <div className={styles.dates}>
              <DatePickerComponent onRangeSelect={this.handleDateRangeSelect} />
            </div>
          )}
        </div>
        {tagList && tagList.tags.length > 0 && (
          <div className={styles.tagsRow}>
            <ul className={styles.tags}>
              <li className={styles.tagsLabel}>Теги:</li>
              {tagList.tags.map((tag: ITagCheckbox) => (
                <li key={`${tag.id}-${tag.name}`} className={styles.tag}>
                  <span className={styles.tagName}>{tag.name}</span>
                  <span
                    onClick={() => tag.remove()}
                    className={styles.tagRemove}
                  >
                    &times;
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default SearchPanel;
