exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".POCHTABANK_ViewType_viewTypes__2mfEu {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  /*position: absolute;*/\n  /*right: 0;*/\n  /*top: 0;*/\n  /*z-index: 2;*/\n}\n\n.POCHTABANK_ViewType_label__3QIQg {\n  display: inline-block;\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 30px;\n  color: var(--pochtabank-dark-blue);\n}\n\n.POCHTABANK_ViewType_viewType__3LPEZ {\n  color: var(--pochtabank-light-blue);\n  display: inline-block;\n  height: 18px;\n  margin-left: 20px;\n  opacity: 0.45;\n  width: 18px;\n}\n\n.POCHTABANK_ViewType_active__3KXOV {\n  color: #cc003d;\n  opacity: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"viewTypes": "POCHTABANK_ViewType_viewTypes__2mfEu",
	"label": "POCHTABANK_ViewType_label__3QIQg",
	"viewType": "POCHTABANK_ViewType_viewType__3LPEZ",
	"active": "POCHTABANK_ViewType_active__3KXOV"
};