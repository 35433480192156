import { types } from "mobx-state-tree";

const Tag = types.model("Tag", {
  id: types.number,
  name: types.string,
  tonality: types.maybeNull(
    types.enumeration("tonality", ["positive", "negative", "neutral"])
  ),
  keyword_list: types.array(types.string),
  active: false,
}).actions(self => ({
  toggleTag() {
    self.active = !self.active;
  }
}));

export default Tag;
