import {values} from "mobx";
import { cast, flow, Instance, types } from "mobx-state-tree";
import qs from "qs";
import { getSearchResults } from "../api/news";
import {notEmptyFields} from "../helpers/utills";
import { ITagCheckbox, NewsFeed, TagList } from "./index";

export interface IQuery {
  root_tag_ids?: number[];
  title_or_description_cont_all?: string;
  publication_at_gteq?: string;
  publication_at_lteq?: string;
}

export interface ISearchQuery {
  q?: IQuery
}

const QueryModel = types.model({
  root_tag_ids: types.maybe(types.array(types.number)),
  title_or_description_cont_all: types.maybe(types.string),
  publication_at_gteq: types.maybe(types.string),
  publication_at_lteq: types.maybe(types.string)
});

const Search = types
  .model("Search", {
    results: NewsFeed,
    tagList: TagList,
    query: QueryModel,
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const updateSearchQuery = (query: IQuery) => {
      if (Object.keys(query).length === 0) {
        self.query = {
          root_tag_ids: undefined,
          title_or_description_cont_all: undefined,
          publication_at_gteq: undefined,
          publication_at_lteq: undefined,
        };
        window.history.pushState({},"", document.location.href);
      } else {
        self.query = {
          ...self.query,
          ...query as IQueryModel
        };
        window.history.pushState({},"", `/search?${qs.stringify({ q: notEmptyFields<IQueryModel>(self.query) })}`);
      }
    };
    const getSearchResult = flow(function* getSearchResult(query) {
      self.results.news = cast({
        news_list: [],
        meta: {}
      });
      self.tagList = cast({ tags: [] });
      self.state = "pending";
      try {
        if (Object.keys(query.q).length > 0) {
          // @ts-ignore
          const res = yield cast(getSearchResults(query));
          const { medialogy_news, meta } = res;

          if (query.q.root_tag_ids && medialogy_news && medialogy_news[0].tags) {
            const queryTags = values(query.q.root_tag_ids);
            medialogy_news[0].tags.map((tag: ITagCheckbox) => {
              if (queryTags.includes(tag.id)) {
                self.tagList.addItem({
                  id: tag.id,
                  name: tag.name,
                  checked: true
                } as ITagCheckbox);
              }
            });
          }
          self.results.news = {
            news_list: medialogy_news,
            meta: meta
          };
          self.state = "done";
        }
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    return { getSearchResult, updateSearchQuery };
  })
  .views(self => ({
    get searchQuery(): ISearchQuery {
      return ({ q: notEmptyFields<IQueryModel>(self.query) });
    }
  }));

export interface ISearch extends Instance<typeof Search> {}
export interface IQueryModel extends Instance<typeof QueryModel> {}

const getQuery = () => {
  const currentSearch = qs.parse(document.location.search.slice(1));
  const { q } = currentSearch;
  if (q) {
    const {
      // @ts-ignore
      root_tag_ids,
      // @ts-ignore
      title_or_description_cont_all,
      // @ts-ignore
      publication_at_gteq,
      // @ts-ignore
      publication_at_lteq
    } = q;
    return {
      root_tag_ids: root_tag_ids && root_tag_ids.map((tag: any) => Number(tag)),
      title_or_description_cont_all,
      publication_at_gteq,
      publication_at_lteq
    };
  }
  return {};
};

const SearchStore: ISearch = Search.create({
  results: { news: { news_list: [], meta: {} } },
  query: getQuery(),
  tagList: {},
  state: "pending"
});

export default SearchStore;
