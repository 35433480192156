exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".POCHTABANK_Menu_menu__1VA0B {\n    display: none;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    -ms-flex-pack: center;\n        justify-content: center;\n    list-style: none;\n    margin: 0;\n    padding: 0;\n}\n\n.POCHTABANK_Menu_item__1vzJq {\n    display: block;\n    font-size: 14px;\n    margin: 0 20px;\n}\n\n.POCHTABANK_Menu_link__15YlC {\n    color: var(--pochtabank-menu-text);\n    text-decoration: none;\n    -webkit-transition: opacity 0.3s ease-out;\n    transition: opacity 0.3s ease-out;\n    white-space: nowrap;\n}\n\n.POCHTABANK_Menu_link__15YlC:hover {\n    opacity: 0.7;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .POCHTABANK_Menu_menu__1VA0B {\n        -ms-flex-align: center;\n            align-items: center;\n        display: -ms-flexbox;\n        display: flex;\n        -ms-flex: 1 1 100%;\n            flex: 1 1 100%;\n        -ms-flex-direction: row;\n            flex-direction: row;\n    }\n}", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"menu": "POCHTABANK_Menu_menu__1VA0B",
	"item": "POCHTABANK_Menu_item__1vzJq",
	"link": "POCHTABANK_Menu_link__15YlC"
};