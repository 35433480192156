import {ReactComponent} from "*.svg";
import React from "react";
import {Link, useHistory} from "react-router-dom";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { IRootStore } from "models";

import ASV_logoutIcon from "assets/ASV/account_logout.svg";
import ASV_ACCOUNT_MENU from "constants/ASV/accountMenu";
import ASV_styles from "./ASV_AccountMenu.module.css";

import DOMRF_logoutIcon from "assets/DOMRF/account_logout.svg";
import DOMRF_ACCOUNT_MENU from "constants/DOMRF/accountMenu";
import DOMRF_styles from "./DOMRF_AccountMenu.module.css";

import MTS_logoutIcon from "assets/MTS/account_logout.svg";
import MTS_ACCOUNT_MENU from "constants/MTS/accountMenu";
import MTS_styles from "./MTS_AccountMenu.module.css";

import KALASHNIKOVGROUP_logoutIcon from "assets/KALASHNIKOVGROUP/account_logout.svg";
import KALASHNIKOVGROUP_ACCOUNT_MENU from "constants/KALASHNIKOVGROUP/accountMenu";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_AccountMenu.module.css";

import POCHTABANK_logoutIcon from "assets/POCHTABANK/account_logout.svg";
import POCHTABANK_ACCOUNT_MENU from "constants/POCHTABANK/accountMenu";
import POCHTABANK_styles from "./POCHTABANK_AccountMenu.module.css";

import CUSTOMS_logoutIcon from "assets/CUSTOMS/account_logout.svg";
import CUSTOMS_ACCOUNT_MENU from "constants/CUSTOMS/accountMenu";
import CUSTOMS_styles from "./CUSTOMS_AccountMenu.module.css";

import DEFAULT_COMPANY from "constants/company";
import { ReactComponent as GoBackIcon } from "assets/icon_go_back.svg";
import { ReactComponent as Logout } from "assets/account_logout.svg";
import { ReactComponent as Favorites } from "assets/star.svg";

const accountMenuProps: any = {
  ASV: {
    accountMenu: ASV_ACCOUNT_MENU,
    logoutIcon: ASV_logoutIcon,
    styles: ASV_styles
  },
  DOMRF: {
    accountMenu: DOMRF_ACCOUNT_MENU,
    logoutIcon: DOMRF_logoutIcon,
    styles: DOMRF_styles
  },
  MTS: {
    accountMenu: MTS_ACCOUNT_MENU,
    logoutIcon: MTS_logoutIcon,
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    accountMenu: KALASHNIKOVGROUP_ACCOUNT_MENU,
    logoutIcon: KALASHNIKOVGROUP_logoutIcon,
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    accountMenu: POCHTABANK_ACCOUNT_MENU,
    logoutIcon: POCHTABANK_logoutIcon,
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    accountMenu: CUSTOMS_ACCOUNT_MENU,
    logoutIcon: CUSTOMS_logoutIcon,
    styles: CUSTOMS_styles
  }
};

interface AccountMenuItem {
  id: number;
  name: string;
  icon?: string;
  link?: string;
  isActive?: boolean;
}

interface IAccountProps {
  rootStore?: IRootStore;
  logout: any;
}

const AccountMenu: React.FC<IAccountProps> = ({ rootStore, logout }) => {
  const { userStore } = rootStore || { userStore: { company: DEFAULT_COMPANY } };
  const { styles, accountMenu, logoutIcon } = accountMenuProps[
      userStore.company.toUpperCase()
      ];

  const renderIcon = (icon?: string) =>
      icon ? <img className={styles.icon} src={icon} alt="" /> : null;

  const renderMenuItem = ({ icon, name, link, isActive }: AccountMenuItem) => (
      <li key={name} className={cn(styles.item, { [styles.active]: isActive })}>
        {link ? (
            <Link className={styles.link} to={link}>
              <span className={styles.icon}><Favorites className={styles.svg}/></span>
              <span>{name}</span>
            </Link>
        ) : (
            <span className={styles.link}>
            <span className={styles.icon}><Favorites className={styles.svg}/></span>
              <span>{name}</span>
        </span>
        )}
      </li>
  );

  const { listen } = useHistory();
  listen((location, action) => {
    rootStore && rootStore.menuStore.closeMenu();
  });

  const handleToggleMenu = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    e.stopPropagation();
    rootStore && rootStore.menuStore.toggleMenu();
  };

  return (
      <>
      <span className={styles.menuToggler} onClick={handleToggleMenu}>
        <span className={styles.menuTogglerInner} />
      </span>
        <ul
            className={cn(styles.menu, {
              [styles.opened]: rootStore && rootStore.menuStore.open
            })}
        >
          {accountMenu.map(renderMenuItem)}
          <li className={cn(styles.item, styles.homelink)}>
            <Link className={styles.link} to="/">
              <span className={styles.icon}><GoBackIcon className={styles.svg}/></span>
              <span>Вернуться на главную</span>
            </Link>
          </li>
          <li className={cn(styles.item, styles.logout)}>
          <span className={styles.link} onClick={logout}>
            <span className={styles.icon}><Logout className={styles.svg}/></span>
            <span>Выйти из личного кабинета</span>
          </span>
          </li>
        </ul>
      </>
  );
};

export default inject("rootStore")(observer(AccountMenu));
