exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".DOMRF_Footer_footer__HbBd5 {\n    -ms-flex-align: center;\n        align-items: center;\n    background: var(--domrf-dark-gray);\n    display: -ms-flexbox;\n    display: flex;\n    height: 120px;\n    -ms-flex-pack: center;\n        justify-content: center;\n    margin-top: auto;\n}\n\n.DOMRF_Footer_logo__2UIFd {\n    height: 36px;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["tablet"] + " {\n    .DOMRF_Footer_footer__HbBd5 {\n        height: 166px;\n    }\n\n    .DOMRF_Footer_logo__2UIFd {\n        height: 50px;\n    }\n}", ""]);

// Exports
exports.locals = {
	"tablet": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["tablet"] + "",
	"footer": "DOMRF_Footer_footer__HbBd5",
	"logo": "DOMRF_Footer_logo__2UIFd"
};