import { types } from "mobx-state-tree";
import Tag from "./Tag";

const NewsItem = types
  .model("NewsItem", {
    id: types.number,
    source_icon: types.maybeNull(types.string),
    source_name: types.maybeNull(types.string),
    author: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    tags: types.maybeNull(types.array(Tag)),
    title: types.maybeNull(types.string),
    annotation: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    tonality: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    publication_at: types.maybeNull(types.string),
    favorite: types.maybeNull(types.boolean),
    viewed: types.maybeNull(types.boolean),
    source_breadth: types.maybeNull(types.number),
    type: types.maybeNull(
      types.enumeration("NewsType", ["MedialogyNews", "IntegrumNews"])
    )
  })
  .views(self => ({
    get colorizedDescription() {
      const activeTags = self.tags ? self.tags.filter(tag => tag.active) : [];
      let newDescr = self.description || "";
      activeTags.forEach(tag => {
        if (tag.keyword_list.length > 0) {
          const pattern = new RegExp(`(${tag.keyword_list.join("|")})`, "gi");
          newDescr = newDescr.replace(
            pattern,
            `<span class="highlighted ${tag.tonality}">$1</span>`
          );
        }
      });
      return newDescr;
    },
    get breadthCount() {
      const breadth = self.source_breadth || 12356789;
      if (breadth) {
        return breadth < 1000000
          ? "< 1 млн."
          : `${(Math.round(breadth / 100000) / 10)
              .toFixed(1)
              .replace(/\.0$/, "")} млн.`;
      }
      return null;
    }
  }));

export default NewsItem;
