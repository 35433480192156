import { inject } from "mobx-react";
import React, { useState } from "react";
import cn from "classnames";
import DEFAULT_COMPANY from "../../constants/company";
import { IUserStore } from "../../models";
import { ReactComponent as Icon } from "./images/settings_icon.svg";
import SettingsMenu from "./SettingsMenu/SettingsMenu";

import ASV_styles from "./ASV_Settings.module.css";
import DOMRF_styles from "./DOMRF_Settings.module.css";
import MTS_styles from "./MTS_Settings.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Settings.module.css";
import POCHTABANK_styles from "./POCHTABANK_Settings.module.css";
import CUSTOMS_styles from "./CUSTOMS_Settings.module.css";

interface MenuItem {
  title: string;
  callback: any;
  isRemove?: true;
}

interface SettingsProps {
  color?: "green" | "blue";
  inverse?: boolean;
  title?: string;
  userStore?: IUserStore;
}

const MENU: MenuItem[] = [
  {
    title: "Настройки",
    callback: () => {
      console.log("Настройки");
    }
  },
  {
    title: "Подписки",
    callback: () => {
      console.log("Подписки");
    }
  },
  {
    title: "Удалить модуль",
    callback: () => {
      console.log("Удалить модуль");
    },
    isRemove: true
  }
];

const settingsStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

const Settings: React.FC<SettingsProps> = ({
  color = "gray",
  inverse,
  title,
  userStore
}) => {
  const [isOpen, toggleMenu] = useState(false);

  const handleIconClick = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    // e.stopPropagation();
    // toggleMenu(!isOpen);
  };

  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const styles = settingsStyles[company];

  return (
    <div
      className={cn(styles.root, styles[color], { [styles.inverse]: inverse })}
    >
      <span className={styles.icon} onClick={handleIconClick}>
        <Icon />
      </span>
      {title && <div className={styles.title}>{title}</div>}
      {isOpen && <SettingsMenu menu={MENU} isOpen={isOpen} />}
    </div>
  );
};

export default inject("userStore")(Settings);
