import { types } from "mobx-state-tree";
import NewsIntegrum from "./NewsIntegrum";
import NewsTop from "./NewsTop";
import NewsFeedList from "./NewsFeedList";

const MainPageStore = types
  .model({
    newsIntegrum: NewsIntegrum,
    newsTop: NewsTop,
    newsFeeds: NewsFeedList,
  });

export default MainPageStore;
