import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Button } from "components";
import { Input } from "components/FormComponents";
import { IUserStore } from "models";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import DEFAULT_COMPANY from "constants/company";

import ASV_logo from "assets/ASV/logo_color.svg";
import ASV_styles from "./ASV_Login.module.css";
import DOMRF_logo from "assets/DOMRF/DOMRF_logo_color.svg";
import DOMRF_styles from "./DOMRF_Login.module.css";
import MTS_logo from "assets/MTS/MTS_logo_color.png";
import MTS_styles from "./MTS_Login.module.css";
import KALASHNIKOVGROUP_logo from "assets/KALASHNIKOVGROUP/KALASHNIKOVGROUP_Logo.png";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Login.module.css";
import POCHTABANK_logo from "assets/POCHTABANK/POCHTABANK_logo_color.svg";
import POCHTABANK_styles from "./POCHTABANK_Login.module.css";
import CUSTOMS_logo from "assets/CUSTOMS/CUSTOMS_logo.png";
import CUSTOMS_styles from "./CUSTOMS_Login.module.css";

import DEFAULT_logo from "assets/default-logo.svg";

const assets: any = {
  ASV: {
    styles: ASV_styles,
    logo: ASV_logo,
  },
  DOMRF: {
    styles: DOMRF_styles,
    logo: DOMRF_logo,
  },
  MTS: {
    styles: MTS_styles,
    logo: MTS_logo,
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles,
    logo: KALASHNIKOVGROUP_logo,
  },
  POCHTABANK: {
    styles: POCHTABANK_styles,
    logo: POCHTABANK_logo,
  },
  CUSTOMS: {
    styles: CUSTOMS_styles,
    logo: CUSTOMS_logo,
  },
  DEFAULT: {
    styles: DOMRF_styles,
    logo: DEFAULT_logo,
  },
};

interface ILoginProps {
  userStore?: IUserStore;
  logo: string;
}

interface ILoginState {
  email: string;
  password: string;
}

@inject("userStore")
@observer
class Login extends Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  handleChange = (event: any) => {
    const { name, value } = event.target;
    const newState = {
      [name]: value,
    } as Pick<ILoginState, keyof ILoginState>;

    this.setState(newState);
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);
    this.props.userStore && this.props.userStore.login(formData);
  };

  render() {
    const { email, password } = this.state;
    const { errors, state, company } = this.props.userStore || {
      errors: "",
      state: "pending",
      company: DEFAULT_COMPANY,
    };
    const { styles } = assets[company];
    const logo = assets[this.props.logo].logo;
    return (
      <div className={styles.overlay}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <form className={styles.popup} onSubmit={this.handleSubmit}>
            {/*<span className={styles.closePopup} onClick={this.handleClosePopup}>*/}
            {/*  &times;*/}
            {/*</span>*/}
            <h2 className={styles.title}>Войти в личный кабинет</h2>
            <div className={styles.emailRow}>
              <Input
                placeholder="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                wide
              />
            </div>
            <div className={styles.passwordRow}>
              <Input
                type="password"
                placeholder="пароль"
                name="password"
                value={password}
                onChange={this.handleChange}
                wide
              />
            </div>
            <div className={styles.rememberRow}>
              <label className={styles.remember}>
                <input type="checkbox" />
                Запомнить меня
              </label>
            </div>

            {state === "pending" && (
              <div className={styles.loader}>
                <Spinner />
              </div>
            )}
            {state === "done" && errors && (
              <div className={styles.errorMsg}>{errors}</div>
            )}

            <Button type="submit" size="big" className={styles.signInBtn}>
              Войти
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
