export const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "#f9f9f9",
    border: "1px solid #efefef",
    borderRadius: "30px",
    fontSize: "16px",
    height: "54px",
    width: "100%",
    padding: "0 5px"
  }),

  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = "red";
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "#f5f5f5"
          : isFocused
            ? "#f5f5f5"
            : null,

      color: isDisabled
        ? "#ccc"
        : isSelected
          ? "#787f98"
          : isFocused
            ? "#787f98"
            : "#787f98",
      cursor: isDisabled ? "not-allowed" : "default",

      height: "54px",
      padding: "10px",
      lineHeight: "34px",

      ":active": {
        ...styles[":active"],
        color: "#787f98",
        backgroundColor: !isDisabled && (isSelected ? data.color : "#f5f5f5")
      }
    };
  },
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    color: "#787f98"
  }),
  indicatorSeparator: (styles: any, { data }: any) => ({
    ...styles,
    display: "none"
  }),
  dropdownIndicator: (styles: any, { data }: any) => ({
    ...styles,
    color: "#3FAA4B"
  }),
  menu: (styles: any, { data }: any) => ({
    ...styles,
    margin: "-54px 0 0",
    border: "1px solid #efefef",
    borderRadius: "30px",
    overflow: "hidden",
    width: "360px"
  }),
  menuList: (styles: any, { data }: any) => ({ ...styles, padding: "0" })
};