exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".Main_news__38dph {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-direction: column;\n        flex-direction: column;\n    position: relative;\n    z-index: 1;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .Main_news__38dph {\n        -ms-flex-direction: row;\n            flex-direction: row;\n    }\n\n    .Main_actualNews__3rNpw {\n        -ms-flex: 1 1 70%;\n            flex: 1 1 70%;\n        max-width: 70%;\n    }\n\n    .Main_actualNewsMin__oeFfA {\n        -ms-flex: 1 1 100%;\n            flex: 1 1 100%;\n        max-width: 100%;\n    }\n\n    .Main_newsFeed__3VU1N {\n        -ms-flex: 1 1 30%;\n            flex: 1 1 30%;\n        max-height: 515px;\n        overflow: auto;\n        max-width: 30%;\n    }\n\n    .Main_newsFeedMin__3UtTB {\n        -ms-flex: 1 1;\n            flex: 1 1;\n        max-height: 515px;\n        overflow: auto;\n        max-width: 0%;\n    }\n}", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"news": "Main_news__38dph",
	"actualNews": "Main_actualNews__3rNpw",
	"actualNewsMin": "Main_actualNewsMin__oeFfA",
	"newsFeed": "Main_newsFeed__3VU1N",
	"newsFeedMin": "Main_newsFeedMin__3UtTB"
};