exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./images/checkbox_marker.svg"));

// Module
exports.push([module.id, ".DOMRF_TagList_root__-PIsc {\n  border: 1px solid rgba(141,150,201, 0.18);\n  border-width: 1px 0;\n  margin-bottom: 40px;\n  padding: 30px 0 0;\n}\n\n.DOMRF_TagList_tagList__19eTu {\n  display: inline-block;\n  list-style: none;\n  margin: 0 0 15px;\n  padding: 0;\n}\n\n.DOMRF_TagList_tag__R-0No {\n  color: var(--domrf-light-green);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-bottom: 15px;\n  margin-right: 30px;\n  text-decoration: underline;\n  vertical-align: middle;\n}\n\n.DOMRF_TagList_tagCheckbox__2FZzn {\n  border: none;\n  background: none;\n  height: 12px;\n  visibility: hidden;\n  width: 12px;\n}\n\n.DOMRF_TagList_checkboxMarker__Td-6G {\n  border: 1px solid rgba(130,130,130,0.3);\n  border-radius: 50%;\n  display: inline-block;\n  height: 12px;\n  position: relative;\n  vertical-align: middle;\n  width: 12px;\n}\n\n.DOMRF_TagList_checkboxMarker__Td-6G:after {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  display: block;\n  height: 12px;\n  position: absolute;\n  bottom: 2px;\n  left: 2px;\n  width: 12px;\n}\n\n.DOMRF_TagList_tagCheckbox__2FZzn:checked + .DOMRF_TagList_checkboxMarker__Td-6G:after {\n  content: '';\n}\n\n.DOMRF_TagList_label__1Wcjo {\n  color: var(--domrf-light-gray);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-right: 20px;\n  margin-bottom: 15px;\n  vertical-align: middle;\n}\n\n.DOMRF_TagList_gotoSearch__2RYwl {\n  margin-bottom: 30px;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "DOMRF_TagList_root__-PIsc",
	"tagList": "DOMRF_TagList_tagList__19eTu",
	"tag": "DOMRF_TagList_tag__R-0No",
	"tagCheckbox": "DOMRF_TagList_tagCheckbox__2FZzn",
	"checkboxMarker": "DOMRF_TagList_checkboxMarker__Td-6G",
	"label": "DOMRF_TagList_label__1Wcjo",
	"gotoSearch": "DOMRF_TagList_gotoSearch__2RYwl"
};