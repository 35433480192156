import React from "react";
import styled from "styled-components";

export const DividerLine = () => {
  return <Container />;
};

const Container = styled.div`
  opacity: 0.18;
  border: 1px solid #828282;
`;
