import React, { Fragment, PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { INewsFeed, IRootStore, IUserStore, NewsGridStore } from "models";
import { Button, NewsFeed, ViewType } from "components";
import { Link } from "react-router-dom";
import DEFAULT_COMPANY from "../../constants/company";

import ASV_styles from "./ASV_NewsGrid.module.css";
import DOMRF_styles from "./DOMRF_NewsGrid.module.css";
import MTS_styles from "./MTS_NewsGrid.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsGrid.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsGrid.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsGrid.module.css";

interface INewsGreedProps {
  store?: IRootStore;
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles,
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles,
  },
  POCHTABANK: {
    styles: POCHTABANK_styles,
  },
  CUSTOMS: {
    styles: CUSTOMS_styles,
  },
};

@inject("userStore")
@observer
class NewsGrid extends PureComponent<INewsGreedProps> {
  componentDidMount() {
    NewsGridStore.getNewsItems();
  }

  renderFeedBlock = (feed: INewsFeed, index: any) => {
    if (!feed || !feed.news || feed.news.news_list.length === 0) {
      return null;
    }
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const { styles } = assets[company];
    return (
      <Fragment key={feed.id || "1"}>
        <NewsFeed showViewToggler={index === 0} feed={feed} showSettings={true} />
        <div className={styles.controls}>
          <Button className={styles.show_more} size="small">
            <Link
              to={{
                pathname: `/feeds/${feed.id}`
              }}
            >
              Все новости ленты
            </Link>
          </Button>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      NewsGridStore.state === "done" && (
        <div style={{ position: "relative" }}>
          {/*<ViewType />*/}
          {NewsGridStore.feeds.map(this.renderFeedBlock)}
        </div>
      )
    );
  }
}

export default NewsGrid;
