exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MTS_ViewType_viewTypes__2P_dd {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  /*position: absolute;*/\n  /*right: 0;*/\n  /*top: 0;*/\n  /*z-index: 2;*/\n}\n\n.MTS_ViewType_label__8dJbP {\n  display: inline-block;\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 30px;\n  color: var(--mts-dark-gray);\n}\n\n.MTS_ViewType_viewType__2fiHm {\n  color: var(--mts-light-gray);\n  display: inline-block;\n  height: 18px;\n  margin-left: 20px;\n  opacity: 0.45;\n  width: 18px;\n}\n\n.MTS_ViewType_active__h-FQB {\n  color: var(--mts-red);\n  opacity: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"viewTypes": "MTS_ViewType_viewTypes__2P_dd",
	"label": "MTS_ViewType_label__8dJbP",
	"viewType": "MTS_ViewType_viewType__2fiHm",
	"active": "MTS_ViewType_active__h-FQB"
};