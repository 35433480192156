import React from "react";
import cn from "classnames";
import {ITag, IUserStore} from "models";
import {inject, observer} from "mobx-react";
import DEFAULT_COMPANY from "../../constants/company";

import ASV_styles from "./ASV_Tags.module.css";
import DOMRF_styles from "./DOMRF_Tags.module.css";
import MTS_styles from "./MTS_Tags.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Tags.module.css";
import POCHTABANK_styles from "./POCHTABANK_Tags.module.css";
import CUSTOMS_styles from "./CUSTOMS_Tags.module.css";

interface ITagsProps {
  tags?: ITag[];
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

const renderLegend = (styles: any) => (
  <ul className={styles.legend}>
    <li className={styles.positive}>Позитивная тональность</li>
    <li className={styles.negative}>Негативная тональность</li>
    <li className={styles.neutral}>Нейтральная тональность</li>
  </ul>
);

const renderTag = (styles: any) => (tag: ITag) => {
  const tonality = tag.tonality || "neutral";
  return (
    <li key={tag.name} className={cn(styles.hashtag, [tonality], {[styles.active]: tag.active})} onClick={tag.toggleTag}>
      {tag.name}
    </li>
  );
};

const Tags: React.FC<ITagsProps> = ({ tags = [], userStore }) => {
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const { styles } = assets[company];
  return (
  <div className={styles.hashtagsWrapper}>
    <div className={styles.hashtagsSticky}>
      <ul className={styles.hashtags}>{tags.map(renderTag(styles))}</ul>
      {renderLegend(styles)}
    </div>
  </div>
)};

export default inject('userStore')(observer(Tags));
