exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".POCHTABANK_SearchResults_searchResultsHeader__2PZ4L {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.POCHTABANK_SearchResults_resultsTitle__2f7dx {\n  color: var(--pochtabank-dark-blue);\n  font-size: 18px;\n  line-height: 23px;\n  margin-bottom: 25px;\n  margin-left: 20px;\n}\n\n.POCHTABANK_SearchResults_viewTypesToggler__1x50X {\n  height: 30px;\n  position: relative;\n  margin-left: auto;\n  margin-bottom: 25px;\n}\n\n.POCHTABANK_SearchResults_total__15rYO {\n  color: var(--pochtabank-light-green);\n  font-weight: 600;\n}", ""]);

// Exports
exports.locals = {
	"searchResultsHeader": "POCHTABANK_SearchResults_searchResultsHeader__2PZ4L",
	"resultsTitle": "POCHTABANK_SearchResults_resultsTitle__2f7dx",
	"viewTypesToggler": "POCHTABANK_SearchResults_viewTypesToggler__1x50X",
	"total": "POCHTABANK_SearchResults_total__15rYO"
};