import * as React from "react";
import {
  Checkbox,
  CheckboxProps,
  lighten,
  withStyles
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export const CustomCheckbox = withStyles({
  root: {
    width: "16px",
    height: "16px",
    marginRight: "10px",
    color: lighten("#828282", 0.45),
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
