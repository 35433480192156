import { inject } from "mobx-react";
import React from "react";
import { INewsFeed, IUserStore } from "models";
import { NewsFeed, ViewType } from "components";
import { wordEnding } from "helpers/textFormatters";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_SearchResults.module.css";
import DOMRF_styles from "./DOMRF_SearchResults.module.css";
import MTS_styles from "./MTS_SearchResults.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_SearchResults.module.css";
import POCHTABANK_styles from "./POCHTABANK_SearchResults.module.css";
import CUSTOMS_styles from "./CUSTOMS_SearchResults.module.css";

interface ISearchResultsProps {
  results: INewsFeed;
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

const SearchResults: React.FC<ISearchResultsProps> = ({
  results,
  userStore
}) => {
  const total = results.news && results.news.meta.total;
  const foundEnding = wordEnding("найдена", "найдено", "найдено");
  const newsEnding = wordEnding("новость", "новости", "новостей");

  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const { styles } = assets[company];

  if (total === null) {
    return null;
  }

  return total > 0 ? (
    <div>
      <div className={styles.searchResultsHeader}>
        <div className={styles.resultsTitle}>
          По запросу {foundEnding(total)}{" "}
          <span className={styles.total}>{total}</span>
          &nbsp;{newsEnding(total)}:
        </div>
        <div className={styles.viewTypesToggler}>
          <ViewType />
        </div>
      </div>
      <NewsFeed showViewToggler={false} feed={results} target="_blank" />
    </div>
  ) : (
    <div>
      <div className={styles.resultsTitle}>По запросу ничего не найдено</div>
    </div>
  );
};

export default inject("userStore")(SearchResults);
