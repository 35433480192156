exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".CUSTOMS_NewsGrid_controls__1mnyV {\n    text-align: center;\n}\n\n.CUSTOMS_NewsGrid_show_more__1imoE {\n    display: inline-block;\n    margin: 0 auto 30px;\n}\n\n.CUSTOMS_NewsGrid_show_more__1imoE a {\n    color: #fff;\n}", ""]);

// Exports
exports.locals = {
	"tablet": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"large-desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["large-desktop"] + "",
	"controls": "CUSTOMS_NewsGrid_controls__1mnyV",
	"show_more": "CUSTOMS_NewsGrid_show_more__1imoE"
};