import { cast, types, flow } from "mobx-state-tree";
// import { getNewsFeeds } from "mocks/news_feeds";
// import MENU from "constants/menu";
import { getNewsFeeds } from "api/news";
import NewsFeed from "./NewsFeed";
// import { INewsFeed } from "./index";

const NewsFeedList = types
  .model("NewsFeedList", {
    feeds: types.array(NewsFeed),
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const getNewsItems = flow(function* getNewsItems() {
      self.feeds = cast([]);
      self.state = "pending";
      try {
        // @ts-ignore
        self.feeds = yield cast(getNewsFeeds());
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    return { getNewsItems };
  })
  // .views(self => ({
  //   get orderedFeeds() {
  //     const getFeedById = (id: number) =>
  //       self.feeds.find(feed => feed.id === id);
  //     return MENU.map(item => item.id).reduce(
  //       (feeds: INewsFeed[], id: number) => {
  //         let feed = getFeedById(id);
  //         return feed ? [...feeds, feed] : feeds;
  //       },
  //       []
  //     );
  //   }
  // }));
export default NewsFeedList;
