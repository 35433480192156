import React, { FunctionComponent } from "react";
import {inject} from "mobx-react";
import {IUserStore} from "models";
import ASV_Main from "./ASV_Main";
import DOMRF_Main from "./DOMRF_Main";
import MTS_Main from "./MTS_Main";
import KALASHNIKOVGROUP_Main from "./KALASHNIKOVGROUP_Main";
import POCHTABANK_Main from "./POCHTABANK_Main";
import CUSTOMS_Main from "./CUSTOMS_Main";

interface IMainProps {
  userStore?: IUserStore;
}

const mainPage: any = {
  ASV: <ASV_Main />,
  DOMRF: <DOMRF_Main />,
  MTS: <MTS_Main />,
  KALASHNIKOVGROUP: <KALASHNIKOVGROUP_Main />,
  POCHTABANK: <POCHTABANK_Main />,
  CUSTOMS: <CUSTOMS_Main />,
};

const Main: FunctionComponent<IMainProps> = ({userStore}) => {
  const company =  userStore && userStore.company || 'ASV';
  return (mainPage[company]);
};

export default inject('userStore')(Main);
