import React, { ChangeEvent, FC, useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { CrossIcon } from "../ui/CrossIcon";
import { DividerLine } from "../ui/DividerLine";
import { CustomInput } from "../ui/Input";
import { Controller, DeepMap, FieldError } from "react-hook-form";
import skrep from "../../assets/skrep.png";
import cross from "../../assets/crossRed.svg";
import { CustomCheckbox } from "../ui/CustomCheckBox";
import { CustomSwitch } from "../ui/CustomSwitch";
import { ArrayField } from "react-hook-form/dist/types";
import { FormControlLabel, OutlinedInput } from "@material-ui/core";
import { gosmi_Order } from "../../pages/CreateOrder/CreateOrder";

interface Props {
  fields: Partial<ArrayField<Record<string, any>, string>>[];
  titles: Partial<ArrayField<Record<string, any>, string>>[];
  append: any;
  appendTitles: any;
  register: any;
  control: any;
  setValue: (name: string, value: any) => void;
  getFieldValue: any;
  rewrite_type: boolean;
  setRewrite_type: (val: boolean) => void;
  files: File[];
  setFiles: (data: File[]) => void;
  errors: DeepMap<gosmi_Order, FieldError>;
}

export const OrderForm: FC<Props> = ({
  fields,
  register,
  append,
  control,
  setRewrite_type,
  rewrite_type,
  getFieldValue,
  files,
  setFiles,
  titles,
  appendTitles,
  errors,
}) => {
  const fileUploader = useRef<HTMLInputElement | null>(null);

  const removeFile = (index: number) => {
    const arr = [...files];
    arr.splice(index, 1);
    setFiles(arr);
  };

  const pickedFilesNames = files.map((item, index) => (
    <FileNameBlock key={item.name}>
      <FileName>
        {item.name.length > 8
          ? item.name.slice(0, 4) +
            "..." +
            item.name.slice(item.name.length - 5)
          : item.name.length}
      </FileName>
      <img
        src={cross}
        style={{ cursor: "pointer" }}
        alt={"Удалить"}
        onClick={() => removeFile(index)}
      />
    </FileNameBlock>
  ));


  const onUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files.length > 3) {
        alert("Максимум 3 файла");
      } else {
        for (let item of e.target.files) {
          if (item.size > 10485760) {
            window.alert("Не удалось загрузить файл, максимальный размер 10мб");
          } else {
            setFiles([...files, item]);
          }
        }
      }
    }
  };

  return (
    <Container>
      <TitleContainer>
        <Title>PR-ВОЛНА</Title>
      </TitleContainer>
      <PRBlock>
        <TitleBlock>
          <InfoText>ОРИГИНАЛ НОВОСТИ (ПЕРВОИСТОЧНИК) </InfoText>
          <CustomInput
            style={{marginBottom: "20px"}}
            name={"original_link"}
            ref={register}
            error={
              errors.original_link && errors.original_link.type === "required"
            }
            placeholder={
              errors.original_link && errors.original_link.type === "required"
                ? "Обязательное поле, введите ссылку на первоисточник"
                : "Ссылка на первоисточник"
            }
          />
        </TitleBlock>
        {/*{&& <span>This is required</span>}*/}
        <TitleBlock>
          <InfoText>КЛЮЧЕВЫЕ СЛОВА ДЛЯ ЗАГОЛОВКА </InfoText>
          <CustomInput
            name={"keywords"}
            ref={register}
            error={errors.keywords && errors.keywords.type === "required"}
            placeholder={
              errors.keywords && errors.keywords.type === "required"
                ? "Обязательное поле, введите ключевые слова"
                : "Пример: “Заголовок отражающий смысл“ или указать нужные слова (формирует сюжет в Яндекс.Новости)"
            }
          />
        </TitleBlock>
      </PRBlock>
      <DividerLine />
      <MoreMaterials>
        <Title>Дополнительные материалы</Title>
        <div>
          <InfoText>Дополнительные ссылки</InfoText>
          <LinkList>
            {fields.map((item, index) => {
              return (
                <li key={item.extra_links}>
                  <CustomInput
                    name={`extra_links[${index}].value`}
                    defaultValue={`${item.value}`} // make sure to set up defaultValue
                    ref={register}
                    placeholder={"Дополнительная ссылка"}
                  />
                </li>
              );
            })}
          </LinkList>
          <LinkButtonContainer>
            <Plus>+ </Plus>
            <LinkButton onClick={() => append({ value: "" })}>
              Добавить ссылку
            </LinkButton>
          </LinkButtonContainer>
        </div>

        <div>
          <InfoText>Варианты заголовка</InfoText>
          <LinkList>
            {titles.map((item, index) => {
              return (
                <li key={item.extra_titles}>
                  <CustomInput
                    name={`extra_titles[${index}].value`}
                    defaultValue={`${item.value}`} // make sure to set up defaultValue
                    ref={register}
                    placeholder={"Дополнительный заголовок"}
                  />
                </li>
              );
            })}
          </LinkList>
          <LinkButtonContainer>
            <Plus>+ </Plus>
            <LinkButton onClick={() => appendTitles({ value: "" })}>
              Добавить заголовок
            </LinkButton>
          </LinkButtonContainer>
        </div>

        <InfoText>Комментарий</InfoText>
        <CustomInput
          name={"comment"}
          ref={register}
          placeholder={"Комментарий"}
        />
        <UploadBlock>
          <UploadButton
            onClick={() =>
              !!fileUploader.current && fileUploader.current.click()
            }
          >
            <input
              multiple
              accept={
                ".zip,.png,.jpg,.pdf,.jpeg.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              }
              type={"file"}
              ref={fileUploader}
              onChange={onUploadChange}
              style={{ display: "none" }}
            />
            <UploadImage src={skrep} alt={"Прикрепить"} />
            <UploadLabel>Прикрепить файл</UploadLabel>
          </UploadButton>
          {pickedFilesNames}
        </UploadBlock>
        <SimpleText>Допустимые форматы: jpeg, png, doc, zip, pdf</SimpleText>
      </MoreMaterials>
      <DividerLine />
      <RewriteBlock>
        <Title>Тип рерайта</Title>
        <CheckBoxContainer>
          <FirstSection>
            <CheckBoxBlock>
              <Controller
                control={control}
                as={
                  <CustomCheckbox
                    defaultValue={"1"}
                    checked={!rewrite_type}
                    onClick={() => setRewrite_type(false)}
                  />
                }
                name="rewrite_type"
              />
              <SimpleText>Легкий (до 20%)</SimpleText>
            </CheckBoxBlock>
            <CheckBoxBlock>
              <Controller
                control={control}
                as={
                  <CustomCheckbox
                    defaultValue={"1"}
                    checked={rewrite_type}
                    onClick={() => setRewrite_type(true)}
                  />
                }
                name="Checkbox"
              />
              <SimpleText>Средний (до 50%)</SimpleText>
            </CheckBoxBlock>
          </FirstSection>
          <SecondSection>
            <CheckBoxBlock>
              <FormControlLabel
                control={
                  <Controller
                    name="full_reprint"
                    control={control}
                    render={(props) => (
                      <CustomCheckbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={"Возможна полная перепечатка"}
              />
            </CheckBoxBlock>
          </SecondSection>
          <SecondSection>
            <SwitchBoxBlock>
              <FormControlLabel
                control={
                  <Controller
                    name="need_source_link"
                    control={control}
                    render={(props) => (
                      <CustomSwitch
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={"Ссылка на первоисточник"}
              />
            </SwitchBoxBlock>
          </SecondSection>
          <SecondSection last>
            <SwitchBoxBlock>
              <FormControlLabel
                control={
                  <Controller
                    name="need_advert"
                    control={control}
                    render={(props) => (
                      <CustomSwitch
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={"Подпись “На правах рекламы”"}
              />
            </SwitchBoxBlock>
          </SecondSection>
        </CheckBoxContainer>
      </RewriteBlock>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 45px;
  font-family: Stem, serif;
  border: 1px solid rgba(130, 130, 130, 0.18);
  box-sizing: border-box;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
  border-radius: 24px;
  background: #ffffff;

  @media screen and (max-width: 767px) {
    padding: 15px;
  }

  @media screen and (max-width: 1023px) {
    border: initial;
    border-top: 1px solid rgba(130, 130, 130, 0.18);
    border-bottom: 1px solid rgba(130, 130, 130, 0.18);
    border-radius: 0px;
    box-shadow: initial;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    justify-content: center;
    & > :nth-child(2) {
      display: none;
    }
  }
`;

const Title = styled.h4`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  margin-right: auto;
  /* or 167% */

  /* Grey */

  color: #4f4f4f;
`;

const PRBlock = styled.div`
  margin-top: 30px;
  margin-bottom: 35px;

  & > :first-child {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 30px;

    & > :first-child {
      margin-bottom: 12px;
    }
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const MoreMaterials = styled.div`
  margin: 30px 0px;

  & > :first-child {
    margin-bottom: 30px;
  }

  & > :nth-child(n) {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 991px) {
    text-align: center;
    & > :nth-child(n) {
      margin-bottom: 12px;
    }
  }
`;

const LinkButton = styled.span`
  cursor: pointer;
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #67b580;
`;

const LinkButtonContainer = styled.div`
  margin-top: 10px;
  margin-left: 30px;
`;

const Plus = styled(LinkButton)`
  margin-left: 0;
  text-decoration-line: none;
`;

const UploadBlock = styled.div`
  display: flex;

  @media screen and (max-width: 991px) {
    flex-flow: column;
    align-items: center;
    & > :nth-child(n + 2) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
`;
const UploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  line-height: 15px;
  width: 248px;
  height: 54px;
  border: 1px solid #67b580;
  box-sizing: border-box;
  border-radius: 30px;
  background: #ffffff;
  color: #67b580;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 991px) {
    font-weight: 500;
    font-size: 13px;
    width: 100%;
    max-width: 290px;
    height: 36px;
  }
`;

const UploadImage = styled.img``;

const UploadLabel = styled.span`
  font-weight: 500;
  font-size: 18px;
  margin-left: 9px;
`;

const FileNameBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const FileName = styled.span`
  cursor: default;
  font-size: 18px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #67b580;
  margin-right: 16px;

  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
`;

const SimpleText = styled.span`
  white-space: nowrap;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #4f4f4f;
`;

const InfoText = styled.span`
  margin-left: 32px;
  white-space: nowrap;
  font-weight: normal;
  font-size: 16px;
  //line-height: 15px;
  color: #4f4f4f;
`;

const RewriteBlock = styled.div`
  margin-top: 28px;
  display: flex;
  flex-flow: column;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
`;

const CheckBoxContainer = styled.div`
  margin-top: 30px;

  display: flex;
  grid-template-columns: repeat(4, auto);

  @media screen and (max-width: 1419px) {
    grid-template-columns: repeat(2, min-content);
    grid-row-gap: 20px;
    justify-content: center;

    & > :nth-child(2) {
      border-right: none;
    }

    & > :nth-child(3) {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: min-content;

    & > :nth-child(n) {
      padding: 0;
      border: none;
    }
  }
`;
const CheckBoxBlock = styled.div`
  display: flex;
  align-items: center;
  & > label span.MuiTypography-body1 {
    font-family: "Stem", serif;
    white-space: nowrap;
    font-size: 13px;
    line-height: 15px;
  }
`;
const SwitchBoxBlock = styled.div`
  display: flex;
  align-items: center;

  & > label span.MuiTypography-body1 {
    font-family: "Stem", serif;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 13px;
    line-height: 15px;
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  border-right: 1px solid #828282;

  @media screen and (min-width: 768px) {
    & > :first-child {
      padding-right: 50px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-flow: column;
    & > :first-child {
      padding-bottom: 20px;
    }
  }
`;

const SecondSection = styled.div<{ last?: boolean }>`
  display: flex;
  height: 22px;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 36px;
  border-right: ${(props) => (props.last ? "none" : "1px solid #828282")};
`;

const LinkList = styled.ul`
  & > :nth-child(n + 2) {
    margin-top: 10px;
  }
`;
