let TOP_MENU = [
  {
    link: "/",
    name: 'Лента новостей',
  },
  // {
  //   link: "/",
  //   name: 'Поиск по СМИ',
  // },
  // {
  //   link: "/",
  //   name: 'Поиск по соц медиа',
  // },
  {
    link: "/interaction",
    name: 'Взаимодействие со СМИ',
  },
];

export default TOP_MENU;
