import React from "react";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Input, Select } from "@material-ui/core";

const useStyles = makeStyles({
  root: {}, // a style rule
  label: {}, // a nested style rule
});

export const CustomInput = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 60px;
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 15px;
  padding: 20px 30px;
  background: rgba(130, 130, 130, 0.1);
  color: #4F4F4F;
  border: 1px solid rgba(79, 79, 79, 0.1);
  border-color: ${(props) =>
    props.error ? "#FF5F5B" : "rgba(79, 79, 79, 0.1)"};
  box-sizing: border-box;
  border-radius: 30px;
  margin-top: 20px;

  &::-webkit-input-placeholder {
    color: ${(props) => (props.error ? "#FF5F5B" : "rgba(79, 79, 79, 0.45)")};
    opacity: 1;
  }
  
  &:focus {
    outline: none;
  }

  @media screen and (max-width: 991px) {
    height: 36px;
    font-size: 13px;
    line-height: 15px;
  }
`;

export const CssTextField = withStyles({
  root: {
    "&.MuiInput-underline:before": {
      border: "none",
    },
    "&.MuiInput-underline:after": {
      border: "none",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
  },
})(Input);

export const CssTextFieldForTotalBlock = withStyles({
  root: {
    color: "#ffffff",
    height: 54,
    border: "1px solid rgba(255,255,255,0.36)",
    borderRadius: 50,
    "&.MuiInput-underline:before": {
      border: "none",
    },
    "&.MuiInput-underline:after": {
      border: "none",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },

    "&.MuiSelect-select.MuiSelect-select": {
      paddingLeft: "28.25px",
    },

    "&> .MuiSvgIcon-root": {
      right: 10,
      color: "#FFFFFF",
    },

    ["@media screen and (max-width:768px)"]: {
      height: 36,
    },
  },
})(Input);

export const SelectForTotalBlock = withStyles({
  root: {
    paddingLeft: "28.25px",
    "&.MuiSelect-select:focus": {
      backgroundColor: "initial",
    },
    ["@media screen and (max-width:768px)"]: {
      paddingLeft: "10px",
    },
  },
})(Select);
