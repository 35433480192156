import { inject } from "mobx-react";
import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { formatDate } from "helpers/formatDate";
import { INewsItem, IRootStore } from "models";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_NewsGridItem.module.css";
import DOMRF_styles from "./DOMRF_NewsGridItem.module.css";
import MTS_styles from "./MTS_NewsGridItem.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsGridItem.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsGridItem.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsGridItem.module.css";
import neutral from "../../assets/neutral.png";
import positive from "../../assets/positive.png";
import negative from "../../assets/negative.png";

type Target = "_self" | "_blank";
type Props = INewsItem & {
  target?: Target;
} & {
  rootStore?: IRootStore;
};

const allStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

const NewsGridItem: React.FC<Props> = props => {
  const {
    id,
    source_icon,
    source_name,
    // tags,
    image,
    title,
    annotation,
    publication_at,
    viewed,
    source_breadth,
    tonality
  }: INewsItem = props;

  const renderTonality =() => {
    switch(tonality) {
      case 'neutral': return <img src={neutral} alt="" width="20px"/>;
      case 'positive': return <img src={positive} alt="" width="20px"/>;
      case 'negative': return <img src={negative} alt="" width="20px"/>;
      default: return null;
    }
  };

  const breadthCount = (breadth: number) => {
    if (breadth) {
      return breadth < 1000000
        ? "< 1 млн."
        : `${(Math.round(breadth / 100000) / 10)
          .toFixed(1)
          .replace(/\.0$/, "")} млн.`;
    }
    return null;
  };
  const company =
    (props.rootStore &&
      props.rootStore.userStore &&
      props.rootStore.userStore.company.toUpperCase()) ||
    DEFAULT_COMPANY;
  const view =
    (props.rootStore &&
      props.rootStore.viewType &&
      props.rootStore.viewType.view) ||
    "magazine";
  const styles = allStyles[company];
  return (
    <Link
      target={props.target || "_self"}
      to={{
        pathname: `/news/${id}`
      }}
      className={cn(styles.news, {
        [styles.read]: viewed,
        [styles.high]: image,
        [styles.magazine]: view === "magazine"
      })}
    >
      {image && view === "magazine" && (
        <div className={styles.image}>
          <img src={image} alt="" />
        </div>
      )}
      <div className={styles.src}>
        {source_icon && (
          <span className={styles.logo}>
            <img src={source_icon} alt="" />
          </span>
        )}
        {source_name && <span className={styles.name}>{source_name}</span>}
        {publication_at && (
          <span className={styles.date}>{formatDate(publication_at)}</span>
        )}
        {source_breadth && source_breadth > 0 && (
          <span className={styles.read_sign}>{breadthCount(source_breadth)}</span>
        )}
      </div>
      <div className={styles.newsBody}>
        {tonality && <div className={styles.newsTonality}>{renderTonality()}</div>}
        {title && <div className={styles.newsTitle}>{title}</div>}
        {annotation && (
          <div
            className={styles.newsText}
            dangerouslySetInnerHTML={{
              __html: annotation
            }}
          />
        )}
      </div>
      {/*{tags && <div className={styles.hashtags}>{tags.join(" ")}</div>}*/}
    </Link>
  );
};

export default inject("rootStore")(NewsGridItem);
