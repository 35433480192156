import React from "react";
import { inject, observer } from "mobx-react";
import cn from "classnames";
import { IRootStore, ViewTypeStore } from "models";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_ViewType.module.css";
import DOMRF_styles from "./DOMRF_ViewType.module.css";
import MTS_styles from "./MTS_ViewType.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_ViewType.module.css";
import POCHTABANK_styles from "./POCHTABANK_ViewType.module.css";
import CUSTOMS_styles from "./CUSTOMS_ViewType.module.css";

import { ReactComponent as IconGrid } from "assets/icon_grid.svg";
import { ReactComponent as IconMagazine } from "assets/icon_magazine.svg";
import { ReactComponent as IconList } from "assets/icon_list.svg";

const VIEW_TYPES = ["magazine", "grid", "list"];
const icons: any = {
  magazine: <IconMagazine />,
  grid: <IconGrid />,
  list: <IconList />
};

const assets: any = {
  styles: {
    ASV: ASV_styles,
    DOMRF: DOMRF_styles,
    MTS: MTS_styles,
    KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
    POCHTABANK: POCHTABANK_styles,
    CUSTOMS: CUSTOMS_styles,
  }
};

interface IViewTypeProps {
  rootStore?: IRootStore;
}

const ViewType: React.FC<IViewTypeProps> = ({ rootStore }) => {
  const company = rootStore && rootStore.userStore && rootStore.userStore.company || DEFAULT_COMPANY;
  const styles = assets.styles[company];
  return (
    <ul className={styles.viewTypes}>
      <li className={styles.label}>Сетка: </li>
      {VIEW_TYPES.map((type: any) => (
        <li
          className={cn(styles.viewType, {
            [styles.active]: rootStore && rootStore.viewType && rootStore.viewType.view === type
          })}
          onClick={() => rootStore && rootStore.viewType && rootStore && rootStore.viewType.changeView(type)}
        >
          {icons[type]}
        </li>
      ))}
    </ul>
  );
};

export default inject("rootStore")(observer(ViewType));
