import React from "react";
import TOP_MENU from "constants/DOMRF/top_menu";
import styles from "./KALASHNIKOVGROUP_Menu.module.css";

interface MenuItem {
    name: string;
    link: string;
}

const renderMenuItem = ({ link, name }: MenuItem) => (
    <li key={name} className={styles.item}>
        <a className={styles.link} href={link}>
            {name}
        </a>
    </li>
);

export const KALASHNIKOVGROUP_Menu: React.FC = () => {
    const gosmi = localStorage.getItem("gosmi") || "";
    const menuItems =  gosmi.length > 2
        ? TOP_MENU
        : TOP_MENU.filter(item => item.link !== "/interaction");

    return <ul className={styles.menu}>{menuItems.map(renderMenuItem)}</ul>;
};