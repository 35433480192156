exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ASV_Settings_root__3J7P9 {\n    --fill-color: #ffffff;\n    --title-color: var(--asv-gray);\n}\n.ASV_Settings_green__E4r8O {\n    --bg-color: var(--asv-green);\n}\n.ASV_Settings_blue__3ui6M {\n    --bg-color: var(--asv-blue);\n}\n\n.ASV_Settings_inverse__TIiTN {\n    --bg-color: #ffffff;\n    --title-color: #ffffff;\n}\n.ASV_Settings_inverse__TIiTN.ASV_Settings_green__E4r8O {\n    --fill-color: var(--asv-green);\n}\n.ASV_Settings_inverse__TIiTN.ASV_Settings_blue__3ui6M {\n    --fill-color: var(--asv-blue);\n}\n\n.ASV_Settings_root__3J7P9 {\n    -ms-flex-align: center;\n        align-items: center;\n    display: -ms-flexbox;\n    display: flex;\n    position: relative;\n}\n\n.ASV_Settings_icon__rmoS7 {\n    -ms-flex-align: center;\n        align-items: center;\n    background: var(--bg-color);\n    border: 1px solid var(--bg-color);\n    border-radius: 50%;\n    /*cursor: pointer;*/\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    fill: var(--fill-color);\n    height: 36px;\n    -ms-flex-pack: center;\n        justify-content: center;\n    margin-right: 15px;\n    -webkit-transition: all 0.3s ease-out;\n    transition: all 0.3s ease-out;\n    vertical-align: middle;\n    width: 36px;\n}\n\n/*.icon:hover,*/\n/*.icon.active {*/\n/*    background: var(--fill-color);*/\n/*    fill: var(--bg-color);*/\n/*}*/\n\n.ASV_Settings_title__226H8 {\n    color: var(--asv-title-color);\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 30px;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "ASV_Settings_root__3J7P9",
	"green": "ASV_Settings_green__E4r8O",
	"blue": "ASV_Settings_blue__3ui6M",
	"inverse": "ASV_Settings_inverse__TIiTN",
	"icon": "ASV_Settings_icon__rmoS7",
	"title": "ASV_Settings_title__226H8"
};