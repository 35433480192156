import { inject } from "mobx-react";
import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { formatDate } from "helpers/formatDate";
import { INewsItem, IUserStore } from "models";
import { addToFavorites, removeFromFavorites } from "api/news";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_NewsListItem.module.css";
import DOMRF_styles from "./DOMRF_NewsListItem.module.css";
import MTS_styles from "./MTS_NewsListItem.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsListItem.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsListItem.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsListItem.module.css";

type Props = INewsItem & { userStore?: IUserStore };

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

const NewsListItem: React.FC<Props> = ({
  id,
  source_icon,
  source_name,
  // tags,
  // image,
  title,
  annotation,
  publication_at,
  type,
  userStore
}) => {
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const { styles } = assets[company];
  return (
    <div className={cn(styles.news, { [styles.read]: false })}>
      <Link to={`/${type === "IntegrumNews" ? "i_news" : "news"}/${id}`}>
        <div className={styles.src}>
          {source_icon && (
            <span className={styles.logo}>
              <img src={source_icon} alt="" />
            </span>
          )}
          {source_name && <span className={styles.name}>{source_name}</span>}
          {publication_at && (
            <span className={styles.date}>{formatDate(publication_at)}</span>
          )}
          {/*<span className={styles.read_sign}>Прочитано</span>*/}
        </div>
        <div className={styles.newsBody}>
          {title && <div className={styles.newsTitle}>{title}</div>}
          {annotation && (
            <div
              className={styles.newsText}
              dangerouslySetInnerHTML={{
                __html: annotation
              }}
            />
          )}
        </div>
      </Link>
      {/*<span*/}
      {/*  onClick={() => {*/}
      {/*    removeFromFavorites(id);*/}
      {/*  }}*/}
      {/*  className={cn(styles.favoriteLink, styles.favorite)}*/}
      {/*/>*/}
    </div>
  );
};

export default inject("userStore")(NewsListItem);
