import { inject } from "mobx-react";
import React from "react";
import { IUserStore } from "models";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_Navigation from "./ASV_Navigation";
import DOMRF_Navigation from "./DOMRF_Navigation";
import MTS_Navigation from "./MTS_Navigation";
import KALASHNIKOVGROUP_Navigation from "./KALASHNIKOVGROUP_Navigation";
import POCHTABANK_Navigation from "./POCHTABANK_Navigation";
import CUSTOMS_Navigation from "./CUSTOMS_Navigation";

interface INavigationProps {
  userStore?: IUserStore;
}

const components: any = {
  ASV: ASV_Navigation,
  DOMRF: DOMRF_Navigation,
  MTS: MTS_Navigation,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_Navigation,
  POCHTABANK: POCHTABANK_Navigation,
  CUSTOMS: CUSTOMS_Navigation,
};

const Navigation: React.FC<INavigationProps> = ({ userStore }) => {
  const company = (userStore && userStore.company.toUpperCase()) || DEFAULT_COMPANY;
  const userInfo = userStore && userStore.userInfo || {};
  return components[company](userInfo);
};

export default inject("userStore")(Navigation);
