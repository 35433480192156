import { types } from "mobx-state-tree";
import NewsItem from "./NewsItem";

const NewsFeed = types
  .model("NewsFeed", {
    id: types.maybeNull(types.number),
    feed_name: types.maybeNull(types.string),
    news: types.maybeNull(types.model({
      news_list: types.array(NewsItem),
      meta: types.model({
        page: types.maybeNull(types.number),
        per_page: types.maybeNull(types.number),
        total: types.maybeNull(types.number),
        total_pages:types.maybeNull(types.number),
      })
    })),
  });
export default NewsFeed;
