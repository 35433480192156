import cn from "classnames";
import { inject } from "mobx-react";
import React, { ReactNode } from "react";
import { IUserStore } from "models";
import DEFAULT_COMPANY from "../../constants/company";

import ASV_styles from "./ASV_Button.module.css";
import DOMRF_styles from "./DOMRF_Button.module.css";
import MTS_styles from "./MTS_Button.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Button.module.css";
import POCHTABANK_styles from "./POCHTABANK_Button.module.css";
import CUSTOMS_styles from "./CUSTOMS_Button.module.css";

const buttonStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

type ButtonProps = {
  children?: ReactNode;
  className?: string;
  size: "small" | "big";
  link?: string;
  iconBefore?: string;
  iconAfter?: string;
  type?: "button" | "submit";
  mobile?: boolean;
  onClick?: any;
  userStore?: IUserStore;
};

const renderIcon = (src: string, styles: any) => (
  <span className={styles.icon}>
    <img src={src} />
  </span>
);

const Button: React.FC<ButtonProps> = ({
  children,
  iconBefore,
  iconAfter,
  link,
  size = "small",
  className,
  type,
  mobile = false,
  onClick,
  userStore
}) => {
  const Tag = link ? "a" : "button";
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const styles = buttonStyles[company.toUpperCase()];
  return (
    <Tag
      href={link}
      type={type}
      onClick={onClick}
      className={cn(
        styles.root,
        styles[size],
        { [styles.mobile]: mobile },
        className
      )}
    >
      {iconBefore && renderIcon(iconBefore, styles)}
      <span className={styles.text}>{children}</span>
      {iconAfter && renderIcon(iconAfter, styles)}
    </Tag>
  );
};

export default inject("userStore")(Button);
