import { inject } from "mobx-react";
import React, { useState } from "react";
import cn from "classnames";
import DEFAULT_COMPANY from "../../../constants/company";
import { IUserStore } from "../../../models";

import ASV_styles from "./ASV_Input.module.css";
import DOMRF_styles from "./DOMRF_Input.module.css";
import MTS_styles from "./MTS_Input.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Input.module.css";
import POCHTABANK_styles from "./POCHTABANK_Input.module.css";
import CUSTOMS_styles from "./CUSTOMS_Input.module.css";

const inputStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

interface IInputProps {
  className?: string;
  name: string;
  placeholder?: string;
  type?: string;
  wide?: boolean;
  value: string;
  onChange?: any;
  userStore?: IUserStore;
}

const Input: React.FC<IInputProps> = ({
  wide,
  type,
  className,
  userStore,
  ...rest
}) => {
  const [showPassword, toggleShowPassword] = useState(false);
  const inputType = type === "password" && showPassword ? "text" : type;
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const styles = inputStyles[company.toUpperCase()];
  return (
    <div className={cn(styles.root, { [styles.fullWidth]: wide }, className)}>
      <input className={styles.input} type={inputType} {...rest} />
      {type === "password" && (
        <span
          className={cn(styles.showPasswordToggler, {
            [styles.shown]: showPassword
          })}
          onClick={() => toggleShowPassword(!showPassword)}
        />
      )}
    </div>
  );
};

export default inject("userStore")(Input);
