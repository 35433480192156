import React, {FC} from "react";
import styled from "styled-components";
import eyeImage from "../../assets/eyeImage.png";
import google from "../../assets/google.png";
import topMedia from "../../assets/topMedia.png";
import yandex from "../../assets/yandex.png";
import checkedImg from "../../assets/checked.png";
import unCheckedImg from "../../assets/unChecked.png";

interface IProps {
  checked: boolean;
  setChecked: (val: number) => void;
  smi_id: number;
  name: string;
  web_link: string;
  smi_type_id: number;
  count_made: number;
  traffic: number;
  mpe_index: number | null;
  price: number;
  region_id: number | null;
  yandex_news: number;
  google_title: number;
  in_medialogy: number;
  top_media: number;
  cost_sum: number;
}

export const SMiListItem: FC<IProps> = ({
  checked,
  setChecked,
  smi_id,
  name,
  traffic,
  count_made,
  web_link,
  mpe_index,
  price,
  google_title,
  top_media,
  yandex_news,
  cost_sum,
}) => {
  return (
    <Container>
      <td>
        <NameBlock>
          <SmiName>{name}</SmiName>
          <EyeBlock>
            <EyeImage src={eyeImage} />
            <ViewLabel>{traffic} ЧЕЛ/МЕС.</ViewLabel>
          </EyeBlock>
          <LinkToSmi href={web_link}>{web_link}</LinkToSmi>
        </NameBlock>
      </td>
      <td>
        <GreenNumber>{count_made}</GreenNumber>
      </td>

      {/*<td>*/}
      {/*  <GreenNumber>{!!mpe_index ? mpe_index : `N/A`}</GreenNumber>*/}
      {/*</td>*/}
      <td>
        <GreenNumber bold>{traffic / 1000} ТЫС.</GreenNumber>
      </td>
      <td>
        <IndexBlock>
          {!!google_title && (
            <MediaImage
              src={google}
              placeholder={"Индексируется Google"}
              alt={"Индексируется Google"}
              title={"Индексируется Google"}
            />
          )}
          {!!yandex_news && (
            <MediaImage
              src={yandex}
              placeholder={"Индексируется Yandex"}
              alt={"Индексируется Yandex"}
              title={"Индексируется Yandex"}
            />
          )}
          {!!top_media && (
            <MediaImage
              src={topMedia}
              placeholder={"Индексируется TopMedia"}
              alt={"Индексируется TopMedia"}
              title={"Индексируется TopMedia"}
            />
          )}
        </IndexBlock>
      </td>
      <td>
        <GreenNumber bold>{`${cost_sum} ₽`}</GreenNumber>
      </td>
      <td>
        <CheckImage
          src={checked ? checkedImg : unCheckedImg}
          onClick={() => setChecked(smi_id)}
        />
      </td>
    </Container>
  );
};

const Container = styled.tr`
  font-family: Stem, serif;
  background: #ffffff;
  border-bottom: 1px solid rgba(130, 130, 130, 0.18);

  line-height: 186px;

  & > td {
    vertical-align: middle;
  }
`;

const NameBlock = styled.div`
  max-width: 300px;
  overflow: hidden;
  padding-left: 44px;
  display: flex;
  flex-flow: column;
`;

const SmiName = styled.span`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */

  /* Grey */

  color: #4f4f4f;
  border-radius: 24px;
`;

const EyeBlock = styled.div`
  display: flex;
  padding-top: 25px;
  padding-bottom: 10px;
`;

const EyeImage = styled.img`
  width: 32px;
  height: 18px;
  object-fit: contain;
`;

const ViewLabel = styled.p`
  padding-left: 7px;
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  /* or 182% */

  text-transform: uppercase;

  /* Light grey */

  color: #828282;
`;

const LinkToSmi = styled.a`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  /* or 182% */

  text-decoration-line: underline;

  /* Main Green */

  color: #67b580;
`;
const MPEBlock = styled.div`
  display: flex;
  flex-flow: column;
`;
const GreenNumber = styled.span<{ bold?: boolean }>`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 28px;
  line-height: 37px;
  color: #67b580;
`;

const IndexBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-template-rows: repeat(2, min-content);
  grid-gap: 17px 34px;
  align-items: center;
`;

const MediaImage = styled.img`
  display: block;
  width: 40px;
  height: 40px;
`;

const CheckImage = styled.img`
  cursor: pointer;
  padding-right: 20px;
`;
