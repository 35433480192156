import React, { FunctionComponent } from "react";
import {inject} from "mobx-react";
import {IUserStore} from "models";
import ASV_NewsIntegrumItem from "./ASV_NewsIntegrumItem";
import DOMRF_NewsIntegrumItem from "./DOMRF_NewsIntegrumItem";
import MTS_NewsIntegrumItem from "./MTS_NewsIntegrumItem";
import KALASHNIKOVGROUP_NewsIntegrumItem from "./KALASHNIKOVGROUP_NewsIntegrumItem";
import POCHTABANK_NewsIntegrumItem from "./POCHTABANK_NewsIntegrumItem";
import CUSTOMS_NewsIntegrumItem from "./CUSTOMS_NewsIntegrumItem";

interface INewsIntegrumItemProps {
  userStore?: IUserStore;
}

const newsIntegrumItemPage: any = {
  ASV: <ASV_NewsIntegrumItem />,
  DOMRF: <DOMRF_NewsIntegrumItem />,
  MTS: <MTS_NewsIntegrumItem />,
  KALASHNIKOVGROUP: <KALASHNIKOVGROUP_NewsIntegrumItem />,
  POCHTABANK: <POCHTABANK_NewsIntegrumItem />,
  CUSTOMS: <CUSTOMS_NewsIntegrumItem />,
};

const NewsIntegrumItem: FunctionComponent<INewsIntegrumItemProps> = ({userStore}) => {
  const company =  userStore && userStore.company || 'ASV';
  return (newsIntegrumItemPage[company]);
};

export default inject('userStore')(NewsIntegrumItem);
