exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./images/eye_opened.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./images/eye.svg"));

// Module
exports.push([module.id, ".POCHTABANK_Input_root__1GsXV {\n  display: inline-block;\n  margin-bottom: 20px;\n  position: relative;\n}\n\n.POCHTABANK_Input_input__1bIri {\n  background: var(--input-bg);\n  border: 1px solid #efefef;\n  border-radius: 30px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  -ms-flex: 1 1;\n      flex: 1 1;\n  font-size: 18px;\n  height: 54px;\n  padding: 15px;\n  -webkit-transition: border 0.2s ease-out;\n  transition: border 0.2s ease-out;\n  width: 100%;\n}\n\n.POCHTABANK_Input_input__1bIri:focus {\n  border-color: #ccc;\n  outline: none;\n}\n\n.POCHTABANK_Input_showPasswordToggler__1p1kr {\n  background: var(--input-bg) url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  cursor: pointer;\n  position: absolute;\n  z-index: 1;\n  width: 40px;\n  height: 18px;\n  right: 15px;\n  top: 50%;\n  -webkit-transform: translateY(-50%);\n          transform: translateY(-50%);\n}\n\n.POCHTABANK_Input_showPasswordToggler__1p1kr.POCHTABANK_Input_shown__1S7D6 {\n  background: var(--input-bg) url(" + ___CSS_LOADER_URL___1___ + ") no-repeat center;\n}\n\n.POCHTABANK_Input_fullWidth__WaY-1 {\n  width: 100%;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "POCHTABANK_Input_root__1GsXV",
	"input": "POCHTABANK_Input_input__1bIri",
	"showPasswordToggler": "POCHTABANK_Input_showPasswordToggler__1p1kr",
	"shown": "POCHTABANK_Input_shown__1S7D6",
	"fullWidth": "POCHTABANK_Input_fullWidth__WaY-1"
};