import { inject } from "mobx-react";
import React from "react";
import DEFAULT_COMPANY from "../../constants/company";
import { IUserStore } from "../../models";
import ASV_logo from "assets/ASV/footer-logo.svg";
import DOMRF_logo from "assets/DOMRF/footer-logo.svg";
import MTS_logo from "assets/MTS/footer-logo.svg";
import KALASHNIKOVGROUP_logo from "assets/KALASHNIKOVGROUP/footer-logo.svg";
import POCHTABANK_logo from "assets/POCHTABANK/footer-logo.svg";
import CUSTOMS_logo from "assets/CUSTOMS/footer-logo.svg";
import ASV_styles from "./ASV_Footer.module.css";
import DOMRF_styles from "./DOMRF_Footer.module.css";
import MTS_styles from "./MTS_Footer.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Footer.module.css";
import POCHTABANK_styles from "./POCHTABANK_Footer.module.css";
import CUSTOMS_styles from "./CUSTOMS_Footer.module.css";

interface IFooterProps {
  userStore?: IUserStore;
}

const assets: any = {
  ASV: {
    styles: ASV_styles,
    logo: ASV_logo
  },
  DOMRF: {
    styles: DOMRF_styles,
    logo: DOMRF_logo
  },
  MTS: {
    styles: MTS_styles,
    logo: MTS_logo,
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles,
    logo: KALASHNIKOVGROUP_logo,
  },
  POCHTABANK: {
    styles: POCHTABANK_styles,
    logo: POCHTABANK_logo,
  },
  CUSTOMS: {
    styles: CUSTOMS_styles,
    logo: CUSTOMS_logo,
  },
};

const Footer: React.FC<IFooterProps> = ({ userStore }) => {
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const { logo, styles } = assets[company];

  return (
    <footer className={styles.footer}>
      <img src={logo} className={styles.logo} alt="logo" />
    </footer>
  );
};

export default inject("userStore")(Footer);
