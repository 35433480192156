import { cast, types, flow } from "mobx-state-tree";
import NewsItem from "./NewsItem";
import { getTopNews } from "api/news";

const NewsTop = types
  .model("NewsTop", {
    news: types.array(NewsItem),
    shown: true,
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const switchOn = (onOrOff: boolean) => {
      self.shown = onOrOff;
    };
    const getNewsItems = flow(function* getNewsItems() {
      self.news = cast([]);
      self.state = "pending";
      try {
        // @ts-ignore
        self.news = yield cast(getTopNews());
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    return { switchOn, getNewsItems };
  });
export default NewsTop;
