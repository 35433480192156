exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".KALASHNIKOVGROUP_ViewType_viewTypes__1-YVd {\n  -ms-flex-align: center;\n      align-items: center;\n  display: -ms-flexbox;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  /*position: absolute;*/\n  /*right: 0;*/\n  /*top: 0;*/\n  /*z-index: 2;*/\n}\n\n.KALASHNIKOVGROUP_ViewType_label__16k2w {\n  display: inline-block;\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 30px;\n  color: var(--kalashnikovgroup-dark-gray);\n}\n\n.KALASHNIKOVGROUP_ViewType_viewType__1Un7i {\n  color: var(--kalashnikovgroup-light-gray);\n  display: inline-block;\n  height: 18px;\n  margin-left: 20px;\n  opacity: 0.45;\n  width: 18px;\n}\n\n.KALASHNIKOVGROUP_ViewType_active__auWL4 {\n  color: var(--kalashnikovgroup-red);\n  opacity: 1;\n}\n", ""]);

// Exports
exports.locals = {
	"viewTypes": "KALASHNIKOVGROUP_ViewType_viewTypes__1-YVd",
	"label": "KALASHNIKOVGROUP_ViewType_label__16k2w",
	"viewType": "KALASHNIKOVGROUP_ViewType_viewType__1Un7i",
	"active": "KALASHNIKOVGROUP_ViewType_active__auWL4"
};