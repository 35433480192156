import { IInterActionCard } from "../components/InterActionCard/InterActionCard";
import PR from "../components/InterActionGrid/images/PR.png";
import airPR from "../components/InterActionGrid/images/AirPR.png";
import Telegramm from "../components/InterActionGrid/images/Telegramm.png";
import TopRU from "../components/InterActionGrid/images/TopRU.png";
import Bugor from "../components/InterActionGrid/images/Bugor.png";
import Special from "../components/InterActionGrid/images/Special.png";
import Author from "../components/InterActionGrid/images/Author.png";

export const InterActiveCardsArray: IInterActionCard[] = [
  {
    title: "PR-ВОЛНА",
    description:
      "PR-волна - уникальный инструмент для распространения информационных поводов, работающий по технологии, которая является собственной разработкой нашего агентства. Преимущество PR-волны в том, что статья самостоятельно цитируется интернет ресурсами, поисковыми системами, за счет чего достигается высокий охват интернет пользователей сети. Также при постановке соответствующей задачи, возможно формирование новостной ветки в Яндекс.Новостях.",
    link: "/interaction/create",
    logo: PR,
    buttonText: "Оформить заказ",
  },
  // {
  //   title: "AIR PR WAVE",
  //   description:
  //     "Air PR wave - раздел, предназначенный для быстрого распространения ТОЛЬКО позитивных и нейтральных имиджевых статей. Вы можете выбрать необходимое вам количество публикаций, установив собственную цену. В системе есть возможность фильтрации изданий по региону, тематикам разделов и их индексируемости в поисковых системах.",
  //   link: "#",
  //   logo: airPR,
  //   buttonText: "Перейти к списку",
  // },
  // {
  //   title: "TELEGRAMM КАНАЛЫ",
  //   description:
  //     "Мессенджер Telegram – новое слово в российском пиаре. В нашей базе – самые популярные telegram-каналы, которые характеризуются не только большой аудиторией, но и ее исключительным качеством. Среди подписчиков каналов – федеральные чиновники, журналисты, политологи и бизнесмены. Подготовка и распространение месседжа с использованием telegram-каналов – это прицельное попадание точно сконфигурированных смыслов в целевую аудиторию кампании.",
  //   link: "http://gosmi.ru/api/getSmiList/id/451/?format=xml",
  //   logo: Telegramm,
  //   buttonText: "Перейти к списку",
  //   isExternalLink: true,
  // },
  // {
  //   title: "ТОП РОССИЙСКИХ СМИ",
  //   description:
  //     "Публикация материала в самых авторитетных и популярных российских СМИ спасет даже самый непростой PR-проект. В нашей базе – топовые федеральные медиа, материалы в которых могут полностью изменить ход вашей PR кампании.\n" +
  //     "Система автоматически перенаправит ваш запрос в редакцию. ",
  //   link: "http://gosmi.ru/api/getSmiList/id/450/?format=xml",
  //   logo: TopRU,
  //   buttonText: "Перейти к списку",
  //   isExternalLink: true,
  // },
  // {
  //   title: "ТОП ЗАРУБЕЖНЫХ СМИ",
  //   description:
  //     "В век глобализации многие информационные кампании не ограничиваются территорией одной страны. Специально для таких случаев в нашей базе имеются самые авторитетные и рейтинговые издания зарубежной прессы, которые готовы к сотрудничеству.\n" +
  //     "Дополнение: возможность выхода и итоговую версию материала определяет издание, исходя из редакционной политики. ",
  //   link: "http://gosmi.ru/api/getSmiList/id/455/?format=xml",
  //   logo: Bugor,
  //   buttonText: "Перейти к списку",
  //   isExternalLink: true,
  // },
  // {
  //   title: "СПЕЦИАЛЬНЫЙ ЗАКАЗ",
  //   description:
  //     "Некоторые кампании требуют особого отношения со стороны всех ее участников. Мы, как никто другой, понимаем это, и готовы оказать помощь в подготовке самых срочных и секретных материалов с соблюдением всех условий и договоренностей. Если вам необходим уникальный подход – отправьте нам запрос, и мы сделаем все возможное для решения вашей задачи. ",
  //   link: "#",
  //   logo: Special,
  //   buttonText: "Перейти к списку",
  // },
  // {
  //   title: "ЗАКАЗАТЬ АВТОРСКИЙ МАТЕРИАЛ",
  //   description:
  //     "Мы понимаем, что возможность подготовки качественного материала существует не всегда. В нашей команде работают опытные журналисты и редакторы, пишущие для ведущих федеральных СМИ, которые способны быстро подготовить материал на основе вашего плана и фактуры. Для текстов узкопрофильной тематики мы готовы привлечь к выполнению работы специалистов в нужной сфере и сделать все, чтобы текст был фактически точным и стилистически выверенным.",
  //   link: "http://gosmi.ru/api/getSmiList/id/458/?format=xml",
  //   logo: Author,
  //   buttonText: "Перейти к списку",
  //   isExternalLink: true,
  // },
];
