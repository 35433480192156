import classNames from "classnames";
import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import cn from 'classnames';
import { IRootStore } from "models";
import { AccountMenu, Favorites } from "components";

import DOMRF_logo from "assets/DOMRF/DOMRF_logo_with_text.svg";
import DOMRF_avatar from "assets/DOMRF/avatar.svg";
import DEFAULT_COMPANY from "../../constants/company";
import DOMRF_styles from "./DOMRF_Account.module.css";

import ASV_logo from "assets/ASV/logo_with_text.svg";
import ASV_avatar from "assets/ASV/avatar.svg";
import ASV_styles from "./ASV_Account.module.css";

import MTS_logo from "assets/MTS/logo_with_text.svg";
import MTS_avatar from "assets/MTS/avatar.svg";
import MTS_styles from "./MTS_Account.module.css";

import KALASHNIKOVGROUP_logo from "assets/KALASHNIKOVGROUP/logo_with_text.svg";
import KALASHNIKOVGROUP_avatar from "assets/KALASHNIKOVGROUP/avatar.svg";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Account.module.css";

import POCHTABANK_logo from "assets/POCHTABANK/logo_with_text.svg";
import POCHTABANK_avatar from "assets/POCHTABANK/avatar.svg";
import POCHTABANK_styles from "./POCHTABANK_Account.module.css";

import CUSTOMS_logo from "assets/CUSTOMS/logo_with_text.svg";
import CUSTOMS_avatar from "assets/CUSTOMS/avatar.svg";
import CUSTOMS_styles from "./CUSTOMS_Account.module.css";

interface IAccountProps {
  rootStore?: IRootStore;
}

interface IAccountState {
  show: boolean;
}

@inject("rootStore")
@observer
class Account extends PureComponent<IAccountProps, IAccountState> {
  constructor(props: IAccountProps) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentDidMount() {
    const { rootStore } = this.props;
    if (!rootStore || !rootStore.userStore) {
      return;
    }
    const { userInfo } = rootStore.userStore;

    if (!userInfo || !rootStore.userStore.loggedIn) {
      rootStore.userStore.logout();
    }

    this.setState({ show: true });
  }

  render() {
    const assets: any = {
      styles: {
        ASV: ASV_styles,
        DOMRF: DOMRF_styles,
        MTS: MTS_styles,
        KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
        POCHTABANK: POCHTABANK_styles,
        CUSTOMS: CUSTOMS_styles,
      },
      logo: {
        ASV: ASV_logo,
        DOMRF: DOMRF_logo,
        MTS: MTS_logo,
        KALASHNIKOVGROUP: KALASHNIKOVGROUP_logo,
        POCHTABANK: POCHTABANK_logo,
        CUSTOMS: CUSTOMS_logo,
      },
      avatar: {
        ASV: ASV_avatar,
        DOMRF: DOMRF_avatar,
        MTS: MTS_avatar,
        KALASHNIKOVGROUP: KALASHNIKOVGROUP_avatar,
        POCHTABANK: POCHTABANK_avatar,
        CUSTOMS: CUSTOMS_avatar,
      },
    };

    const { rootStore } = this.props;
    const { userStore } = rootStore || {};
    const companyName = userStore && userStore.company.toUpperCase() || DEFAULT_COMPANY;
    const { firstName, lastName, avatar } =
      userStore
        ? userStore.userInfo
        : { firstName: "", lastName: "", avatar: assets.avatar[companyName] };
    const logout = (userStore && userStore.logout) || (() => {});

    const logo = userStore && userStore.userInfo && userStore.userInfo.company_logo ? userStore.userInfo.company_logo : assets.logo[companyName];
    const styles = assets.styles[companyName];

    return (
      this.state.show && (
        <div className={cn(styles.root, {[styles.openedMenu]: rootStore && rootStore.menuStore.open})}>
          <div className={styles.nav}>
            <Link to="/" className={styles.logo}>
              <img src={logo} alt="" />
            </Link>
            <div className={styles.user}>
              <div className={styles.avatar}>
                <img src={avatar} alt="" />
              </div>
              <div className={styles.userInfo}>
                <div
                  className={styles.userName}
                >{`${firstName} ${lastName}`}</div>
                {/*<a className={styles.changePass}>Сменить пароль</a>*/}
              </div>
            </div>
            <div className={styles.menu}>
              <AccountMenu logout={logout} />
            </div>
          </div>
          <div className={styles.content}>
            <Favorites />
          </div>
        </div>
      )
    );
  }
}

export default Account;
