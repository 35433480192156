import { cast, types, flow } from "mobx-state-tree";
// import { getNewsItemPage } from "mocks/news_page";
import { getNewsFeed } from "api/news";
import NewsFeed from "./NewsFeed";

const NewsFeedPage = types
  .model("NewsFeedPage", {
    page: NewsFeed,
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const getFeed = flow(function* getFeed(id) {
      self.state = "pending";
      try {
        // @ts-ignore
        self.page = yield cast(getNewsFeed(id));
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    return { getFeed };
  });
export default NewsFeedPage;
