import { cast, types, flow } from "mobx-state-tree";
import { getMenu } from "api/menu";

const MenuItem = types.model("MenuItem", {
  id: types.number,
  name: types.string
});

const Menu = types
  .model("Menu", {
    menu: types.array(MenuItem),
    open: false,
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const getMenuItems = flow(function* getMenuItems() {
      self.menu = cast([]);
      self.state = "pending";
      try {
        // @ts-ignore
        self.menu = yield cast(getMenu());
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    const openMenu = () => {
      self.open = true;
    };
    const closeMenu = () => {
      self.open = false;
    };
    const toggleMenu = () => {
      self.open = !self.open;
    };
    return { getMenuItems, openMenu, closeMenu, toggleMenu };
  });

const MenuStore = Menu.create({ menu: [], state: "pending" });

export { Menu, MenuItem, MenuStore };
