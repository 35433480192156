exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".ASV_Header_header__3CMBk {\n    background: var(--asv-green);\n    height: 85px;\n}\n\n.ASV_Header_header__3CMBk .container {\n    -ms-flex-align: center;\n        align-items: center;\n    display: -ms-flexbox;\n    display: flex;\n    height: 100%;\n}\n\n.ASV_Header_brand__2IAnE {\n    -ms-flex: 0 0 60px;\n        flex: 0 0 60px;\n}\n\n.ASV_Header_logo__GOmWb {\n    display: block;\n    height: 37px;\n}\n\n.ASV_Header_logoText__1-8Fo {\n    display: none;\n    height: 50px;\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + " {\n    .ASV_Header_header__3CMBk {}\n    .ASV_Header_brand__2IAnE {\n        border-right: 1px solid rgba(255, 255, 255, 0.18);\n        -ms-flex-preferred-size: 245px;\n            flex-basis: 245px;\n        width: 245px;\n    }\n    .ASV_Header_logo__GOmWb {\n        display: none;\n    }\n    .ASV_Header_logoText__1-8Fo {\n        display: block;\n    }\n}\n", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"header": "ASV_Header_header__3CMBk",
	"brand": "ASV_Header_brand__2IAnE",
	"logo": "ASV_Header_logo__GOmWb",
	"logoText": "ASV_Header_logoText__1-8Fo"
};