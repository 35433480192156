import React, { FC } from "react";
import styled from "styled-components";
import google from "../../assets/google.png";
import { OrderItem } from "./StatisticItem";

export const StatisticItemMobile: FC<OrderItem> = ({
  isOpen,
  setActive,
  isLast = true,
  id,
  id_gosmi,
  deadline,
  original_link,
  keywords,
  need_source_link,
  from_gosmi_order,
  rewrite_type,
  full_reprint,
}) => {
  return (
    <Container>
      <LineBlock
        isOpen={isOpen}
        isBorderBottom={!isOpen && isLast}
        onClick={() => setActive(id)}
      >
        <NameBlock>
          <BoldText isOpen={isOpen}>PR-волна</BoldText>
          <SimpleText>#{id_gosmi}</SimpleText>
        </NameBlock>
        <DateBlock>
          <SimpleText>{deadline}</SimpleText>
          <BoldTextStat>
            {" "}
            {from_gosmi_order.smi.reduce(
              (previousValue, currentValue) =>
                currentValue.statusName === "Выполнено"
                  ? previousValue + 1
                  : previousValue,
              0
            )}{" "}
            из {from_gosmi_order.smi.length}
          </BoldTextStat>
        </DateBlock>
      </LineBlock>

      <FullInfoContainer isHidden={!isOpen}>
        <InfoBlock>
          <InfoCell>
            <GrayText>
              Ссылка на <br /> первоисточник:
            </GrayText>
            <GreenLink>{original_link}</GreenLink>
          </InfoCell>

          <InfoCell>
            <GrayText>
              Ключевые слова
              <br />
              для заголовка:
            </GrayText>
            <GreenText>{keywords}</GreenText>
          </InfoCell>

          <DoubleBlock>
            <InfoCell>
              <GrayText>
                Тип
                <br />
                рерайта
              </GrayText>
              <GreenText>
                {full_reprint
                  ? "Полная перепечатка"
                  : rewrite_type
                  ? "Средний"
                  : "Легкий"}
              </GreenText>
            </InfoCell>

            <InfoCell>
              <GrayText>
                Ссылка на <br /> источник
              </GrayText>
              <GreenText>{need_source_link ? "Да" : "Нет"}</GreenText>
            </InfoCell>
          </DoubleBlock>

          <InfoCell>
            <GrayText>
              Тип
              <br />
              размещения
            </GrayText>
            <GreenText>PR-волна</GreenText>
          </InfoCell>
        </InfoBlock>

        <BlackLabel>Размещено в:</BlackLabel>

        {from_gosmi_order.smi.map(({ smiId, smiName, statusName }) => (
          <PartnerBlock key={smiId + smiName}>
            <PartnerNameBlock>
              <PartnerName>{smiName}</PartnerName>
              <PartnerImage src={google} />
            </PartnerNameBlock>
            <StatusLabel
              isDeclined={statusName.toLowerCase().includes("отклонено")}
            >
              {statusName}
            </StatusLabel>
          </PartnerBlock>
        ))}

        <PriceContainer>
          <PriceBlock>
            <PriceName>Внесенная сумма:</PriceName>
            <PriceValue>{from_gosmi_order.current_balance} ₽</PriceValue>
          </PriceBlock>
          <PriceBlock>
            <PriceName>Возвращенная сумма:</PriceName>
            <PriceValue>{from_gosmi_order.return_balance} ₽</PriceValue>
          </PriceBlock>
          <PriceBlock>
            <PriceName>Списано исполнителем:</PriceName>
            <PriceValue>{from_gosmi_order.complete_balance} ₽</PriceValue>
          </PriceBlock>
        </PriceContainer>
      </FullInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: Stem, serif;
`;

const LineBlock = styled.div<{ isOpen: boolean; isBorderBottom: boolean }>`
  width: 100%;
  display: flex;
  flex-flow: column;
  cursor: pointer;
  justify-content: space-between;
  background: ${(props) => (props.isOpen ? "#67b580" : "#ffffff")};
  color: ${(props) => (props.isOpen ? "#ffffff" : "#4F4F4F")};
  padding: 18px;
`;

const SimpleText = styled.p`
  font-family: Stem, serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
`;

const BoldText = styled.p<{ isOpen?: boolean }>`
  font-family: Stem, serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  color: ${(props) => (props.isOpen ? "#ffffff" : "#67B580")};
`;

const BoldTextStat = styled.p`
  font-family: Stem, serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
`;

const NameBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DateBlock = styled.div`
  padding-top: 18px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FullInfoContainer = styled.div<{ isHidden: boolean }>`
  display: ${(props) => (props.isHidden ? "none" : "initial")};
  background: #ffffff;
  margin-bottom: 21px;

  border: 1px solid rgba(130, 130, 130, 0.18);
  box-sizing: border-box;
`;

const InfoBlock = styled.div`
  width: 100%;
  padding: 0 19px 19px 19px;
  display: flex;
  flex-flow: column;
`;

const InfoCell = styled.div`
  display: flex;
  flex-flow: column;
  & > :first-child {
    padding: 18px 0;
  }
`;

const GrayText = styled.span`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: rgba(79, 79, 79, 0.45);
`;

const GreenLink = styled.a`
  cursor: pointer;
  font-family: Stem, serif;
  font-size: 18px;
  line-height: 16px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-line: underline;
  color: rgba(103, 181, 128, 1);
`;

const GreenText = styled.span`
  font-family: Stem, serif;
  font-size: 18px;
  line-height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-line: underline;
  color: rgba(103, 181, 128, 1);
`;

const BlackLabel = styled.span`
  display: block;
  font-family: Stem, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  padding: 22px 18px;
  border-bottom: 1px solid rgba(130, 130, 130, 0.18);

  color: #4f4f4f;
`;

const PartnerBlock = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 18px;
  border-bottom: 1px solid rgba(130, 130, 130, 0.18);
`;

const PartnerNameBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const PartnerName = styled(GreenLink)`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;

  max-width: initial;
  padding-right: 28px;
`;

const PartnerImage = styled.img`
  width: 45px;
  height: 45px;
  object-fit: contain;
`;

const StatusLabel = styled.span<{ isDeclined?: boolean }>`
  font-family: Stem, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;

  margin-right: auto;
  padding-top: 24px;
  color: ${(props) => (props.isDeclined ? "#FF5F5B" : "#67B580")};
`;

const PriceContainer = styled.div`
  padding: 25px 18px 10px;
  display: flex;
  flex-flow: column;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-flow: column;
  padding-bottom: 15px;
`;

const PriceName = styled.p`
  //styleName: D3;
  font-family: Stem, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;

  color: #67b580;

  padding-bottom: 5px;
`;

const PriceValue = styled.p`
  //styleName: T3;
  font-family: Stem, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;

  color: #67b580;
`;

const DoubleBlock = styled.div`
  display: flex;
  justify-content: space-between;

  & > :nth-child(2) {
    margin: auto;
  }
`;
