exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".KALASHNIKOVGROUP_SearchResults_searchResultsHeader__2iwgA {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.KALASHNIKOVGROUP_SearchResults_resultsTitle__3B2SM {\n  color: var(--kalashnikovgroup-dark-gray);\n  font-size: 18px;\n  line-height: 23px;\n  margin-bottom: 25px;\n  margin-left: 20px;\n}\n\n.KALASHNIKOVGROUP_SearchResults_viewTypesToggler__23ZXf {\n  height: 30px;\n  position: relative;\n  margin-left: auto;\n  margin-bottom: 25px;\n}\n\n.KALASHNIKOVGROUP_SearchResults_total__15dVu {\n  color: var(--kalashnikovgroup-light-green);\n  font-weight: 600;\n}", ""]);

// Exports
exports.locals = {
	"searchResultsHeader": "KALASHNIKOVGROUP_SearchResults_searchResultsHeader__2iwgA",
	"resultsTitle": "KALASHNIKOVGROUP_SearchResults_resultsTitle__3B2SM",
	"viewTypesToggler": "KALASHNIKOVGROUP_SearchResults_viewTypesToggler__23ZXf",
	"total": "KALASHNIKOVGROUP_SearchResults_total__15dVu"
};