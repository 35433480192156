import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { IRootStore, IFilterSmi } from "../../models";
import { SMiListItem } from "./SMiListItem";
import { useWindowSize } from "../../helpers/useWindowsSize";
import { SMiListItemMobile } from "./SMiListItemMobile";
import { SelectedType, IFilter } from "../../pages/CreateOrder/CreateOrder";
import InfiniteScroll from "react-infinite-scroller";
// import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import _ from "lodash";
import {ReactComponent as Arrow} from "../../assets/back_icon.svg";

interface IProps {
  rootStore?: IRootStore;
  selected: SelectedType[];
  setSelected: (val: SelectedType[]) => void;
  loadMore: () => void;
  // filter: IFilter;
  // setFilter: (filter: IFilter) => void;
  // filterStore?: IFilterSmi;
}

const SmiListTableComponent: FC<IProps> =
({
  rootStore,
  selected,
  setSelected,
  loadMore,
   // filter, filterStore, setFilter,
}) => {
  // const [oldState, setOldState] = useState(filter);
  // const [sortState, setSortState] = useState('ASC')
  const windowSize = useWindowSize();



  const handleSelect = useCallback(
    (id: string, price: number) => {
      setSelected(
        selected.filter((item) => item.id === id).length > 0
          ? [...selected.filter((item) => item.id !== id)]
          : [...selected, { id, price }]
      );
    },
    [setSelected, selected]
  );

  // const checkAnyThingChangedSort = useCallback(() => {
  //   !_.isEqual(filter, oldState) && loadMore();
  // }, [filter, oldState, loadMore, _]);
  //
  // const switchSort = () => {
  //   (sortState === 'ASC') ? setSortState('DESC') : setSortState('ASC')
  //   console.log(sortState)
  //   return sortState
  // }

  // const setSort = (type: any, value: any) =>
  //     setFilter({ ...filter, order_type: type, order_by: value })
  //
  // const sortName = () =>{
  //   checkAnyThingChangedSort();
  //   switchSort();
  // }
  // const sortPrice = () =>{
  //   checkAnyThingChangedSort();
  //   switchSort();
  // }

  return (
      <div>
        <InfiniteScroll
          pageStart={(rootStore && rootStore.filteredSmiStore.smiList.length) || 0} //This is important field to render the next data
          loadMore={loadMore}
          threshold={200}
          hasMore={(rootStore && rootStore.filteredSmiStore.hasMore) || false}
          style={{ overflow: "hidden" }}
          loader={
            <LoadingContainer>
              <Loader type={"TailSpin"} color="#67B580" height={80} width={80} />
            </LoadingContainer>

          }

          // scrollableTarget="root"
          // endMessage={
          // }
        >

      {/*// <InfiniteScroll*/}
      {/*//     dataLength={(rootStore && rootStore.filteredSmiStore.smiList.length) || 0} //This is important field to render the next data*/}
      {/*//     next={loadMore}*/}
      {/*//     scrollThreshold={"0"}*/}
      {/*//     hasMore={(rootStore && rootStore.filteredSmiStore.hasMore) || false}*/}
      {/*//     style={{ overflow: "hidden" }}*/}
      {/*//     loader={*/}
      {/*//       <LoadingContainer>*/}
      {/*//         <Loader type={"TailSpin"} color="#67B580" height={80} width={80} />*/}
      {/*//       </LoadingContainer>*/}
      {/*//     }*/}
      {/*//     // scrollableTarget="root"*/}
      {/*//     endMessage={*/}
      {/*//       <LoadingContainer>*/}
      {/*//         <b>Больше СМИ нет</b>*/}
      {/*//       </LoadingContainer>*/}
      {/*//     }*/}
      {/*// >*/}
      <Container>
            <thead>
              <HeaderRow>
                <HeaderLabel>Название СМИ</HeaderLabel>
                <HeaderLabel>Активность </HeaderLabel>
                {/*<HeaderLabel>MPEIndex </HeaderLabel>*/}
                <HeaderLabel>Охват</HeaderLabel>
                <HeaderLabel>Индексируемость </HeaderLabel>
                <HeaderLabel>Цена </HeaderLabel>
                <HeaderLabel> </HeaderLabel>
              </HeaderRow>
            </thead>
            <TableBody>
              {rootStore &&
                rootStore.filteredSmiStore.smiList.map((smi) =>
                  windowSize.width > 991 ? (
                    <SMiListItem
                      {...smi}
                      key={smi.smi_id}
                      setChecked={() =>
                        handleSelect(smi.smi_id.toString(), smi.cost_sum)
                      }
                      checked={
                        selected.filter((item) => item.id === smi.smi_id.toString())
                          .length > 0
                      }
                    />
                  ) : (
                    <SMiListItemMobile
                      {...smi}
                      key={smi.smi_id}
                      setChecked={() =>
                        handleSelect(smi.smi_id.toString(), smi.cost_sum)
                      }
                      checked={
                        selected.filter((item) => item.id === smi.smi_id.toString())
                          .length > 0
                      }
                    />
                  )
                )}
            </TableBody>
          </Container>
        </InfiniteScroll>
      </div>
  );
};

const AddTripButton = (props: any) => {
  return <button onClick={props.addTrip}>Add a trip</button>
}

export const SmiListTable = inject("rootStore")(
  observer(SmiListTableComponent)
);

const Container = styled.table`
  font-family: Stem, serif;
  margin-top: 55px;
  width: 100%;
  text-align: left;
  margin-bottom: 60px;
`;

const TableBody = styled.tbody`
  @media screen and (min-width: 600px) {
    & > :first-child > :first-child {
      border-top-left-radius: 24px;
    }
    & > :first-child > :last-child {
      border-top-right-radius: 24px;
    }

    & > :last-child > :first-child {
      border-bottom-left-radius: 24px;
    }
    & > :last-child > :last-child {
      border-bottom-right-radius: 24px;
    }

    & > :last-child {
      border-bottom: unset;
    }
  }
`;

const HeaderRow = styled.tr`
  & > :first-child {
    padding-left: 44px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const HeaderLabel = styled.th`
  font-family: Stem, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 15px;
  color: #4f4f4f;
  padding-bottom: 30px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin-bottom: 60px;
`;
