import React, { PureComponent, Fragment } from "react";
import { observer, Provider } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { NewsItemPage, INewsItemPage } from "models";
import { Footer, Header, NewsPage } from "components";

interface INewsItemPageProps {
  id?: string;
}

interface Props extends RouteComponentProps<INewsItemPageProps> {}

@observer
class ASV_NewsIntegrumItem extends PureComponent<Props> {
  private readonly pageStore: INewsItemPage;

  constructor(props: Props) {
    super(props);

    const { id } = props.match.params;

    this.pageStore = NewsItemPage.create({
      page: { id: 0 },
      state: "pending"
    });
    //@ts-ignore
    this.pageStore.getNewsItem("IntegrumNews", Number(id));
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="container">
          {this.pageStore.state === "done" && (
            <Provider pageStore={this.pageStore}>
              <NewsPage />
            </Provider>
          )}
          {/*<NewsGrid />*/}
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default withRouter(ASV_NewsIntegrumItem);
