import React, { PureComponent } from "react";
import {inject, observer} from "mobx-react";
import cn from "classnames";
import { FavoriteNewsStore } from "models";
import { NewsListItem } from "components";
import DOMRF_styles from "./DOMRF_Favorites.module.css";
import ASV_styles from "./ASV_Favorites.module.css";
import MTS_styles from "./MTS_Favorites.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_Favorites.module.css";
import POCHTABANK_styles from "./POCHTABANK_Favorites.module.css";
import CUSTOMS_styles from "./CUSTOMS_Favorites.module.css";

interface IFavoritesProps {
  userStore?: any;
}

const assets: any = {
  styles: {
    ASV: ASV_styles,
    DOMRF: DOMRF_styles,
    MTS: MTS_styles,
    KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
    POCHTABANK: POCHTABANK_styles,
    CUSTOMS: CUSTOMS_styles,
  },
};

@inject('userStore')
@observer
class Favorites extends PureComponent<IFavoritesProps> {
  componentDidMount(): void {
    FavoriteNewsStore.getNewsItems();
  }

  render() {
    const favoritesIsEmpty =
      FavoriteNewsStore.news && FavoriteNewsStore.news.length === 0;
    const styles = assets.styles[this.props.userStore.company];
    return (
      <>
        <h1 className={styles.title}>Избранное</h1>
        {FavoriteNewsStore.state === "done" &&
          (!favoritesIsEmpty && (
            <div className={styles.list}>
              <div className={styles.listTitle}>Все статьи</div>
              {FavoriteNewsStore.news.map((item, index) => (
                <div className={styles.favList} key={`${index}-${item.id}`}>
                  <NewsListItem {...item} />
                  <span
                    onClick={() => {
                      FavoriteNewsStore.removeNewsFromFavorites(item.id);
                    }}
                    className={cn(styles.favoriteLink, styles.favorite)}
                  />
                </div>
              ))}
            </div>
          ))}
      </>
    );
  }
}

export default Favorites;
