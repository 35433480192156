import { types } from "mobx-state-tree";

const Meta = types
  .model("Meta", {
    page: 1,
    per_page: "30",
    total: 1,
    total_pages: 1,
  });

export default Meta;
