exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DOMRF_SearchResults_searchResultsHeader__cIoy1 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.DOMRF_SearchResults_resultsTitle__17PNL {\n  color: var(--domrf-dark-gray);\n  font-size: 18px;\n  line-height: 23px;\n  margin-bottom: 25px;\n  margin-left: 20px;\n}\n\n.DOMRF_SearchResults_viewTypesToggler__1vgZu {\n  height: 30px;\n  position: relative;\n  margin-left: auto;\n  margin-bottom: 25px;\n}\n\n.DOMRF_SearchResults_total__2gtfJ {\n  color: var(--domrf-light-green);\n  font-weight: 600;\n}", ""]);

// Exports
exports.locals = {
	"searchResultsHeader": "DOMRF_SearchResults_searchResultsHeader__cIoy1",
	"resultsTitle": "DOMRF_SearchResults_resultsTitle__17PNL",
	"viewTypesToggler": "DOMRF_SearchResults_viewTypesToggler__1vgZu",
	"total": "DOMRF_SearchResults_total__2gtfJ"
};