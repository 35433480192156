import React, {FC, useState} from "react";
import styled from "styled-components";
import backArrow from "../../assets/back_icon.png";
import {createStyles, FormControl, MenuItem, Theme, useTheme,} from "@material-ui/core";
import {CssTextFieldForTotalBlock, SelectForTotalBlock,} from "../ui/Input";
import {makeStyles} from "@material-ui/core/styles";
import {ISmiListTotalBlock} from "./SMiListTotalBlock";
import {deadlineTable} from "../../helpers/deadlineTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      maxWidth: 136,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
      width: 250,
    },
  },
};

export const SMiListTotalBlockMobile: FC<ISmiListTotalBlock> = ({
  onClickBuy,
  selectedSmi,
  currentBalance,
  onClickReset,
  deadline,
  setDeadline,
}) => {
  const [hidden, setHidden] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const deadLineLabel = deadlineTable.find((item) => item.value === deadline);


  function getStyles(theme: Theme) {
    return {
      fontWeight: theme.typography.fontWeightMedium,
    };
  }

  return (
    <ContainerFluid>
      <Container>
        <LeftBlock>
          <SelectedBlock>
            <SelectedLabel>
              Выбрано{" "}
              <SelectedLabelBold>{selectedSmi.length} </SelectedLabelBold> СМИ
            </SelectedLabel>
            <SimpleWhiteLabel>
              Итоговая сумма:{" "}
              <SelectedLabelBold>
                {" "}
                {`${selectedSmi.reduce(
                  (acc, item) => (acc += item.price),
                  0
                )} `}{" "}
              </SelectedLabelBold>
              рублей
            </SimpleWhiteLabel>

            <ClearList onClick={onClickReset}>Очистить список</ClearList>
          </SelectedBlock>
          <ArrowImage
            src={backArrow}
            show={hidden}
            onClick={() => setHidden((val) => !val)}
          />
        </LeftBlock>

        {!hidden && (
          <RightBlock>
            <TimeBlock>
              <SimpleWhiteLabel>Срок исполнения:</SimpleWhiteLabel>
              <FormControl className={classes.formControl}>
                <SelectForTotalBlock
                  labelId="smi-type-label"
                  value={deadLineLabel ? deadLineLabel.value : ""}
                  onChange={(val: any) => setDeadline(val.target.value)}
                  input={<CssTextFieldForTotalBlock />}
                  MenuProps={MenuProps}
                >
                  {deadlineTable.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      style={getStyles(theme)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </SelectForTotalBlock>
              </FormControl>
            </TimeBlock>
            <BuyButton onClick={onClickBuy}>
              <BuyGreenText>Отправить заказ</BuyGreenText>
            </BuyButton>
          </RightBlock>
        )}
      </Container>
    </ContainerFluid>
  );
};

const ContainerFluid = styled.div`
  width: 100%;
  font-family: Stem, serif;
  background: #67b580;
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
`;

const Container = styled.div`
  max-width: 1420px;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 18px 15px;
  margin: auto;
`;

const LeftBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const SelectedBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const SelectedLabel = styled.p`
  font-size: 18px;
  line-height: 27px;
  white-space: nowrap;
  color: #ffffff;
`;

const SelectedLabelBold = styled.span`
  font-size: 18px;
  line-height: 27px;
  white-space: nowrap;
  color: #ffffff;
  font-weight: bold;
`;

const ClearList = styled.span`
  font-size: 13px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
`;

const TimeBlock = styled.div`
  display: flex;
  align-items: center;

  & > .test {
    width: 171px;
    color: #ffffff;

    & > :first-child {
      border-radius: 30px;
      border: 1px solid #ffffff;

      background-color: #67b580;
    }
  }
`;
const SimpleWhiteLabel = styled.p`
  font-size: 13px;
  line-height: 30px;
  white-space: nowrap;
  color: #ffffff;
  text-align: left;
`;

const BuyButton = styled.div`
  cursor: pointer;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  justify-content: center;
`;

const BuyGreenText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #67b580;
`;

const ArrowImage = styled.img<{ show: boolean }>`
  cursor: pointer;
  margin-top: 10px;
  width: 17px;
  height: 11px;
  transform: ${(props) => (props.show ? "rotate(180deg)" : "initial")};
`;
