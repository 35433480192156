import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Footer, Header } from "../../components";
import { InterActionPanel } from "../../components/InterActionPanel/InterActionPanel";
import { OrderForm } from "../../components/OrderForm/OrderForm";
import { FiltersBlock } from "../../components/Filters/FiltersBlock";
import { IRootStore } from "../../models";
import { inject, observer } from "mobx-react";
import { SmiListTable } from "../../components/SmiListTable/SmiListTable";
import { SMiListTotalBlock } from "../../components/SMiListTotalBlock/SMiListTotalBlock";
import { useWindowSize } from "../../helpers/useWindowsSize";
import { useFieldArray, useForm } from "react-hook-form";
import { createOrder, getGoSmiStatus } from "../../api/goSmi";
import { useHistory } from "react-router-dom";
import { CreateOrderSchema } from "./CreateOrderSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { SMiListTotalBlockMobile } from "../../components/SMiListTotalBlock/SMiListTotalBlockMobile";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import _ from "lodash";

export interface IFilter {
  smiType: number[];
  region: number[];
  indexing: string[];
  subject: string[];
  search: string;
  order_by: string;
  order_type: string;
}

export interface gosmi_Order {
  original_link: string;
  keywords: string;
  source_id: {
    id: string;
    price: number;
  }[];
  rewrite_type: boolean;
  full_reprint: boolean;
  comment: string;
  need_source_link: boolean;
  need_advert: boolean;
  extra_photos: string;
  extra_docs: string;
  deadline: number;
  extra_links: { value: string }[];
  extra_titles: { value: string }[];
  files?: { name: string; base64: string }[];
}

interface IProps {
  rootStore?: IRootStore;
}

export type SelectedType = {
  price: number;
  id: string;
};

export type InfoStatistic = {
  all_orders: number;
  complete_orders: number;
  in_work: number;
  current_balance: number;
};

const CreateOrder: FC<IProps> = ({ rootStore }) => {
  const [filter, setFilter] = useState<IFilter>({
    smiType: [],
    indexing: [],
    region: [],
    subject: [],
    search: "",
    order_by: "",
    order_type: "",
  });
  const history = useHistory();
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState<InfoStatistic>({
    all_orders: 0,
    complete_orders: 0,
    in_work: 0,
    current_balance: 0,
  });

  const [selectedSmi, setSelectedSmi] = useState<SelectedType[]>([]);
  const [rewrite_type, setRewrite_type] = useState(false);
  const [deadline, setDeadline] = useState(13);

  const isMobile = useWindowSize().width < 993;
  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    errors,
  } = useForm<gosmi_Order>({
    defaultValues: {
      original_link: "",
      keywords: "",
      full_reprint: false,
      comment: "",
      need_source_link: true,
      need_advert: false,
      extra_links: [{ value: "" }],
      extra_titles: [{ value: "" }],
    },
    resolver: yupResolver(CreateOrderSchema),
  });

  const { fields, append } = useFieldArray({
    control,
    name: "extra_links",
    keyName: "extra_links",
  });

  const { fields: titles, append: appendTitles } = useFieldArray({
    control,
    name: "extra_titles",
    keyName: "extra_titles",
  });

  const getBase64 = async (file: Blob): Promise<string | undefined> => {
    var reader = new FileReader();
    // @ts-ignore

    reader.readAsDataURL(file as Blob);

    return new Promise((reslove, reject) => {
      reader.onload = () => reslove(reader.result as any);
      reader.onerror = (error) => reject(error);
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const FilesArray = [] as { name: string; base64: string }[];
    const promises = files.map((file) =>
      getBase64(file).then((res) =>
        FilesArray.push({ name: file.name, base64: res || "" })
      )
    );
    await Promise.all(promises);

    if (selectedSmi.length === 0) {
      window.alert("Выберите как минимум одно сми");
      setLoading(false);
    } else {
      createOrder({
        ...data,
        source_id: selectedSmi,
        rewrite_type,
        deadline,
        files: FilesArray,
      })
        .then((res) => history.push(`/interaction/statistic/inwork/${res.id}`))
        .finally(() => setLoading(false));
    }
  });

  const loadMore = useCallback(() => {
    if (rootStore) {
      const newFilter = {
        ...rootStore.filteredSmiStore.filter,
        search: filter.search as typeof rootStore.filteredSmiStore.filter.search,
        order_by: filter.order_by as typeof rootStore.filteredSmiStore.filter.order_by,
        order_type: filter.order_type as typeof rootStore.filteredSmiStore.filter.order_type,
        smi_type_id: filter.smiType as typeof rootStore.filteredSmiStore.filter.smi_type_id,
        region_id: filter.region as typeof rootStore.filteredSmiStore.filter.region_id,
        theme: filter.subject as typeof rootStore.filteredSmiStore.filter.theme,
        index: filter.indexing.includes("Индексируемое") ? 1 : undefined,
        yandex_news: filter.indexing.includes("Индексируется Яндексом")
          ? 1
          : undefined,
        google_title: filter.indexing.includes("Индексируется Google")
          ? 1
          : undefined,
        in_medialogy: filter.indexing.includes("Находится в ТОП Медиаметрикс")
          ? 1
          : undefined,
        no_index: filter.indexing.includes("Не индексируется") ? 1 : undefined,
      };

      if (_.isEqual(newFilter, rootStore.filteredSmiStore.filter)) {
        newFilter.page = newFilter.page + 1;
        newFilter.clear = false;
      } else {
        newFilter.clear = true;
        newFilter.page = 1;
      }

      rootStore.filteredSmiStore.getNewsItems(newFilter);
    }
  }, [rootStore, filter]);

  // useEffect(() => {
  //   if (rootStore) {
  //     loadMore();
  //   }
  // }, [rootStore]);

  useEffect(() => {
    if (rootStore) {
      rootStore.filterStore.getSmiFilters().finally(() => setLoading(false));
    }
  }, [rootStore, setLoading]);

  useEffect(() => {
    getGoSmiStatus().then((res) => setInfo(res));
  }, []);

  const onClickReset = () => {
    setSelectedSmi([]);
  };

  return (
    <Fragment>
      <Header />
      <div className={"container"}>
        {loading && (
          <LoaderContainer>
            <Loader type={"TailSpin"} color="#67B580" height={80} width={80} />
          </LoaderContainer>
        )}
        <InterActionPanel
          all_orders={info.all_orders}
          complete_orders={info.complete_orders}
          current_balance={info.current_balance}
          in_work={info.in_work}
        />

        <OrderForm
          titles={titles}
          appendTitles={appendTitles}
          fields={fields}
          append={append}
          control={control}
          register={register()}
          setValue={setValue}
          setRewrite_type={setRewrite_type}
          rewrite_type={rewrite_type}
          getFieldValue={getValues}
          setFiles={setFiles}
          files={files}
          errors={errors}
        />
        <FiltersBlock
          filter={filter}
          loadMore={loadMore}
          setFilter={setFilter}
          filterStore={rootStore && rootStore.filterStore}
        />
        <SmiListTable
          selected={selectedSmi}
          setSelected={setSelectedSmi}
          loadMore={loadMore}
        />
      </div>
      {isMobile ? (
        <SMiListTotalBlockMobile
          deadline={deadline}
          setDeadline={setDeadline}
          onClickBuy={onSubmit}
          selectedSmi={selectedSmi}
          currentBalance={info.current_balance}
          onClickReset={onClickReset}
        />
      ) : (
        <SMiListTotalBlock
          deadline={deadline}
          setDeadline={setDeadline}
          onClickBuy={onSubmit}
          selectedSmi={selectedSmi}
          currentBalance={info.current_balance}
          onClickReset={onClickReset}
        />
      )}
      <Footer />
    </Fragment>
  );
};

export default inject("rootStore")(observer(CreateOrder));

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(42, 41, 41, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;
