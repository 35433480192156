export const wordEnding = (one: string, twoFour: string, many: string) => (
  count: number
): string => {
  const lastDigit = count % 10;
  if (lastDigit === 1) {
    return one;
  }
  if (lastDigit > 1 && lastDigit < 5) {
    return twoFour;
  }
  return many;
};
