exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".POCHTABANK_Settings_root__3u9oR {\n    --fill-color: #ffffff;\n    --title-color: var(--pochtabank-dark-blue);\n}\n.POCHTABANK_Settings_green__2CQ5h {\n    --bg-color: var(--pochtabank-light-green);\n}\n.POCHTABANK_Settings_blue__3hXNu {\n    --bg-color: var(--pochtabank-light-blue);\n}\n\n.POCHTABANK_Settings_inverse__11imq {\n    --bg-color: #ffffff;\n    --title-color: #ffffff;\n}\n.POCHTABANK_Settings_inverse__11imq.POCHTABANK_Settings_green__2CQ5h {\n    --fill-color: var(--pochtabank-light-green);\n}\n.POCHTABANK_Settings_inverse__11imq.POCHTABANK_Settings_blue__3hXNu {\n    --fill-color: var(--pochtabank-light-blue);\n}\n\n.POCHTABANK_Settings_root__3u9oR {\n    -ms-flex-align: center;\n        align-items: center;\n    display: -ms-flexbox;\n    display: flex;\n    position: relative;\n}\n\n.POCHTABANK_Settings_icon__2N44K {\n    -ms-flex-align: center;\n        align-items: center;\n    background: var(--bg-color);\n    border: 1px solid var(--bg-color);\n    border-radius: 50%;\n    /*cursor: pointer;*/\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    fill: var(--pochtabank-settings-bg);\n    height: 36px;\n    -ms-flex-pack: center;\n        justify-content: center;\n    margin-right: 15px;\n    -webkit-transition: all 0.3s ease-out;\n    transition: all 0.3s ease-out;\n    vertical-align: middle;\n    width: 36px;\n}\n\n/*.icon:hover,*/\n/*.icon.active {*/\n/*    background: var(--fill-color);*/\n/*    fill: var(--bg-color);*/\n/*}*/\n\n.POCHTABANK_Settings_title__2KUqL {\n    color: #000000;\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 30px;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "POCHTABANK_Settings_root__3u9oR",
	"green": "POCHTABANK_Settings_green__2CQ5h",
	"blue": "POCHTABANK_Settings_blue__3hXNu",
	"inverse": "POCHTABANK_Settings_inverse__11imq",
	"icon": "POCHTABANK_Settings_icon__2N44K",
	"title": "POCHTABANK_Settings_title__2KUqL"
};