exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./images/checkbox_marker.svg"));

// Module
exports.push([module.id, ".MTS_TagList_root__3llbz {\n  border: 1px solid rgba(141,150,201, 0.18);\n  border-width: 1px 0;\n  margin-bottom: 40px;\n  padding: 30px 0 0;\n}\n\n.MTS_TagList_tagList__1sIyW {\n  display: inline-block;\n  list-style: none;\n  margin: 0 0 15px;\n  padding: 0;\n}\n\n.MTS_TagList_tag__2A7uV {\n  color: var(--mts-dark-gray);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-bottom: 15px;\n  margin-right: 30px;\n  text-decoration: underline;\n  vertical-align: middle;\n}\n\n.MTS_TagList_tagCheckbox__3H-Vd {\n  border: none;\n  background: none;\n  height: 12px;\n  visibility: hidden;\n  width: 12px;\n}\n\n.MTS_TagList_checkboxMarker__3nfGN {\n  border: 1px solid rgba(130,130,130,0.3);\n  border-radius: 50%;\n  display: inline-block;\n  height: 12px;\n  position: relative;\n  vertical-align: middle;\n  width: 12px;\n}\n\n.MTS_TagList_checkboxMarker__3nfGN:after {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  display: block;\n  height: 12px;\n  position: absolute;\n  bottom: 2px;\n  left: 2px;\n  width: 12px;\n}\n\n.MTS_TagList_tagCheckbox__3H-Vd:checked + .MTS_TagList_checkboxMarker__3nfGN:after {\n  content: '';\n}\n\n.MTS_TagList_label__b4RmJ {\n  color: var(--mts-light-gray);\n  display: inline-block;\n  font-size: 16px;\n  line-height: 20px;\n  margin-right: 20px;\n  margin-bottom: 15px;\n  vertical-align: middle;\n}\n\n.MTS_TagList_gotoSearch__21s9e {\n  margin-bottom: 30px;\n}\n", ""]);

// Exports
exports.locals = {
	"root": "MTS_TagList_root__3llbz",
	"tagList": "MTS_TagList_tagList__1sIyW",
	"tag": "MTS_TagList_tag__2A7uV",
	"tagCheckbox": "MTS_TagList_tagCheckbox__3H-Vd",
	"checkboxMarker": "MTS_TagList_checkboxMarker__3nfGN",
	"label": "MTS_TagList_label__b4RmJ",
	"gotoSearch": "MTS_TagList_gotoSearch__21s9e"
};