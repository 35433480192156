exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css"), "");

// Module
exports.push([module.id, ".InterActionGrid_container__3jbhq {\n  display: grid;\n  -ms-flex-pack: center;\n      justify-content: center;\n  grid-template-columns: auto;\n  grid-gap: 30px;\n  margin-bottom: 30px;\n\n}\n\n@media " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["large-desktop"] + " {\n  .InterActionGrid_container__3jbhq {\n    grid-template-columns: repeat(2, 1fr);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"tablet": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["tablet"] + "",
	"desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["desktop"] + "",
	"large-desktop": "" + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../../../node_modules/postcss-loader/src/index.js??postcss!../../styles/breakpoints.css").locals["large-desktop"] + "",
	"container": "InterActionGrid_container__3jbhq"
};