import { inject } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { formatDateWithTime } from "helpers/formatDate";
import { INewsIntegrumItem, IUserStore } from "models";
import DEFAULT_COMPANY from "../../constants/company";

import ASV_styles from "./ASV_NewsIntegrum.module.css";
import DOMRF_styles from "./DOMRF_NewsIntegrum.module.css";
import MTS_styles from "./MTS_NewsIntegrum.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsIntegrum.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsIntegrum.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsIntegrum.module.css";

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

type Props = INewsIntegrumItem & { userStore?: IUserStore };

const NewsIntegrumItem: React.FC<Props> = ({
  id,
  source_name,
  title,
  publication_at,
  hot,
  viewed,
  news_color,
  userStore
}) => {
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const { styles } = assets[company];
  return (
    <Link
      to={`/i_news/${id}`}
      className={cn(styles.news, {
        [styles.viewed]: viewed,
        [styles.hot]: hot
      })}
      style={{ color: news_color || "#4F4F4F" }}
    >
      <div className={styles.src}>
        {/*<span className={styles.logo}>*/}
        {/*  <img src={logo} alt="logo" />*/}
        {/*</span>*/}
        {source_name && <span className={styles.name}>{source_name}</span>}
        {publication_at && (
          <span className={styles.date}>
            {formatDateWithTime(publication_at)}
          </span>
        )}
      </div>
      {title && <div className={styles.newsBody}>{title}</div>}
    </Link>
  );
};

export default inject("userStore")(NewsIntegrumItem);
