import qs from "qs";
import { destroy, getParent, getRoot, types } from "mobx-state-tree";
import { ISearchStore, ITagCheckbox, ITagList } from "./index";

export const TagCheckbox = types
  .model({
    id: types.number,
    name: types.string,
    checked: types.maybeNull(types.boolean)
  })
  .actions(self => ({
    remove() {
      getParent<ITagList>(getParent<ITagList>(self)).removeTag(
        self as ITagCheckbox
      );
    },
    toggleTeg() {
      self.checked = !self.checked;
    }
  }));

export const TagList = types
  .model("TagList", {
    tags: types.optional(types.array(TagCheckbox), [])
  })
  .actions(self => ({
    addItem(item: ITagCheckbox) {
      self.tags.push(item);
    },
    removeTag(tag: ITagCheckbox) {
      destroy(tag);

      const SearchStore = getRoot(self) as ISearchStore;
      SearchStore.updateSearchQuery({
        root_tag_ids: self.tags.reduce(
          (tagIds: number[], tag: ITagCheckbox) =>
            tag.checked ? [...tagIds, tag.id] : tagIds,
          []
        )
      });
      SearchStore.getSearchResult(SearchStore.searchQuery);
    }
  }))
  .views(self => ({
    get checkedTags() {
      return self.tags.filter(tag => tag.checked);
    },
    get query() {
      const query = {
        q: {
          root_tag_ids: self.tags.reduce(
            (tagIds: number[], tag: ITagCheckbox) =>
              tag.checked ? [...tagIds, tag.id] : tagIds,
            []
          )
        }
      };
      return qs.stringify(query);
    }
  }));
