import React from "react";
import styles from "./InterActionGrid.module.css";
import { InterActionCard } from "../InterActionCard/InterActionCard";
import { InterActiveCardsArray } from "../../constants/interActiveCards";

export const InterActionGrid = () => {
  return (
    <div className={styles.container}>
      {InterActiveCardsArray.map(card => (
        <InterActionCard {...card} />
      ))}
    </div>
  );
};
