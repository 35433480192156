import React from "react";
import { INewsItem } from "models";
import { formatDate } from "helpers/formatDate";
import { Link } from "react-router-dom";

type ISliderItemProps = INewsItem & { styles: any };

const NewsSliderItem: React.FC<ISliderItemProps> = ({
  id,
  source_icon,
  source_name,
  // tags,
  title,
  image,
  annotation,
  publication_at,
  styles
}) => (
  <div>
    <Link
      to={{ pathname: `/news/${id}` }}
      className={styles.slide}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.src}>
        {source_icon && (
          <span className={styles.logo}>
            <img src={source_icon} alt="" />
          </span>
        )}
        {source_name && <span className={styles.name}>{source_name}</span>}
        {publication_at && (
          <span className={styles.date}>{formatDate(publication_at)}</span>
        )}
      </div>
      <div className={styles.newsBody}>
        <div className={styles.newsTitle}>{title}</div>
        {annotation && (
          <div
            className={styles.newsText}
            dangerouslySetInnerHTML={{
              __html: annotation
            }}
          />
        )}
      </div>
      {/*{tags && <div className={styles.hashtags}>{tags.join(" ")}</div>}*/}
    </Link>
  </div>
);

export default NewsSliderItem;
