import React from "react";
import { Link } from "react-router-dom";
import { IUserInfo } from "models";

import styles from "./ASV_Navigation.module.css";

const ASV_Navigation: React.FC<IUserInfo> = ({ avatar }) => (
  <div className={styles.nav}>
    <a href="/search" className={styles.search}>
      {/*<input className={styles.searchInput} type="search" />*/}
      {/*<Link to="/search" className={styles.searchIcon} />*/}
    </a>
    <div className={styles.user}>
      {/*<div className={styles.comment} />*/}
      <Link to="/account" className={styles.lk}>
        {avatar && <img src={avatar} alt="" />}
      </Link>
    </div>
  </div>
);

export default ASV_Navigation;
