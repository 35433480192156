import { inject, observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { INewsFeed, IRootStore, IUserStore } from "models";
import { NewsGridItem, Settings, ViewType } from "components";
import DEFAULT_COMPANY from "../../constants/company";
import ASV_styles from "./ASV_NewsFeed.module.css";
import DOMRF_styles from "./DOMRF_NewsFeed.module.css";
import MTS_styles from "./MTS_NewsFeed.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsFeed.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsFeed.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsFeed.module.css";

interface INewsFeedProps {
  feed: INewsFeed;
  showSettings?: boolean;
  showViewToggler?: boolean;
  target?: "_self" | "_blank";
  rootStore?: IRootStore;
}

const assets: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

const NewsFeed: React.FC<INewsFeedProps> = ({
  feed: { id, feed_name, news },
  showSettings,
  showViewToggler = true,
  target,
  rootStore
}) => {
  const company =
    (rootStore && rootStore.userStore && rootStore.userStore.company) || DEFAULT_COMPANY;
  const view =
    (rootStore && rootStore.viewType && rootStore.viewType.view) || "magazine";
  const styles = assets[company];
  return (
    <div key={feed_name || ""} className={styles.root}>
      {(feed_name || showViewToggler) && (
        <div className={styles.feedHeader}>
          {feed_name && (
            <Link to={`/feeds/${id}`} className={styles.title}>
              {showSettings ? <Settings title={feed_name} /> : feed_name}
            </Link>
          )}
          <div className={styles.viewTypesToggler}>
            {showViewToggler ? <ViewType /> : null}
          </div>
        </div>
      )}
      {news && (
        <div className={cn(styles.newsGrid, styles[view])}>
          {news.news_list &&
            news.news_list.map((item, index) => (
              <NewsGridItem key={`${index}`} target={target} {...item} />
            ))}
        </div>
      )}
    </div>
  );
};

export default inject("rootStore")(observer(NewsFeed));
