import {
  differenceInCalendarDays,
  format,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";

export const formatToSearch = (date: Date): string =>
  format(new Date(date), "yyyy-MM-dd HH:mm");

export const formatDate = (date: string): string =>
  format(new Date(date), "dd.MM.yyyy");

export const formatDateWithTime = (date: string): string =>
  format(new Date(date), "dd.MM.yyyy HH:mm");

export const formatInputDate = (date: Date): string =>
  format(new Date(date), "dd/MM/yyyy");

export const searchQueryForThisWeek = (): string => {
  const today = new Date();
  const weekAgo = subWeeks(today, 1);
  return `q[publication_at_gteq]=${formatToSearch(
    weekAgo
  )}&q[publication_at_lteq]=${formatToSearch(today)}`;
};

export const searchQueryForThisMonth = (): string => {
  const today = new Date();
  const weekAgo = subMonths(today, 1);
  return `q[publication_at_gteq]=${formatToSearch(
    weekAgo
  )}&q[publication_at_lteq]=${formatToSearch(today)}`;
};

export const searchQueryForThisYear = (): string => {
  const today = new Date();
  const weekAgo = subYears(today, 1);
  return `q[publication_at_gteq]=${formatToSearch(
    weekAgo
  )}&q[publication_at_lteq]=${formatToSearch(today)}`;
};

export const searchQueryForAllPeriod = (): string => {
  const today = new Date();
  return `q[publication_at_lteq]=${formatToSearch(today)}`;
};

export const daysDifferense = (start: string, end: string): number =>
  differenceInCalendarDays(new Date(start), new Date(end));
