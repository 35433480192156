import React, { FC, useState } from "react";
import styled from "styled-components";
import bucket from "../../assets/bucket.png";
import {
  Select,
  createStyles,
  FormControl,
  MenuItem,
  Theme,
  useTheme,
} from "@material-ui/core";
import { CustomInputLabel } from "../ui/CustomInputLabel";
import {
  CssTextField,
  CssTextFieldForTotalBlock,
  SelectForTotalBlock,
} from "../ui/Input";
import { makeStyles } from "@material-ui/core/styles";
import { SelectedType } from "../../pages/CreateOrder/CreateOrder";
import { deadlineTable } from "../../helpers/deadlineTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      maxWidth: 200,
      minWidth: 200,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
      width: 250,
    },
  },
};

export interface ISmiListTotalBlock {
  onClickBuy: () => void;
  selectedSmi: SelectedType[];
  currentBalance: number;
  onClickReset: () => void;
  deadline: number;
  setDeadline: (val: number) => void;
}

export const SMiListTotalBlock: FC<ISmiListTotalBlock> = ({
  onClickBuy,
  selectedSmi,
  currentBalance,
  onClickReset,
  deadline,
  setDeadline,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const deadLineLabel = deadlineTable.find((item) => item.value === deadline);
  function getStyles(theme: Theme) {
    return {
      fontWeight: theme.typography.fontWeightMedium,
    };
  }

  return (
    <ContainerFluid>
      <Container>
        <LeftBlock>
          <SelectedBlock>
            <SelectedLabel>
              Выбрано{" "}
              <SelectedLabelBold>{selectedSmi.length} </SelectedLabelBold> СМИ
            </SelectedLabel>
            <ClearList onClick={onClickReset}>Очистить список</ClearList>
          </SelectedBlock>
          <TimeBlock>
            <SimpleWhiteLabel>Срок исполнения:</SimpleWhiteLabel>
            <FormControl className={classes.formControl}>
              <SelectForTotalBlock
                labelId="smi-type-label"
                value={deadLineLabel ? deadLineLabel.value : ""}
                onChange={(val: any) => setDeadline(val.target.value)}
                input={<CssTextFieldForTotalBlock />}
                MenuProps={MenuProps}
              >
                {deadlineTable.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    style={getStyles(theme)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </SelectForTotalBlock>
            </FormControl>
          </TimeBlock>
        </LeftBlock>
        <RightBlock>
          <PriceBlock>
            <SimpleWhiteLabel>Итоговая сумма:</SimpleWhiteLabel>
            <SelectedLabel>
              <SelectedLabelBold>
                {`${selectedSmi.reduce(
                  (acc, item) => (acc += item.price),
                  0
                )} `}
              </SelectedLabelBold>
              рублей
            </SelectedLabel>
            <SimpleWhiteLabel>Баланс: {currentBalance} рубля</SimpleWhiteLabel>
          </PriceBlock>
          <BuyButton onClick={onClickBuy}>
            <BuyGreenText>
              Отправить заказ <BucketImage src={bucket} />
            </BuyGreenText>
          </BuyButton>
        </RightBlock>
      </Container>
    </ContainerFluid>
  );
};

const ContainerFluid = styled.div`
  width: 100%;
  font-family: Stem, serif;
  background: #67b580;
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
`;

const Container = styled.div`
  max-width: 1420px;
  display: flex;
  width: 100%;
  padding: 30px 15px;
  margin: auto;

  @media screen and (max-width: 1280px) {
    flex-flow: column;
  }
`;

const LeftBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;

  align-items: center;

  @media screen and (max-width: 1280px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const RightBlock = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 1280px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
  }
`;

const SelectedBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const SelectedLabel = styled.p`
  font-size: 28px;
  line-height: 30px;
  white-space: nowrap;
  color: #ffffff;
`;

const SelectedLabelBold = styled.span`
  font-size: 28px;
  line-height: 30px;
  white-space: nowrap;
  color: #ffffff;
  font-weight: bold;
`;

const ClearList = styled.span`
  padding-top: 10px;
  font-size: 13px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
`;

const TimeBlock = styled.div`
  display: flex;
  align-items: center;

  & > .test {
    width: 171px;
    color: #ffffff;

    & > :first-child {
      border-radius: 30px;
      border: 1px solid #ffffff;

      background-color: #67b580;
    }
  }
`;
const SimpleWhiteLabel = styled.p`
  font-size: 13px;
  line-height: 30px;
  white-space: nowrap;
  color: #ffffff;
  text-align: left;
`;

const PriceBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

const BuyButton = styled.div`
  cursor: pointer;
  width: 265px;
  height: 54px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  justify-content: center;
`;

const BuyGreenText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #67b580;
`;

const BucketImage = styled.img`
  padding-left: 17px;
`;
