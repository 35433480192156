import axios, { AxiosError, AxiosRequestConfig } from "axios";
import qs from "qs";
import { UserStore } from "models";

axios.interceptors.request.use(
  (config) => {
    let currentUrl = new URL(window.location.href);
    const url = new URL(
      currentUrl.protocol + "//" + window.location.hostname + "/"
    );
    url.pathname = config.url as string;
    config.url = `${url.protocol}//${url.host}${url.pathname}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// @ts-ignore
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      UserStore.logout();
      return error.response;
    }
    if (error.response.status >= 500) {
      window.alert(error.response.data.errMsg || "ошибка сервера");
      return Promise.reject("dd");
      console.log("ERRORS/GET_INTERNAL_SERVER_ERROR");
    }
  }
);

axios.defaults.headers["Content-Type"] = "application/json";

const apiClient = {
  get(path: string, params = {}, config?: AxiosRequestConfig) {
    const options: AxiosRequestConfig = {
      ...config,
      params,
      method: "GET",
      url: path,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    };
    return axios(options).then((response) => response.data);
  },
  post(path: string, data?: object, params?: {}, config?: object) {
    const options: AxiosRequestConfig = {
      data,
      ...config,
      params,
      method: "POST",
      url: path,
      withCredentials: true,
    };
    return axios(options).then((response) => response.data);
  },
  put(path: string, params = {}, data = {}) {
    if (!UserStore.token) {
      UserStore.logout();
    }
    const options: AxiosRequestConfig = {
      params,
      data,
      headers: { "auth-token": UserStore.token },
      method: "PUT",
      url: path,
      withCredentials: true,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    };
    return axios(options).then((response) => response.status && response.data);
  },
  delete(path: string) {
    const options: AxiosRequestConfig = {
      method: "DELETE",
      url: path,
      withCredentials: true,
    };
    return axios(options).then((response) => response);
  },
};

export default apiClient;
