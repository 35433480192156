import { inject } from "mobx-react";
import React from "react";
import Slider from "react-slick";
import { INewsItem, IUserStore } from "models";
import DEFAULT_COMPANY from "constants/company";
import NewsSliderItem from "./NewsSliderItem";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ASV_styles from "./ASV_NewsSlider.module.css";
import DOMRF_styles from "./DOMRF_NewsSlider.module.css";
import MTS_styles from "./MTS_NewsSlider.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_NewsSlider.module.css";
import POCHTABANK_styles from "./POCHTABANK_NewsSlider.module.css";
import CUSTOMS_styles from "./CUSTOMS_NewsSlider.module.css";

interface INewsSliderProps {
  slides: INewsItem[];
  userStore?: IUserStore;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
};

const assets: any = {
  ASV: {
    styles: ASV_styles
  },
  DOMRF: {
    styles: DOMRF_styles
  },
  MTS: {
    styles: MTS_styles
  },
  KALASHNIKOVGROUP: {
    styles: KALASHNIKOVGROUP_styles
  },
  POCHTABANK: {
    styles: POCHTABANK_styles
  },
  CUSTOMS: {
    styles: CUSTOMS_styles
  },
};

const NewsSlider: React.FC<INewsSliderProps> = ({ slides, userStore }) => {
  const company = (userStore && userStore.company) || DEFAULT_COMPANY;
  const { styles } = assets[company];
  return (
    <div className={styles.slider}>
      <Slider {...settings}>
        {slides.map((item, index) => (
          <NewsSliderItem key={`${item.publication_at}-${index}`} styles={styles} {...item} />
        ))}
      </Slider>
    </div>
  );
};

export default inject("userStore")(NewsSlider);
