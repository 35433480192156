import React, {FC, Fragment, useEffect, useState} from "react";
import {Footer, Header} from "../../components";
import {InterActionPanel} from "../InterActionPanel/InterActionPanel";
import {inject, observer} from "mobx-react";
import {StatisticList} from "../StatisticList/StatisticList";
import {getGoSmiDoneOrders, getGoSmiStatus,} from "../../api/goSmi";
import {InfoStatistic} from "../../pages/CreateOrder/CreateOrder";
import {OrderItem} from "../StatisticList/StatisticItem";

const StatisticDone: FC = () => {
  const [info, setInfo] = useState<InfoStatistic>({
    all_orders: 0,
    complete_orders: 0,
    in_work: 0,
    current_balance: 0,
  });
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interval_id = setTimeout(() => {
      window.location.reload();
    }, 300000);

    return () => clearTimeout(interval_id);
  }, []);

  useEffect(() => {
    getGoSmiStatus().then((res) => setInfo(res));
  }, []);

  useEffect(() => {
    setLoading(true);
    getGoSmiDoneOrders()
      .then((res) => setOrders(res))
      .finally(() => setLoading(false));
  }, [getGoSmiDoneOrders,setOrders, setLoading]);

  return (
    <Fragment>
      <Header />
      <div
        className={"container"}
        style={{ display: "flex", flexFlow: "column", flexGrow: 1 }}
      >
        <InterActionPanel
          all_orders={info.all_orders}
          complete_orders={info.complete_orders}
          current_balance={info.current_balance}
          in_work={info.in_work}
          activePath={"/interaction/statistic/done"}
        />
        <StatisticList loading={loading} orders={orders} />
      </div>
      <Footer />
    </Fragment>
  );
};

export default inject("rootStore")(observer(StatisticDone));
