exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".MTS_SearchResults_searchResultsHeader__Tj83x {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.MTS_SearchResults_resultsTitle__K-D-q {\n  color: var(--mts-dark-gray);\n  font-size: 18px;\n  line-height: 23px;\n  margin-bottom: 25px;\n  margin-left: 20px;\n}\n\n.MTS_SearchResults_viewTypesToggler__1GSbY {\n  height: 30px;\n  position: relative;\n  margin-left: auto;\n  margin-bottom: 25px;\n}\n\n.MTS_SearchResults_total__3CWOU {\n  color: var(--mts-light-green);\n  font-weight: 600;\n}", ""]);

// Exports
exports.locals = {
	"searchResultsHeader": "MTS_SearchResults_searchResultsHeader__Tj83x",
	"resultsTitle": "MTS_SearchResults_resultsTitle__K-D-q",
	"viewTypesToggler": "MTS_SearchResults_viewTypesToggler__1GSbY",
	"total": "MTS_SearchResults_total__3CWOU"
};