exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n", ""]);

// Exports
exports.locals = {
	"tablet-width": "768px",
	"desktop-width": "1024px",
	"large-desktop-width": "1420px",
	"tablet": "(min-width: 768px)",
	"desktop": "(min-width: 1024px)",
	"large-desktop": "(min-width: 1420px)"
};