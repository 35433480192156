exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./styles/breakpoints.css"), "");

// Module
exports.push([module.id, "/*html {*/\n/*  margin-right: calc(100% - 100vw);*/\n/*  overflow-x: hidden;*/\n/*  padding-right: calc(100vw - 100%);*/\n/*}*/\n\n.MTS_App_app__Riqnd {\n  color: var(--mts-text-color);\n  background: #FFFFFF;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  min-height: 100vh;\n}\n\n.MTS_App_menuOpened__2AqOH {\n  height: 100vh;\n  position: fixed;\n  width: 100%;\n}\n\n@media " + require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./styles/breakpoints.css").locals["desktop"] + " {\n  .MTS_App_menuOpened__2AqOH {\n    height: auto;\n    position: static;\n    width: auto;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"desktop": "" + require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./styles/breakpoints.css").locals["desktop"] + "",
	"app": "MTS_App_app__Riqnd",
	"menuOpened": "MTS_App_menuOpened__2AqOH"
};