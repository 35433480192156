import React, { PureComponent } from "react";
import { inject, observer } from "mobx-react";
import { IUserStore, NewsTopStore } from "models";

import { NewsSlider, Settings } from "components";
import DEFAULT_COMPANY from "constants/company";

import ASV_styles from "./ASV_ActualNews.module.css";
import DOMRF_styles from "./DOMRF_ActualNews.module.css";
import MTS_styles from "./MTS_ActualNews.module.css";
import KALASHNIKOVGROUP_styles from "./KALASHNIKOVGROUP_ActualNews.module.css";
import POCHTABANK_styles from "./POCHTABANK_ActualNews.module.css";
import CUSTOMS_styles from "./CUSTOMS_ActualNews.module.css";

const actualNewsStyles: any = {
  ASV: ASV_styles,
  DOMRF: DOMRF_styles,
  MTS: MTS_styles,
  KALASHNIKOVGROUP: KALASHNIKOVGROUP_styles,
  POCHTABANK: POCHTABANK_styles,
  CUSTOMS: CUSTOMS_styles,
};

interface IActualNewsProps {
  userStore?: IUserStore;
}

@inject("userStore")
@observer
class ActualNews extends PureComponent<IActualNewsProps> {
  componentDidMount() {
    NewsTopStore.getNewsItems();
  }

  render() {
    const { userStore } = this.props;
    const company = (userStore && userStore.company) || DEFAULT_COMPANY;
    const styles = actualNewsStyles[company];
    return (
      NewsTopStore.state === "done" && (
        <div className={styles.root}>
          <div className={styles.title}>
            <Settings inverse title="Актуальные новости" />
          </div>
          <NewsSlider slides={NewsTopStore.news} />
        </div>
      )
    );
  }
}

export default ActualNews;
