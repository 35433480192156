import { cast, types, flow } from "mobx-state-tree";
import {addToFavorites, getNewsItemPage, removeFromFavorites} from "api/news";
import NewsItem from "./NewsItem";

const NewsItemPage = types
  .model("NewsItemPage", {
    page: NewsItem,
    state: types.enumeration("State", ["pending", "done", "error"])
  })
  .actions(self => {
    const getNewsItem = flow(function* getNewsItem(type, id) {
      self.state = "pending";
      try {
        // @ts-ignore
        self.page = yield cast(getNewsItemPage(type, id));
        self.state = "done";
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    const addNewsToFavorites = flow(function* addNewsToFavorites(id: number) {
      try {
        // @ts-ignore
        const newFav = yield cast(addToFavorites(id));
        if (newFav) {
          self.page.favorite = true;
        }
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    const removeNewsFromFavorites = flow(function* removeNewsFromFavorites(id: number) {
      try {
        // @ts-ignore
        const removed = yield cast(removeFromFavorites(id));
        if (removed) {
          self.page.favorite = false;
        }
      } catch (error) {
        console.error("Failed to fetch news", error);
        self.state = "error";
      }
    });
    return { getNewsItem, addNewsToFavorites, removeNewsFromFavorites };
  });
export default NewsItemPage;
