exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/KALASHNIKOVGROUP/datepicker_icon.svg"));

// Module
exports.push([module.id, ".KALASHNIKOVGROUP_DatePicker_dateRangeValue__1_b4u {\n  background: var(--kalashnikovgroup-input-bg);\n  border: 1px solid #efefef;\n  border-radius: 30px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  -ms-flex: 1 1;\n      flex: 1 1;\n  font-size: 18px;\n  height: 54px;\n  padding: 15px 25px;\n  -webkit-transition: border 0.2s ease-out;\n  transition: border 0.2s ease-out;\n  vertical-align: middle;\n  width: 100%;\n}\n\n.KALASHNIKOVGROUP_DatePicker_dateRangeValue__1_b4u::after {\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat center;\n  content: '';\n  display: inline-block;\n  height: 27px;\n  margin-right: 10px;\n  margin-left: 20px;\n  vertical-align: middle;\n  width: 27px;\n}\n\n.KALASHNIKOVGROUP_DatePicker_dateInput__1NCDL {\n  position: absolute;\n}\n\n.KALASHNIKOVGROUP_DatePicker_dateInput__1NCDL .react-datepicker__input-container > input {\n  color: var(--kalashnikovgroup-text-color);\n  height: 54px;\n  opacity: 0;\n  width: 150px;\n}\n", ""]);

// Exports
exports.locals = {
	"dateRangeValue": "KALASHNIKOVGROUP_DatePicker_dateRangeValue__1_b4u",
	"dateInput": "KALASHNIKOVGROUP_DatePicker_dateInput__1NCDL"
};