import { types } from "mobx-state-tree";

const NewsIntegrumItem = types
  .model("NewsIntegrum", {
    id: types.maybeNull(types.number),
    source_name: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    publication_at: types.maybeNull(types.string),
    hot: false,
    viewed: types.maybeNull(types.boolean),
    news_color: types.maybeNull(types.string),
  })
  .actions(self => ({
    markRead() {
      self.viewed = true;
    }
  }))
  .views(self => ({
    status() {
      return self.viewed ? "read" : "not read";
    }
  }));

export default NewsIntegrumItem;
